<template>
  <div class="notification">
    <transition-group
        name="v-transition-animate"
        class="messages_list"
    >
      <div
          v-for="message in messages"
          class="notification__content"
          :key="message.id"
      >
        <div class="content__text">
          <span>{{ message.name }}</span>
          <!--          todo по возможности добавить картинку галочки при сохранении-->
          <!--          <i class="material-icons">check_circle</i>-->
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    messages: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    hideNotification() {
      let vm = this;
      if (this.messages.length) {
        setTimeout(function () {
          vm.messages.splice(vm.messages.length - 1, 1)
        }, 2500)
      }
    }
  },
  watch: {
    messages() {
      this.hideNotification()
    }
  },
  mounted() {
    this.hideNotification()
  }
}
</script>

<style lang="scss">
.notification {
  position: fixed;
  top: 155px;
  right: 35px;
  z-index: 1000000;
}

.messages_list {
  display: flex;
  flex-direction: column-reverse;
}

.notification__content {
  padding: 16px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-bottom: 16px;
  background: #00a651;
}

.content__text {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.material-icons {
  margin-left: 16px;
}

.v-transition-animate {
  &-enter {
    transform: translateX(120px);
    opacity: 0;
  }

  &-enter-active {
    transition: all .6s ease;
  }

  &-enter-to {
    opacity: 1;
  }

  &-leave {
    height: 100px;
    opacity: 1;
  }

  &-leave-active {
    transition: transform .6s ease, opacity .6s, height .6s .2s;
  }

  &-leave-to {
    height: 0;
    transform: translateX(120px);
    opacity: 0;
  }

  &-move {
    transition: transform .6s ease;
  }
}
</style>