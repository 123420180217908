<template>
  <aside class="main-menu">
    <div class="main-menu__wrap">
      <ul class="main-menu__list">
        <li v-if="menu['profile-org']"
            class="main-menu__item" :class="{'main-menu__item-link_active': menu['profile-org'].isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-1.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link :to="{ name: 'LK/profile-org' }" class="main-menu__text_link">
                Профиль организации
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu['request-kp']"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': this.$route.name === 'LK/request-kp' || this.$route.name === 'LK/kp-on-request'}"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-2.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <div class="request-kp__menu"
                   @click="menuDropKP()">
                <div class="main-menu__text_link">Запросы</div>
                <img :src="menuDrop.src" alt="">
              </div>
            </span>
          </div>
        </li>
        <li v-if="menuDrop.isOpenKP"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': this.$route.name === 'LK/response-kp/create' }"
        >
          <span class="main-menu__icon"/>
          <span class="main-menu__text">
            <div class="request-kp__menu">
              <router-link :to="{ name: 'LK/request-kp' }" class="main-menu__text_link">
                Запросы КП
              </router-link>
            </div>
          </span>
        </li>
        <li v-if="menuDrop.isOpenKP && userRole"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': this.$route.name === 'LK/response-kp' }"
        >
          <span class="main-menu__icon"/>
          <span class="main-menu__text">
            <div class="request-kp__menu">
              <router-link :to="{ name: 'LK/kp-on-request' }" class="main-menu__text_link">
                КП по запросам
              </router-link>
            </div>
          </span>
        </li>
        <li v-if="menu['dogovors']"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': menu['dogovors'].isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-3.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/dogovors'}">
                Договоры
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu.request"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': this.$route.name === 'LK/request' }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-4.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/request'}">
                Заявки
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu.conclusions"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': menu.conclusions.isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-5.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/conclusions'}">
                Заключения
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu.accreditation"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': menu.accreditation.isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-5.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/accreditation'}">
                {{ menu.accreditation.module }}
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu['dogovors-services']"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': menu['dogovors-services'].isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-5.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/dogovors-services'}">
                {{ menu['dogovors-services'].module }}
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu['personal-account']"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': menu['personal-account'].isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/exporter/menu_icon-5.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/personal-account'}">
                {{ menu['personal-account'].module }}
              </router-link>
            </span>
          </div>
        </li>
        <li v-if="menu['reestr-fstec']"
            class="main-menu__item"
            :class="{'main-menu__item-link_active': menu['reestr-fstec'].isActive }"
        >
          <div class="main-menu__item-link">
            <span class="main-menu__icon">
              <img src="/img/main-menu/manager/menu_icon-5.png" class="menu_img" alt="">
            </span>
            <span class="main-menu__text">
              <router-link class="main-menu__text_link" :to="{name: 'LK/reestr-fstec'}">
                Реестр отчетов во ФСТЭК
              </router-link>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import Vue from "vue";
import {UserService} from "@/common/api.service";

export default {
  name: "MainMenu",
  data() {
    return {
      userRole: false,
      menu: {},
      menuDrop: {
        isOpenKP: false,
        imgSrc: ['/img/min_arrow.png', '/img/min_arrow_open.png'],
        src: '/img/min_arrow.png',
      },
    }
  },
  async mounted() {
    this.userRole = (this.$store.getters.currentUser.ACTIVE_ROLE === 8 || this.$store.getters.currentUser.ACTIVE_ROLE === 9);
    await UserService.getUserDefaultSetting().then(({data}) => {
      data.MENU.forEach(function (menu) {
        Vue.set(this.menu, menu.PAGE_URL, {
          module: menu.MODUL_NAME,
          isActive: this.$router.currentRoute.name.toLowerCase().indexOf('lk/' + menu.PAGE_URL) !== -1
        })
      }, this)
    }, this);
  },
  methods: {
    menuDropKP() {
      this.menuDrop.isOpenKP = !this.menuDrop.isOpenKP;
      if (this.menuDrop.isOpenKP) {
        this.menuDrop.src = this.menuDrop.imgSrc[1];
      } else {
        this.menuDrop.src = this.menuDrop.imgSrc[0];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.request-kp__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
}
</style>
