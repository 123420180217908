<template>
    <div style="font-size: 14px;margin-top: 10px">
        <div v-html="filterString()"/>
        <div v-html="groupString()"/>
    </div>
</template>

<script>
    export default {
        name: "InfoFilter",
        props: {
            items: Object,
        },
        methods: {
            filterString() {
                let str = ""
                if (this.items.applyFilters.length > 0) {
                    str += `Фильтр: `
                    this.items.applyFilters.forEach(function (filter, ind) {
                        let f = this.items.settings.filterFields[filter.index]
                        if (filter.value) {
                            str += ` ${f.NAME} ${f.OPERATION[filter.operation].NAME} "${filter.value}"`
                            if (filter.value2) {
                                str += ` и "${filter.value2}"`
                            }
                            if (this.items.applyFilters[ind + 1] && (this.items.applyFilters[ind + 1].value || this.items.applyFilters[ind + 1].multivalue.length > 0)) {
                                str += ` <strong>${f.LOGIC[filter.logic].NAME}</strong> `
                            }
                        }
                        if (filter.multivalue.length > 0) {
                            str += ` ${f.NAME} ${f.OPERATION[filter.operation].NAME} `
                            filter.multivalue.forEach(function (val, i) {
                                if (i > 0) {
                                    str += ', '
                                }
                                str += `"${val.UF_NAME}"`
                            })

                            if (this.items.applyFilters[ind + 1] && (this.items.applyFilters[ind + 1].value || this.items.applyFilters[ind + 1].multivalue.length > 0)) {
                                str += ` <strong>${f.LOGIC[filter.logic].NAME}</strong> `
                            }
                        }
                    }, this)
                }
                return str
            },
            groupString() {
                let str = ""
                if (this.items.applyGroups.length > 0) {
                    str += `Группировка: `
                    this.items.applyGroups.forEach(function (group, i) {
                        if (i > 0) {
                            str += ", "
                        }
                        str += this.items.settings.groupFields[group.index].NAME
                    }, this);
                }
                return str
            }
        }
    }
</script>

<style scoped>

</style>