const ID_TOKEN_KEY = "id_token";
const LAST_ACTION_TIME = "last_action";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getLastAction = () => {
  return window.localStorage.getItem(LAST_ACTION_TIME);
};

export const saveLastAction = time => {
  window.localStorage.setItem(LAST_ACTION_TIME, time);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getLastAction,
  saveLastAction
};
