<template>
    <div>
        <div class="modal fade in show" tabindex="-1" v-if="items.showFilterModal" role="dialog"
             aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-add-document modal-full">
                        <div class="modal-header main-clear">
                            <span class="modal-title">настройка фильтра</span>
                            <a @click="items.showFilterModal=false" class="modal-exit stl-FR"></a>
                        </div>
                        <div class="modal-body">
                            <div class="modal_row main-clear">
                                <div class="table-responsive">
                                    <table class="filter-table">
                                        <tbody>
                                        <tr :key="filter.name" v-for="(filter, fi) in items.filters">
                                            <td>
                                                <select @change="changeFilterIndex(filter)"
                                                        v-model="filter.index"
                                                        class="main_input main_select filter-input">
                                                    <option :key="item.NAME" :value="index"
                                                            v-for="(item, index) in items.settings.filterFields">
                                                        {{item.NAME}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="filter.operation"
                                                        class="main_input main_select filter-input">
                                                    <option :key="item.NAME" :value="index"
                                                            v-for="(item, index) in items.settings.filterFields[filter.index].OPERATION">
                                                        {{item.NAME}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td style="width: 250px">
                                                <input v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='string'"
                                                       type="text" class="main_input filter-input"
                                                       v-model="filter.value">
                                                <input style="margin-top: 5px"
                                                       v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='string' && items.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                                       type="text" class="main_input filter-input"
                                                       v-model="filter.value2">
                                                <Multiselect
                                                        label="UF_NAME"
                                                        :loading="!filter.listRendered"
                                                        :limit="1"
                                                        :limitText="filterItemText"
                                                        track-by="ID"
                                                        v-model="filter.multivalue"
                                                        :options="filter.objects"
                                                        :multiple="true" placeholder=""
                                                        v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='multiselect'"/>
                                                <Multiselect
                                                        label="UF_NAME"
                                                        :loading="!filter.listRendered"
                                                        :limit="1"
                                                        :limitText="filterItemText"
                                                        track-by="ID"
                                                        v-model="filter.multivalue2"
                                                        :options="filter.objects"
                                                        :multiple="true" placeholder=""
                                                        style="margin-top: 5px"
                                                        v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='multiselect' && items.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"/>
                                                <datepicker
                                                        v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='datetime'"
                                                        v-model="filter.value"
                                                        :language="ru"
                                                        :format="dateFormat" style="width: 100%"
                                                        input-class="main_input filter-input"
                                                ></datepicker>
                                                <datepicker
                                                        v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='datetime'
                                                             && items.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                                        v-model="filter.value2"
                                                        :language="ru"
                                                        :format="dateFormat"
                                                        style="margin-top: 5px;width: 100%"
                                                        input-class="main_input filter-input"
                                                ></datepicker>
                                                <select v-if="items.settings.filterFields[filter.index].FIELD_TYPE==='select'"
                                                        type="text" class="main_input filter-input"
                                                        v-model="filter.value">
                                                    <option :key="item.ID" :value="item.ID"
                                                            v-for="item in filter.objects">
                                                        {{item.UF_NAME}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="filter.logic"
                                                        class="main_input main_select filter-input">
                                                    <option :key="item.NAME" :value="index"
                                                            v-for="(item, index) in items.settings.filterFields[filter.index].LOGIC">
                                                        {{item.NAME}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td class="text-center"><a @click="items.filters.splice(fi, 1)"><i
                                                    class="control-delete_link"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal_row">
                                <a class="control-panel__link control-add_link"
                                   @click="addFilter">добавить
                                    фильтр</a>
                            </div>
                            <div class="modal_row main-clear">
                                <div class="link_style-blue modal-full_wid" @click="$parent.fetchList">
                                    применить
                                </div>
                                <div class="link_style-blue link_style-reset modal-full_wid"
                                     @click="clearFilters()">
                                    сбросить фильтры
                                </div>
                                <div class="link_style-white modal-full_wid"
                                     @click="items.showFilterModal=false">отмена
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade in show" tabindex="-1" v-if="items.showGroupModal" role="dialog"
             aria-labelledby="myModalLabel">
            <div class="modal-dialog modal-middle" role="document">
                <div class="modal-content">
                    <div class="modal-add-document">
                        <div class="modal-header main-clear">
                            <span class="modal-title">настройка группировки</span>
                            <a @click="items.showGroupModal=false" class="modal-exit stl-FR"></a>
                        </div>
                        <div class="modal-body">
                            <div class="modal_row main-clear">
                                <div class="table-responsive">
                                    <table class="filter-table">
                                        <tbody>
                                        <tr :key="group.name" v-for="(group, gi) in items.groups">
                                            <td>
                                                <select v-model="group.index"
                                                        class="main_input main_select filter-input">
                                                    <option :key="item.NAME" :value="item.POS"
                                                            v-for="item in groupFields(gi, items.settings.groupFields)">
                                                        {{item.NAME}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                уровень {{gi+1}}
                                            </td>
                                            <td class="text-center"><a @click="items.groups.splice(gi, 1)"><i
                                                    class="control-delete_link"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal_row">
                                <a class="control-panel__link control-add_link"
                                   v-if="groupFields(-1, items.settings.groupFields).length > 0"
                                   @click="items.groups.push({})">добавить уровень группировки</a>
                            </div>
                            <div class="modal_row main-clear">
                                <div class="link_style-blue modal-full_wid" @click="$parent.fetchList">
                                    применить
                                </div>
                                <div class="link_style-blue link_style-reset modal-full_wid"
                                     @click="clearGroups">
                                    сбросить группировку
                                </div>
                                <div class="link_style-white modal-full_wid"
                                     @click="items.showGroupModal = false">
                                    отмена
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import Multiselect from "vue-multiselect";
    import ApiService from "@/common/api.service";
    import moment from "moment";

    export default {
        name: "ListFilter",
        components: {Multiselect},
        props: {
            items: Object,
        },
        methods: {
            addFilter() {
                this.items.filters.push({index:0, operation:0, logic: 0, value: '', value2: '', objects:[], listRendered: false, multivalue:[], multivalue2:[]})
                this.changeFilterIndex(this.items.filters[this.items.filters.length-1])
            },
            changeFilterIndex(filter) {
                filter.value = ""
                filter.value2 = ""
                filter.operation = 0
                filter.logic = 0
                filter.multivalue = []
                filter.multivalue2 = []
                if (this.items.settings.filterFields[filter.index].LINK) {
                    filter.listRendered = false
                    ApiService.get2(this.items.settings.filterFields[filter.index].LINK).then(({data}) => {
                        filter.objects = data
                        Vue.set(filter, 'listRendered', true)
                    }, this)
                }
            },
            filterItemText(count) {
                return `и еще ${count}`
            },
            clearFilters() {
                this.items.filters = []
                this.items.showFilterModal = false
                Vue.set(this.items, 'applyFilters', [])
                this.$parent.fetchList()
            },
            clearGroups() {
                this.items.groups = []
                this.items.showGroupModal = false
                Vue.set(this.items, 'applyGroups', [])
                this.$parent.fetchList()
            },
            dateFormat(date) {
                return moment(date).format("DD.MM.YYYY");
            },
            groupFields(index, fields) {
                let ret = []
                fields.forEach(function (f, fin) {
                    let hasAdd = false
                    this.items.groups.forEach(function (gr, gin) {
                        if (index !== gin) {
                            if (gr.index === fin) {
                                hasAdd = true
                            }
                        }
                    }, this)
                    if (!hasAdd) {
                        ret.push({NAME: f.NAME, POS: fin})
                    }
                }, this)
                return ret
            },
        }
    }
</script>

<style scoped>

</style>