<template>
  <div v-if="!isTZLoading">
      <div class="table-responsive">
        <table class="table-info-tz">
          <tbody>
          <tr>
            <td>ТЗ подписано:</td>
            <td>Исполнителем</td>
            <td v-if="tzItem.UF_DATE_SIGN_EXECUTOR">
              <span class="e-signature">эцп</span>
              <span class="e-signature_data">{{tzItem.UF_DATE_SIGN_EXECUTOR | dateFormat}}</span>
            </td>
            <td v-else>не подписано</td>
          </tr>
          <tr>
            <td><a @click="downloadTZ" class="link-downloaud_file">Скачать ТЗ</a></td>
            <td>Заказчиком</td>
            <td v-if="tzItem.UF_DATE_SIGN_CUSTOMER">
              <span class="e-signature">эцп</span>
              <span class="e-signature_data">{{tzItem.UF_DATE_SIGN_CUSTOMER | dateFormat}}</span>
            </td>
            <td v-else>не подписано</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="content-tab-wrapper">
        <div class="row row_link_title">
          <div class="col-md-12">
            <a href="#" class="link_title">
              <i class="i_arrow"></i>
              Согласование
            </a>
          </div>
        </div>
        <div class="content-tab-indent">
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Срок проведения экспертизы</div>
            <div class="col-md-9 col-lg-10 col-data">
             <span style="display: inline-block"> с</span>
              <datepicker style="display: inline-block"
                  v-model="tzItem.UF_EXPERTISE_DATE_START"
                  :language="ru"
                  :format="dateFormat"
                  input-class="main_input small_calendar small_calendar_mid_100 main-inlineBlock"
              ></datepicker>
              <span style="display: inline-block">  по</span>
              <datepicker
                  v-model="tzItem.UF_EXPERTISE_DATE_END"
                  :language="ru"
                  :format="dateFormat"
                  style="display: inline-block"
                  input-class="main_input small_calendar small_calendar_mid_100 main-inlineBlock"
              ></datepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Стоимость без НДС</div>
            <div class="col-md-9 col-lg-10 col-data"><input type="text"
                                                            class="main_input"
                                                            style="text-align: right"
                                                            v-model="tzItem.UF_PRICE_NOT_NDS"></div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Ставка НДС</div>
            <div class="col-md-9 col-lg-10 col-data"><select v-model="tzItem.UF_STAVKA_NDS"
                                                             class="main_input main_select">
              <option v-for="item in directory.stavka_nds" :key="item.ID" :value="item.ID">{{ item.UF_VALUE }}%</option>
            </select></div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Сумма НДС</div>
            <div class="col-md-9 col-lg-10 col-data"><input disabled type="text"
                                                            class="main_input"
                                                            style="text-align: right"
                                                            :value="sumNds()"></div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Всего с НДС</div>
            <div class="col-md-9 col-lg-10 col-data"><input disabled type="text"
                                                            class="main_input"
                                                            style="text-align: right"
                                                            :value="sumWNds()"></div>
          </div>
        </div>
        <!-- content-tab-indent -->
      </div>
      <!-- content-tab-wrapper -->

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ru} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import {Funcs} from "@/common/funcs";
import Datepicker from "vuejs-datepicker";
import {TZService} from "@/common/api.service";
import {API_URL} from "@/common/config";

export default {
  name: "Card",
  data() {
    return {
      ru: ru
    };
  },
  computed: {
    ...mapGetters([
      "tzItem",
      "directory",
      "isTZLoading",
      "currentUser"
    ])
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  components: {Datepicker},
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    sumNds() {
      let sum = parseInt(this.tzItem.UF_PRICE_NOT_NDS)/100*Funcs.getDirectoryValue(this.directory.stavka_nds,this.tzItem.UF_STAVKA_NDS).UF_VALUE
      if (!sum) {
        sum = 0
      }
      return sum.toFixed(2)
    },
    sumWNds() {
      let sum = parseInt(this.tzItem.UF_PRICE_NOT_NDS)+(this.tzItem.UF_PRICE_NOT_NDS/100*Funcs.getDirectoryValue(this.directory.stavka_nds,this.tzItem.UF_STAVKA_NDS).UF_VALUE)
      if (!sum) {
        sum = 0
      }
      return sum.toFixed(2)
    },
    downloadTZ() {
      TZService.getLinkTZPDF(this.tzItem.ID).then(({data}) => {
        window.open(API_URL.replace(new RegExp("[\\/]+$"), "") + data.link);
      }, this)
    },
  }
}
</script>

<style>
.small_calendar_mid_100 {
  width: 158px;
}
</style>