import {MethodsService} from "@/common/api.service";
import {
  FETCH_METHODS
} from "./actions.type";

import {
  FETCH_METHODS_START, FETCH_METHODS_END, FETCH_METHODS_ERROR
} from "./mutations.type";

const state = {
  methodsArchiveLink: {},
  methods: {
    counts: 0,
    items: []
  },
  isMethodsLoading: true,
  isMethodsLoadingError: false
};

const getters = {
  methods(state) {
    return state.methods;
  },
  isMethodsLoading(state) {
    return state.isMethodsLoading;
  },
  isMethodsLoadingError(state) {
    return state.isMethodsLoadingError;
  }
};

const actions = {
  [FETCH_METHODS]({ commit }, params) {
    commit(FETCH_METHODS_START);
    return MethodsService.list(params.type, params)
      .then(({ data }) => {
        commit(FETCH_METHODS_END, data);
      })
      .catch(error => {
        commit(FETCH_METHODS_ERROR);
        throw new Error(error);
      });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_METHODS_START](state) {
    state.isMethodsLoading = true;
  },
  [FETCH_METHODS_END](state, methods) {
    state.methods = methods;
    state.isMethodsLoading = false;
  },
  [FETCH_METHODS_ERROR](state) {
    state.isMethodsLoading = false;
    state.isMethodsLoadingError = true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
