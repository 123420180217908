<template>
    <div class="main-wraper">
        <Header/>
        <section class="content-area">
            <div class="content-indent">
              <div class="breadcrumbs">
                <router-link :to="{name: 'News'}">Новости</router-link>
                &gt;
                <span>{{ newsItem.NAME }}</span>
              </div>
                <div class="page-top-title">
                    <div class="page-top-title__item">
                        <h2 class="content-title">Новости</h2>
                    </div>
                    <div class="page-top-title__item text-right">
                    </div>
                </div>
                <!-- page-top-title -->
                <div class="content-tab-area">
                    <ul class="content-tab-menu">
                        <router-link tag="li" class="tab-menu__item tab_active" :to="{'name': 'News'}">Новости</router-link>
                        <router-link tag="li" class="tab-menu__item" :to="{'name': 'Methods'}">Методические материалы</router-link>
                        <router-link tag="li" class="tab-menu__item" :to="{'name': 'Tariffs'}">Тарифы</router-link>
                    </ul>
                    <Item/>
                </div>
                <!-- content-tab-area -->
            </div>
            <!-- content-indent -->
        </section>
        <!-- content-area -->
        <Footer />
    </div>
</template>

<script>
    import Item from "@/components/site/NewsItem";
    import Footer from "@/views/site/Footer";
    import Header from "@/views/site/Header";
    import {mapGetters} from "vuex";

    export default {
        name: "ViewNews",
        components: {Header, Footer, Item},
      computed: {
        ...mapGetters([
          "newsItem",
          "isNewsLoading"
        ]),
      },
    }
</script>

<style scoped>

</style>