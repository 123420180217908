<template>
  <div v-if="!isAccreditationOMLoading"
       class="content-indent-wrapper"
  >
    <div class="content-indent">
      <div class="breadcrumbs">
        <router-link :to="{name: 'LK/accreditation'}">Аккредитация организаций на площадке</router-link>
        <span v-if="accreditationOMItem.COMPANY.UF_FULL_NAME">
          > Аккредитация организации {{ accreditationOMItem.COMPANY.UF_FULL_NAME }}
        </span>
      </div>
      <div class="page-top-title">
        <div class="page-top-title__item">
          <h2 class="content-title">Аккредитация организаций на площадке</h2>
        </div>
        <div class="page-top-title__item text-right">
        </div>
      </div>

      <!--   Форма со всеми сведениями   -->
      <div class="content-tab__panel tab_active">
        <div class="container-fluid">
          <!--     Сведения об организации     -->
          <div class="content-tab-wrapper">
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="visible.mainInfo = !visible.mainInfo" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: !visible.mainInfo}"/>
                  Сведения об организации
                </a>
              </div>
            </div>
            <div v-if="visible.mainInfo"
                 class="content-tab-indent"
            >
              <div v-for="row in companyInfo.stack"
                   :key="row"
                   class="row"
              >
                <div class="col-md-3 col-lg-2 col-name">
                  {{ companyInfo[row] }}
                  <span v-if="row !== 'UF_IS_BROKER'" class="required_color">*</span>
                </div>
                <div v-if="row === 'UF_IS_BROKER'"
                     class="col-md-9 col-lg-10 col-data"
                >
                  <input type="checkbox" :value="accreditationOMItem.COMPANY[row]" disabled>
                </div>
                <div v-else-if="row === 'UF_DATE_GOS_REGISTR' || row === 'UF_DATE_EGRYUL'"
                     class="col-md-9 col-lg-10 col-data"
                >
                  {{ accreditationOMItem.COMPANY[row] | dateFormat }}
                </div>
                <div v-else
                     class="col-md-9 col-lg-10 col-data"
                >
                  {{ accreditationOMItem.COMPANY[row] }}
                </div>
              </div>
            </div>
          </div>

          <!--     Документы для аккредитации     -->
          <div class="content-tab-wrapper">
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="visible.accreditationDocs = !visible.accreditationDocs" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: !visible.accreditationDocs}"/>
                  Сведения об организации
                </a>
              </div>
            </div>
            <div v-if="visible.accreditationDocs"
                 class="content-tab-indent"
            >
              <div class="row">
                <div class="col-md-12 col-name">
                  <div class="stl-pb_10">
                    <span class="i-doc-desc">
                      <i class="i-doc i-doc_y stl-vermid"></i>
                      — документ принят
                    </span>
                    <span class="i-doc-desc">
                      <i class="i-doc i-doc_n stl-vermid"></i>
                      — документ отклонен
                    </span>
                    <span class="i-doc-desc">
                      <i class="i-doc i-doc_np stl-vermid"></i>
                      — документ не проверен
                    </span>
                  </div>
                  <div class="content-tab-wrapper" :key="item.ID" v-for="item in directory.vid_documents_accreditation">
                    <div class="row row_link_title">
                      <div class="col-md-12">
                        <a @click="toggleVid(item)" class="link_title d-inline-block">
                          <i class="i_arrow" :class="{i_arrow_hidden: item.hide}"/>
                          {{ item.UF_NAME }}
                        </a>
                        <div v-if="item.UF_DESC" class="d-inline-block"> ({{ item.UF_DESC }})</div>
                      </div>
                    </div>

                    <!--          Документы аккредитации с ЭЦП            -->
                    <div class="content-tab-indent" v-if="!item.hide">
                      <div v-if="docs && accreditationOMItem.DOCUMENTES[item.ID]">
                        <div v-for="doc in accreditationOMItem.DOCUMENTES[item.ID]"
                             :key="doc.ID"
                             class="col-md-12 col-name"
                        >
                          <div class="stl-pb_10" v-if="doc.UF_FILE_BASE_NAME">
                            <span class="versia-box_doc">
                              <a :href="docUrl(doc)"
                                 :class="{'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                          'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                          'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                          'link-doc-y': doc.UF_STATUS_DOC_ID === 4,}"
                              >
                                {{ doc.UF_FILE_BASE_NAME }}
                              </a>
                            </span>
                            <span v-if="doc.UF_IS_SIGN_ECP"
                                  v-tooltip="{ content: new Object(() => ecpInfo(doc)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }"
                            >
                              <span class="e-signature">эцп</span>
                              <span class="e-signature_data">{{ doc.UF_SIGN_ECP_DATE | dateFormat }}</span>
                            </span>
                          </div>
                          <div class="">
                            <div class="col-name" v-if="doc.UF_COMMENT">
                              Комментарий: {{ doc.UF_COMMENT }}<br/><br/>
                            </div>
                            <div v-if="doc.UF_DATE_ADD_NEW_VERSION" class="col-name">
                              Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                            </div>
                            <button
                                v-if="isButtonStatusVisible"
                                @click="showChangeStatusModal(item, doc.ID)"
                                type="button"
                                class="link_style-blue"
                            >
                              изменить статус
                            </button>
                          </div>
                          <div class="col-name" v-if="doc.UF_DATE_ADD_NEW_VERSION">
                            Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                          </div>
                          <div class="content-tab-wrapper" v-if="doc.VERSION_LIST.length > 0">
                            <div class="row row_link_title">
                              <div class="col-md-12">
                                <a @click="toggleVid(doc)"
                                   class="link_title link_suptitle">
                                  <i class="i_arrow" :class="{i_arrow_hidden: !doc.hide}"></i>
                                  Предыдущие версии
                                </a>
                              </div>
                            </div>
                            <div v-if="doc.hide">
                              <div class="content-tab-indent versia-tab-indent"
                                   v-for="dh in doc.VERSION_LIST"
                                   :key="dh.ID">
                                <div class="row">
                                  <div class="col-md-12 col-name">
                                    Название: {{ dh.UF_NAME }}
                                  </div>
                                  <div class="col-md-12 col-name">
                                    <span class="versia-box_doc">
                                      <a :href="docUrl(doc)"
                                         :class="{'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                  'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                  'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                  'link-doc-y': doc.UF_STATUS_DOC_ID === 4,}"
                                      >
                                        {{ dh.UF_FILE_BASE_NAME }}
                                      </a>
                                      <span class="link-doc_required">{{ dh.UF_COMMENT }}</span>
                                    </span>
                                    <span v-if="doc.UF_IS_SIGN_ECP"
                                          v-tooltip="{ content: new Object(() => ecpInfo(dh)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                                      <span v-if="dh.UF_IS_SIGN_ECP" class="e-signature">эцп</span>
                                      <span v-if="dh.UF_IS_SIGN_ECP" class="e-signature_data">
                                        {{ dh.UF_SIGN_ECP_DATE | dateFormat }}
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-name" v-if="dh.UF_COMMENT">
                                    Комментарий: {{ dh.UF_COMMENT }}<br/><br/>
                                  </div>
                                  <div class="col-name" v-if="dh.UF_DATE_ADD_NEW_VERSION">
                                    Срок предоставления новой версии: {{ dh.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--  Модальное окно добавления документа  -->
                    <div class="modal fade in show"
                         v-if="uploadModalIsShowed(item)"
                         tabindex="-1"
                         role="dialog"
                         aria-labelledby="myModalLabel">
                      <div class="modal-dialog "
                           style="width: 490px"
                           role="document">
                        <div class="modal-content">
                          <div class="modal-add-document"
                               style="z-index: 10000;width: 490px">
                            <div class="modal-header main-clear">
                              <span class="modal-title">изменение статуса документа</span>
                              <a @click="closeChangeStatusModal(item)"
                                 class="modal-exit stl-FR">
                              </a>
                            </div>
                            <div class="modal-body">
                              <div class="modal_row main-clear">
                                <span class="modal-field_name">Статус</span>
                                <span>
                                  <select v-model="uploadCard.UF_STATUS_DOC_ID"
                                          class="main_input main_select main-inlineBlock">
                                    <option v-for="status in arrayDocsStatuses"
                                            :key="status.ID"
                                            :value="status.ID"
                                    >
                                      {{ status.UF_NAME }}
                                    </option>
                                  </select>
                                </span>
                              </div>
                              <div v-if="uploadCard.UF_STATUS_DOC_ID === 2"
                                   class="modal_row main-clear"
                              >
                                <span class="modal-field_name">Комментарий</span>
                                <textarea-autosize
                                    v-model="uploadCard.UF_COMMENT"
                                    class="main_textarea"
                                />
                              </div>
                              <div v-if="uploadCard.UF_STATUS_DOC_ID === 2"
                                   class="modal_row main-clear"
                              >
                                <span class="modal-field_name">
                                  Срок предоставления новой версии документа (дней)
                                </span>
                                <datepicker style="display: inline-block"
                                            v-model="uploadCard.UF_DATE_ADD_NEW_VERSION"
                                            :language="ru"
                                            :format="dateFormat"
                                            input-class="main_input small_calendar small_calendar_mid_100 main-inlineBlock"
                                />
                              </div>
                              <div class="modal_row main-clear">
                                <button
                                    @click="uploadDocument(item)"
                                    :disabled="uploadCard.UF_STATUS_DOC_ID === null"
                                    class="link_style-blue modal_btn_wid"
                                    type="button"
                                    style="width: 30%"
                                >
                                  сохранить
                                </button>
                                <button
                                    @click="closeChangeStatusModal(item)"
                                    type="button"
                                    class="link_style-white stl-FR modal_btn_wid"
                                    style="width: 30%"
                                >
                                  отмена
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Комментарий оператора площадки   -->
      <div class="content-tab-wrapper">
        <div class="row row_link_title">
          <div class="col-md-12">
            <span class="link_title">Комментарий оператор площадки</span>
          </div>
        </div>
        <div class="content-tab-indent">
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">
              <textarea-autosize
                  v-model="comment"
                  class="main_textarea"
              />
            </div>
          </div>
        </div>
      </div>

      <!--   Кнопки для формы   -->
      <div class="container-fluid">
        <div class="content-tab-wrapper">
          <div class="btn-box">
            <button
                @click="saveComment"
                :disabled="!comment.length"
                class="link_style-blue"
                type="button"
            >
              Сохранить комментарий
            </button>
            <button v-if="isButtonStatusVisible"
                    @click="changeStatusAccreditation(1)"
                    class="link_style-blue"
                    type="button"
            >
              Вернуть на доработку
            </button>
            <button v-if="isButtonStatusVisible"
                    @click="changeStatusAccreditation(2)"
                    class="link_style-blue"
                    type="button"
            >
              Аккредитовать
            </button>
            <button v-if="isButtonStatusVisible"
                    @click="changeStatusAccreditation(4)"
                    class="link_style-blue"
                    type="button"
            >
              Отклонить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import Vue from "vue";
import "@/scripts/cadesplugin_api";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
import ApiService, {RequestService} from "@/common/api.service";
import {API_URL} from "@/common/config";

export default {
  name: "AccreditationItem",
  data() {
    return {
      ecpCert: "",
      ecpCerts: [],
      ecpPluginEnabled: false,
      showDocs: true,
      ru: ru,
      uploadCard: {
        ID: 0,
        UF_STATUS_DOC_ID: null,
        UF_COMMENT: '',
        UF_DATE_ADD_NEW_VERSION: '',
      },
      comment: '',
      toasterErrors: [],
      visible: {
        mainInfo: true,
        accreditationDocs: true,
      },
      companyInfo: {
        UF_DATE_EGRYUL: 'Дата внесения записи в ЕГРЮЛ',
        UF_DATE_GOS_REGISTR: 'Дата государственной регистрации',
        UF_FULL_NAME: 'Полное наименование (в соответствии с уставом)',
        UF_INN: 'ИНН',
        UF_IS_BROKER: 'Брокер',
        UF_NAME: 'Краткое наименование',
        UF_OGRN: 'ОГРН',
        stack: [
          'UF_INN',
          'UF_OGRN',
          'UF_FULL_NAME',
          'UF_NAME',
          'UF_DATE_EGRYUL',
          'UF_DATE_GOS_REGISTR',
          'UF_IS_BROKER'
        ],
      },
    }
  },
  components: {Datepicker},

  async mounted() {
    await this.fetchItem();
    await this.$store.dispatch('fetchDirectories', [
      "vid_documents_accreditation",
      "status_documents_request",
    ]);
  },

  computed: {
    ...mapGetters([
      "directory",
      "accreditationOMItem",
      "isAccreditationOMLoading",
      "isAccreditationOMLoadingError",
    ]),
    docs() {
      return !!this.accreditationOMItem && !!this.accreditationOMItem.DOCUMENTES;
    },
    isButtonStatusVisible() {
      return this.accreditationOMItem.STATUS.CODE === 'check_accreditation'
          || this.accreditationOMItem.STATUS_REPEAT.CODE === 'check_accreditation';
    },
    arrayDocsStatuses() {
      return this.directory.status_documents_request
          .filter(el => el.UF_CODE === 'document_rejected' || el.UF_CODE === 'document_accepted');
    },
  },

  methods: {
    // Запрос данных перед отрисовкой компонента
    async fetchItem() {
      this.$store.dispatch('fetchAccreditationOMItem', this.$route.params.id).then(() => {
        document.title = unescape(this.accreditationOMItem.META_TITLE);
        this.isLoaded = true;
      });
      if (this.directory.vid_documents_accreditation.length !== 0) {
        this.directory.vid_documents_accreditation.forEach(function (item) {
          Vue.set(item, 'showChangeStatusModal', false)
        })
      }
    },

    toggleVid(item) {
      Vue.set(item, 'hide', !item.hide);
    },

    toggleDocs() {
      this.showDocs = !this.showDocs
    },

    // Форматирование для датапикера
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    // Составляет ссылку для скачивания документа
    docUrl(object) {
      return API_URL.replace(new RegExp("[\\/]+$"), "") + object.UF_FILE;
    },

    // Условие показа модалки добавления документа
    uploadModalIsShowed(item) {
      return item.showChangeStatusModal
    },

    // Открытие модального окна и установка начальных параметров документа
    showChangeStatusModal(item, doc) {
      this.uploadCard.ID = doc;
      Vue.set(item, 'showChangeStatusModal', true)
    },

    // Закрытие модального окна
    closeChangeStatusModal(item) {
      this.uploadCard.UF_STATUS_DOC_ID = null;
      this.uploadCard.UF_COMMENT = '';
      this.uploadCard.UF_DATE_ADD_NEW_VERSION = '';
      Vue.set(item, 'showChangeStatusModal', false);
    },

    // Изменение статуса файла в системе
    uploadDocument(item) {
      let formData = new FormData();
      formData.append("UF_STATUS_DOC_ID", this.uploadCard.UF_STATUS_DOC_ID);
      if (this.uploadCard.UF_COMMENT.length) {
        formData.append("UF_COMMENT", this.uploadCard.UF_COMMENT);
      }
      if (this.uploadCard.UF_DATE_ADD_NEW_VERSION.length) {
        formData.append("UF_DATE_ADD_NEW_VERSION", this.uploadCard.UF_DATE_ADD_NEW_VERSION);
      }
      ApiService.post(`/accreditation/documents/changeStatusDocument/${this.$route.params.id}/${this.uploadCard.ID}/`, formData).then(({data}) => {
        if (!data.error) {
          this.closeChangeStatusModal(item);
          this.successProcess('Статус успешно изменен');
          this.fetchItem();
        } else {
          data.errorAr.forEach(function (err) {
            this.errorProcess(err.TEXT);
          }, this);
          this.closeChangeStatusModal(item);
        }
      }, this)
    },

    // Сохранение комментария оператора площадки
    saveComment() {
      ApiService.post(`/accreditation/saveAccreditationComment/${this.$route.params.id}`, {UF_COMMENT: this.comment}).then(({data}) => {
        if (!data.error) {
          this.successProcess('Комментарий успешно сохранен');
          this.comment = '';
          this.fetchItem();
        } else {
          data.errorAr.forEach(function (err) {
            this.errorProcess(err.TEXT);
          }, this);
          this.comment = '';
        }
      }, this)
    },

    // Сохранение комментария оператора площадки
    changeStatusAccreditation(code) {
      ApiService.post(`/accreditation/changeStatusAccreditationCompany/${this.$route.params.id}`, {UF_STATUS_ID: code}).then(({data}) => {
        if (!data.error) {
          this.successProcess('Статус успешно изменен');
          this.fetchItem();
        } else {
          data.errorAr.forEach(function (err) {
            this.errorProcess(err.TEXT);
          }, this);
        }
      }, this)
    },

    // Получение информации о файле по ЭЦП
    async ecpInfo(doc) {
      return await RequestService.getECPInfoFile(doc.ID).then(({data}) => {
        return `<div class="modal-content">
<div class="text-center"><span class="modal-title">Документ подписан <br> электронной
подписью</span></div>
<div class="modal-body">
<div class="modal_row modal_flex">
<span class="modal-field_name">Сертификат</span>
<span>${data.result.SERTIFICATE_NUMBER}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Организация</span>
<span>${data.result.COMPANY}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Владелец</span>
<span>${data.result.OWNER_FIO}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Должность</span>
<span>${data.result.POSITION}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Действителен</span>
<span>${data.result.DATE_USE_SERTIFICATE}</span>
</div>
</div>
</div>`
      }).catch(() => 'ERROR')
    },

    // Вывод сообщения об успешности операции
    successProcess(text) {
      this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
          text,
          {
            theme: "green",
            position: "bottom-right",
            duration: null,
            action: [
              {
                text: "Закрыть",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }
              },
              {
                text: "Закрыть все",
                onClick: () => {
                  this.toasterErrors.forEach(function (err) {
                    err.goAway(0);
                  });
                }
              }
            ]
          }
      );
    },

    // Вывод сообщения об ошибке
    errorProcess(text) {
      this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
          text,
          {
            theme: "bubble",
            position: "bottom-right",
            duration: null,
            action: [
              {
                text: "Закрыть",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }
              },
              {
                text: "Закрыть все",
                onClick: () => {
                  this.toasterErrors.forEach(function (err) {
                    err.goAway(0);
                  });
                }
              }
            ]
          }
      );
    },
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    }
  },
}
</script>
