<template>
  <div>
    <notification
        :messages="messageNotification"
    >
    </notification>
    <form action="#" class="" @submit.prevent="changeProfile">
      <div class="content-tab__panel tab_active">
        <div class="col-xs-12 status-accreditation"
        >Статус организации на площадке:
          <span
              v-show="accreditationAnswer"
              v-if="accreditation"
              class="status-accreditation__yes"
          >
            Аккредитована</span>
          <span
              v-show="accreditationAnswer"
              v-else
              class="status-accreditation__no"
          >
            Не аккредитована</span>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name"><label for="login">Логин</label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text"
                   name="login"
                   class="auth_input"
                   :value="changeData.LOGIN"
                   disabled/>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="lastname">
              Фамилия
              <span class="required_color">*</span>
            </label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text"
                   name="lastname"
                   class="auth_input"
                   v-model.trim="changeData.LAST_NAME"
                   :class="{invalid: !$v.changeData.LAST_NAME.required}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="!$v.changeData.LAST_NAME.required && showValidError">Поле должно быть заполнено</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('LAST_NAME') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="name">
              Имя
              <span class="required_color">*</span>
            </label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text"
                   name="name"
                   class="auth_input"
                   v-model.trim="changeData.NAME"
                   :class="{invalid: !$v.changeData.NAME.required}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="!$v.changeData.NAME.required && showValidError">Поле должно быть заполнено</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('NAME') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="secondname">Отчество</label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text"
                   name="secondname"
                   class="auth_input"
                   v-model.trim="changeData.SECOND_NAME"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="avatar">Аватар</label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <div class="avatar-upload">
              <label>
                <input type="file" name="avatar-file" class="avatar-file-inp" accept="image/*,image/jpeg"
                       @change="getImage">
                <div class="avatar-file__edit"></div>
                <img v-if="!!changeData.PERSONAL_PHOTO_FILE_NAME"
                     :src="changeData.PERSONAL_PHOTO_FILE"
                     width="50px"
                     height="50px"
                     alt="">
                <img v-else-if="getIsAvatar"
                     :src="avatarGet"
                     alt="avatar"
                     width="50px"
                     height="50px">
                <img v-else
                     class="menu_img"
                     src="/img/user_avatar.png"
                     alt="">
              </label>
            </div>
          </div>


          <div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">
            <label for="email">E-mail<span class="required_color">*</span></label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text"
                   name="email"
                   class="auth_input"
                   id="email_mask"
                   v-model.trim="changeData.EMAIL"
                   @keydown.space.prevent
                   :class="{invalid: !$v.changeData.EMAIL.required && !$v.changeData.EMAIL.email}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="!$v.changeData.EMAIL.required && showValidError">введите email</span>
            <span class="reg-danger reg-danger_auth"
                  v-else-if="!$v.changeData.EMAIL.email && showValidError">email введен неверно</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('EMAIL') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="pass">Пароль</label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="password"
                   name="pass"
                   autocomplete="new-password"
                   class="auth_input"
                   v-model.trim="changeData.PASSWORD"
                   @keydown.space.prevent
                   :class="{invalid: $v.changeData.PASSWORD.$dirty && !$v.changeData.PASSWORD.valid}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="$v.changeData.PASSWORD.$dirty && !$v.changeData.PASSWORD.valid && showValidError">Длина пароля не меньше 8 символов. Пароль должен содержать латинские буквы, как минимум одну заглавную, а также одну цифру</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('PASSWORD') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="change-pass">Повтор пароля</label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="password"
                   name="change-pass"
                   class="auth_input"
                   v-model.trim="changeData.CONFIRM_PASSWORD"
                   @keydown.space.prevent
                   :class="{invalid: ($v.changeData.CONFIRM_PASSWORD.$dirty && !$v.changeData.CONFIRM_PASSWORD.sameAsPassword)}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="$v.changeData.CONFIRM_PASSWORD.$dirty && !$v.changeData.CONFIRM_PASSWORD.sameAsPassword && showValidError">Пароли не совпадают</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('CONFIRM_PASSWORD') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="mob-tell">Телефон<span class="required_color">*</span></label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="tel"
                   name="mob-tell"
                   id="phone_number_mask"
                   class="auth_input"
                   v-model="changeData.PERSONAL_PHONE"
                   :class="{invalid: !$v.changeData.PERSONAL_PHONE.validPhone}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="!$v.changeData.PERSONAL_PHONE.validPhone && showValidError">Неверно введен номер телефона</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('PERSONAL_PHONE') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class=" col-md-3 col-lg-2 col-name">
            <label for="snails">СНИЛС<span class="required_color">*</span></label>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text"
                   name="snails"
                   id="snails_mask"
                   class="auth_input"
                   v-model="changeData.UF_SNILS"
                   :class="{invalid: !$v.changeData.UF_SNILS.validSNILS}"/>
            <span class="reg-danger reg-danger_auth"
                  v-if="!$v.changeData.UF_SNILS.validSNILS && showValidError">номер СНИЛС состоит из 11 цифр</span>
            <span class="reg-danger reg-danger_auth"
                  v-if="serverErrors">{{ showServerError('UF_SNILS') }}
            </span>
          </div>
        </div>
        <div class="required_color stl-pb_30">Все поля, отмеченные * - обязательны для заполнения</div>
        <div class="btn-box">
          <button class="link_style-blue"
                  type="submit">
            сохранить
          </button>
          <button class="link_style-white"
                  type="button"
                  @click="profileChangeCancel">
            отмена
          </button>
        </div>
      </div>
    </form>
    <a href="#" class="link_up"></a>
  </div>
</template>

<script>
import Inputmask from 'inputmask';
import {
  email,
  required,
  sameAs
} from "vuelidate/lib/validators";
import ApiService from "@/common/api.service";
import {SET_NAME} from "@/store/mutations.type";
import {SET_AVATAR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import {API_URL} from "@/common/config";
import Notification from "@/components/popup/Notification";

export default {
  name: "PersonalCabinet",
  metaInfo() {
    return {
      title: this.changeData.META_TITLE,
    }
  },
  components: {
    Notification,
  },
  data() {
    return {
      changeData: {
        EMAIL: "",
        ID: 3,
        LAST_NAME: "",
        LOGIN: "",
        NAME: "",
        PERSONAL_PHONE: "",
        PERSONAL_PHOTO: "",
        PERSONAL_PHOTO_FILE: "",
        PERSONAL_PHOTO_FILE_NAME: "",
        SECOND_NAME: "",
        UF_SNILS: "",
        PASSWORD: "",
        CONFIRM_PASSWORD: "",
      },
      ACTIVE_ROLE: this.$store.getters.currentUser.ACTIVE_ROLE,
      ACTIVE_ROLE_CODE: this.$store.getters.currentUser.ACTIVE_ROLE_CODE,
      serverErrors: '',
      otherErrors: '',
      avatarGet: '',
      forReset: {},
      showValidError: false,
      API_URL: '',
      messageNotification: [],
      accreditation: false,
      accreditationAnswer: '',
    }
  },
  computed: {
    ...mapGetters(["getAvatar", "getIsAvatar"]),
  },
  mounted() {
    this.API_URL = API_URL
    ApiService.get3("users/getProfileUser/")
        .then(({data}) => {
          this.forReset = {...data.result}
          this.changeData = {...data.result, PERSONAL_PHOTO_FILE: '', PERSONAL_PHOTO_FILE_NAME: ''};
          this.avatarGet = this.API_URL + data.result.PERSONAL_PHOTO;
          this.accreditationAnswer = data.result.UF_STATUS;
          if (data.result.UF_STATUS === "Аккредитован") {
            this.accreditation = true;
          } else {
            this.accreditation = false;
          }
        });
    let emailMask = new Inputmask("email");
    emailMask.mask(document.getElementById('email_mask'));
    let phoneMask = new Inputmask("+7(999) 999-99-99");
    phoneMask.mask(document.getElementById('phone_number_mask'));
    let snailsMask = new Inputmask("999-999-999 99");
    snailsMask.mask(document.getElementById('snails_mask'));
  },
  validations: {
    changeData: {
      EMAIL: {email, required},
      PASSWORD: {
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsLength = value && value.length > 7;
          let emptyString;
          value === '' ? emptyString = false : emptyString = true;
          return containsUppercase && containsLowercase && containsNumber && containsLength || emptyString;
        }
      },
      CONFIRM_PASSWORD: {
        sameAsPassword: sameAs('PASSWORD')
      },
      NAME: {required},
      LAST_NAME: {required},
      PERSONAL_PHONE: {
        validPhone: function (value) {
          const containsPhone = /(\+7)\(\d{3}\)\s*\d{3}-\d{2}-\d{2}/.test(value);
          const containsPhoneDefault = /\d{10}/
          return containsPhone || containsPhoneDefault;
        }
      },
      UF_SNILS: {
        validSNILS: function (value) {
          const containsSNILS = /\d{3}-\d{3}-\d{3}\s*\d{2}/.test(value);
          const containsSNILSDefault = /\d{11}/.test(value);
          return containsSNILS || containsSNILSDefault;
        }
      },
    },
  },
  methods: {
    // roleConditionCompany() {
    //   if ((this.ACTIVE_ROLE === 9 && this.ACTIVE_ROLE_CODE === "broker") || (this.ACTIVE_ROLE === 8 && this.ACTIVE_ROLE_CODE === "users")) {
    //     return true;
    //   }
    // },
    getImage(event) {
      this.changeData.PERSONAL_PHOTO_FILE_NAME = event.target.files[0].name;
      let image = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        this.avatarGet = event.target.result;
        this.changeData.PERSONAL_PHOTO_FILE = event.target.result;
      }
    },
    showServerError(inputName) {
      let arrayError = this.serverErrors
          .filter((item) => {
            return (item["FIELD"] === inputName)
          })
          .map((item) => {
            return item["TEXT"];
          });
      return arrayError[0];
    },
    changeProfile() {
      if (this.$v.$invalid) {
        this.showValidError = true;
        this.$v.$touch();
        return;
      }
      ApiService.post("users/setProfileUser/", {...this.changeData})
          .then(({data}) => {
                if (data.result.error === true) {
                  this.serverErrors = data.result.errorArr;
                  return;
                }
                let fullName = '';
                let timeStamp = Date.now().toLocaleString();
                if (this.changeData.SECOND_NAME) {
                  // ЭТО ЕСЛИ В ВЕРХНЕМ МЕНЮ НАДО ФИО ПОЛНОСТЬЮ
                  // fullName = `${this.changeData.LAST_NAME} ${this.changeData.NAME} ${this.changeData.SECOND_NAME}`
                  fullName = `${this.changeData.LAST_NAME} ${this.changeData.NAME}`
                } else {
                  fullName = `${this.changeData.LAST_NAME} ${this.changeData.NAME}`
                }
                this.$store.commit(SET_NAME, fullName)
                if (data.result.isUpdateProfile && data.result.AVATAR) {
                  this.$store.commit(SET_AVATAR, data.result.AVATAR)
                }
                this.messageNotification.unshift({
                  name: "Ваши изменения сохранены!",
                  id: timeStamp
                });
              }
          )
          .catch(error => {
            this.otherErrors = error;
            return;
          })
    },
    profileChangeCancel() {
      this.changeData = {...this.forReset, PERSONAL_PHOTO_FILE: '', PERSONAL_PHOTO_FILE_NAME: ''};
    },
  }
}


</script>

<style scoped>

</style>
