<template>
  <span>
        <div class="user-panel" v-if="isAuthenticated">
            <div class="user-panel__data">
                <span class="user-panel__avatar" v-if="getIsAvatar" @click="goToLK">
                    <img :src="getAvatar"
                         alt="avatar"
                         width="50px" height="50px">
                </span>
                <span class="user-panel__avatar" v-else @click="goToLK">
                    <img src="/img/user_avatar.png" alt=""
                         class="menu_img">
                </span>
                <span class="user-panel__name"
                      @mouseenter="isShowMenu = !isShowMenu"
                >
                    {{ getFullName }}
                </span>
                <button class="user-panel__btn-menu menu-dropdown__btn"
                        @mouseenter="isShowMenu = !isShowMenu"
                        :class="{ 'user-panel__btn-menu-active': isShowMenu }"
                >
                </button>
                <div class="user-panel__menu"
                     v-if="isShowMenu"
                     @mouseleave="isShowMenu=!isShowMenu">
                    <div>
                        <div v-for="(item, elKey) of menuItems"
                             :key="elKey"
                             :name="item"
                             class="user-panel__menu-link"
                             @click.prevent="dropDownRouter(item)"
                        >{{ item }}</div>
                    </div>
                </div>
            </div>
          <!-- user-panel__data -->
            <div class="user-panel__exit" @click="logout"></div>
        </div>
        <router-link v-else :to="{name: 'Auth'}" class="link-login">выполнить вход</router-link>
        <ChangeOrg
            v-if="isOrgChangeVisible"
            @close="close"
            @closeAndChange="closeAndChange"
            @selectOrg="selectOrg"
            :changeOrganization="changeOrganization"
        />
        <ChangeRole
            v-if="isRoleChangeVisible"
            @closeRole="closeRole"
            @closeAndChangeRole="closeAndChangeRole"
            @selectRole="selectRole"
            :changeRole="userMenu.GROUP_ID"
            :activeRole="userMenu.ACTIVE_ROLE"
        />
    </span>
</template>

<script>
import {mapGetters} from "vuex";
import {LOGOUT, UPDATE_USER_ORG, UPDATE_USER_ROLE} from "@/store/actions.type";
import {SET_AVATAR, SET_NAME} from "@/store/mutations.type";
import ApiService from "@/common/api.service";
import ChangeOrg from "@/components/popup/ChangeOrg";
import ChangeRole from "@/components/popup/ChangeRole";
import {UserService} from "@/common/api.service";

export default {
  name: "UserMenu",
  components: {
    ChangeOrg,
    ChangeRole,
  },
  data() {
    return {
      isShowMenu: false,
      menuItems: [
        "Личный кабинет",
        "Профиль пользователя",
      ],
      serverErrors: '',
      userMenu: {
        ACTIVE_ROLE: '',
        ACTIVE_ROLE_CODE: '',
        GROUP_ID: [],
      },
      loading: false,
      isOrgChangeVisible: false,
      isRoleChangeVisible: false,
      changeOrganization: [],
      selectedRole: '',
      selectedRoleId: null,
      selectedOrg: '',
      popErrors: [],
    }
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "getFullName", "getAvatar", "getIsAvatar"]),
  },

  beforeCreate() {
    if (this.$store.getters.isAuthenticated) {
      if (!this.$store.getters.getFullName) {
        this.$store.commit(SET_NAME, this.$store.getters.currentUser.NAME);
      }
      this.$store.commit(SET_AVATAR, this.$store.getters.currentUser.AVATAR);
    }
  },

  mounted() {
    if (this.$store.getters.currentUser) {
      this.userMenu.ACTIVE_ROLE = this.$store.getters.currentUser.ACTIVE_ROLE;
      this.userMenu.ACTIVE_ROLE_CODE = this.$store.getters.currentUser.ACTIVE_ROLE_CODE;
      this.userMenu.GROUP_ID = this.$store.getters.currentUser.GROUP_ID;
    }
    if (this.userMenu.ACTIVE_ROLE === 9 && this.userMenu.ACTIVE_ROLE_CODE === "broker") {
      this.menuItems.push("Организации, выдавшие доверенность");
      ApiService.get3("orgs/getOrgTrastUserList/")
          .then(({data}) => {
            this.changeOrganization = data;
          })
          .catch(error => {
            console.log(error);
          })
    }
    // ДОПОЛНИТЕЛЬНАЯ ФУНКЦИЯ ДЛЯ ОРГАНИЗАЦИЙ
    // if (this.userMenu.ACTIVE_ROLE === 8 && this.userMenu.ACTIVE_ROLE_CODE === "users") {
    //   this.menuItems.push("Организации, выдавшие доверенность");
    //   // функционал смены организации в меню шапки
    //   // this.menuItems.push("Сменить организацию");
    //   ApiService.get3("orgs/getOrgTrastUserList/")
    //       .then(({data}) => {
    //         console.log(data);
    //         this.changeOrganization = data;
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       })
    // }
    if (this.userMenu.GROUP_ID && this.userMenu.GROUP_ID.length > 1) {
      this.menuItems.push("Сменить роль");
    }
  },
  methods: {
    logout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => {
            this.$router.push({name: "Home"})
          });
      window.localStorage.clear();
      window.location.href = '/';
    },
    goToLK() {
      this.$router.push({name: 'LK/user/profile'});
    },
    dropDownRouter(item) {
      switch (item) {
        case "Личный кабинет":
          return this.$router.push({name: 'LK/request'});
        case "Профиль пользователя":
          return this.$router.push({name: 'LK/user/profile'});
        case "Организации, выдавшие доверенность":
          return
        case "Сменить организацию":
          return this.showOrgChange();
        case "Сменить роль":
          return this.showRoleChange();
      }
    },

    selectRole(data) {
      const roleObject = this.userMenu.GROUP_ID.filter(el => el.NAME.includes(data))[0];
      this.selectedRoleId = roleObject.ID
      this.selectedRole = data;
    },
    closeRole() {
      this.selectedRole = '';
      this.isRoleChangeVisible = false;
    },
    showRoleChange() {
      this.isRoleChangeVisible = true;
    },
    closeAndChangeRole() {
      this.isRoleChangeVisible = false;
      this.$store.dispatch(UPDATE_USER_ROLE, this.selectedRoleId)
          .then(() => {
            UserService.getUserDefaultSetting()
          })
          .then(() => this.$router.push({name: 'LK/request'}));
    },

    selectOrg(data) {
      const lastLeftBracket = data.lastIndexOf('(');
      const lastRightBracket = data.lastIndexOf(')');
      const orgOGRN = +data.split('').slice(lastLeftBracket + 6, lastRightBracket).join('');
      const objectOrg = this.changeOrganization.filter(el => el.COMPANY_OGRN === orgOGRN);
      this.selectedOrg = objectOrg[0];
    },
    showOrgChange() {
      this.isOrgChangeVisible = true;
    },
    close() {
      this.selectedOrg = '';
      this.isOrgChangeVisible = false;
    },
    closeAndChange() {
      this.isOrgChangeVisible = false;
      this.$store.dispatch(UPDATE_USER_ORG, this.selectedOrg.UF_COMPANY_ID);
    },
  },
  watch: {
    AVATAR() {
      this.userMenu.AVATAR = this.$store.getters.getAvatar;
    }
  }
}
</script>

<style scoped>
.menu-dropdown__btn {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: inline;
  color: white;
  padding: 0;
  text-decoration: underline;
}

.menu-dropdown__btn:hover {
  cursor: pointer;
}

.user-panel__menu-link:hover {
  font-weight: bold;
  cursor: pointer;
}
</style>
