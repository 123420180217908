<template>
  <div class="content-tab__panel tab_active">
    <div class="table-responsive production-area">
      <table class="production-table_child">
        <thead>
        <tr>
          <th rowspan="2" class="mob-cell-w_200">Номер заключения</th>
          <th rowspan="2" class="mob-cell-w_200">Код ТН ВЭД ЕАЭС</th>
          <th rowspan="2" class="mob-cell-w_200">Наименование объекта экспертизы</th>
          <th rowspan="2" class="mob-cell-w_200">Краткое техническое описание (не более 256 символов</th>
          <th colspan="3" class="mob-cell-w_400 text-center after_dashed">Выводы</th>
        </tr>
        <tr>
          <th style="max-width: 100px" class="mob-cell-w_200">О соответствии объекта контроля товару (технологии), подпадающему под действие экспортного контроля</th>
          <th style="max-width: 100px" class="mob-cell-w_200">Номера позиций контрольных списков, под действие которых подпадает объект экспертизы</th>
          <th style="max-width: 100px" class="mob-cell-w_200">Обозначение контрольного списк</th>
        </tr>
        </thead>
        <tbody>
        <tr class="" :style="{background: item.ROW_BACK_COLOR.HEX}" :key="item.ID" v-for="item in reportItem.items">
          <td>{{item.UF_NUMBER}}</td>
          <td>{{item.UF_TN_VED_CODE}}</td>
          <td>
            <textarea-autosize class="production-cell" style="width: 100%" :class="{red_border: item.UF_IS_PRUN_OBJECT}" v-model="item.UF_EXPERTISE_OBJECT"></textarea-autosize>
          </td>
          <td>
            <textarea-autosize class="production-cell" style="width: 100%" :class="{red_border: item.UF_IS_PRUN_DESCRIPTION}" v-model="item.UF_DESCRIPTION"></textarea-autosize>
          </td>
          <td><span>Не соответствует</span></td>
          <td>{{item.UF_NUMBER_PST_TEXT}}</td>
          <td>{{item.UF_CODE_CNT_LIST}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <br/>
    <div class="btn-box" v-if="!reportItem.UF_IS_VERIFIED">
      <a class="link_style-blue" @click="saveReport">сохранить</a>
      <a class="link_style-blue" @click="sendReport">утвердить</a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ReportService} from "@/common/api.service";

export default {
  name: "ReportTable",
  components: {},
  async mounted() {

  },
  computed: {
    ...mapGetters([
      "reportItem",
      "isReportsLoading",
      "currentUser",
      "directory"
    ]),
  },
  methods: {
    saveReport() {
      ReportService.saveReport(this.reportItem).then(({data}) => {
        console.log(data)
      })
    },
    sendReport() {
      ReportService.sendReport(this.reportItem).then(({data}) => {
        console.log(data)
      })
    }
  }
}
</script>

<style scoped>

</style>