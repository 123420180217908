<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <a name="htop"></a>
      <MainMenu/>
      <div class="content-indent-wrapper">
        <div class="content-indent">
          <div class="breadcrumbs">
            <router-link :to="{name: 'LK/request'}">Заявки</router-link>
            > <span v-if="!this.$route.params.copy && requestItem.UF_NUMBER">заявка Рег. № {{ requestItem.UF_NUMBER }} от
                    {{ requestItem.UF_DATE_CREATE | dateFormat }}</span>
            <span v-else-if="this.$route.params.copy">копирование заявки Рег. № {{ requestItem.UF_NUMBER }} от
                      {{ requestItem.UF_DATE_CREATE | dateFormat }}</span>
            <span v-else>Создание заявки</span>
          </div>
          <div class="page-top-title">
            <div class="page-top-title__item">
              <h2 class="content-title" v-if="!this.$route.params.copy && requestItem.UF_NUMBER">заявка Рег. №
                {{ requestItem.UF_NUMBER }} от
                {{ requestItem.UF_DATE_CREATE | dateFormat }}, cтатус заявки -
                {{ getStatusName(requestItem.UF_STATUS_ID) }}</h2>
              <h2 class="content-title" v-else-if="this.$route.params.copy">копирование заявки Рег. №
                {{ requestItem.UF_NUMBER }} от
                {{ requestItem.UF_DATE_CREATE | dateFormat }}</h2>
              <h2 class="content-title" v-else>Создание заявки</h2>
            </div>
            <SendRequest/>
          </div>
          <!-- page-top-title -->
          <div class="content-tab-area" v-if="!isRequestsLoading || isLoaded">
            <ul class="content-tab-menu">
              <li class="tab-menu__item" :class="{tab_active: step === 1}" @click="setStep(1)">
                Содержание
              </li>
              <li class="tab-menu__item" :class="{tab_active: step === 2}" @click="setStep(2)">Товар /
                Продукция
              </li>
              <li class="tab-menu__item" v-if="requestItem.UF_IS_VIEW_TAB_COMMENTS" :class="{tab_active: step === 3}"
                  @click="setStep(3)">
                Замечания к заявке
                <span v-if="numStatuses>0" class="tab-history-num-note">{{ numStatuses }}</span>
              </li>
              <a  v-if="currentUser.ACTIVE_ROLE_CODE !== 'expert_org_manager'" class="tab-menu__item" target="_blank"
                 href="https://fstec.ru/eksportnyj-kontrol/obshchaya-informatsiya">
                Обращение во ФСТЭК России
              </a>
            </ul>
            <!-- content-tab-menu -->
            <!-- START content-tab__panel Содержание -->
            <div class="content-tab__panel" v-if="currentUser.ACTIVE_ROLE_CODE !== 'expert_org_manager'" :class="{tab_active: step === 1}">
              <div class="container-fluid">
                <CardContent v-if="step === 1"/>
              </div>
              <!-- container-fluid -->
            </div>
            <div class="content-tab__panel" v-if="currentUser.ACTIVE_ROLE_CODE === 'expert_org_manager'" :class="{tab_active: step === 1}">
              <div class="container-fluid">
                <CardExpertContent v-if="step === 1"/>
              </div>
              <!-- container-fluid -->
            </div>
            <!-- END content-tab__panel Содержание -->

            <!-- START content-tab__panel  Товар / Продукция -->
            <div class="content-tab__panel" :class="{tab_active: step === 2}">
              <div class="container-fluid">
                <CardProduct v-if="step === 2"/>
              </div>
            </div>
            <!-- END content-tab__panel Товар / Продукция-->

            <div class="content-tab__panel" :class="{tab_active: step === 3}">
              <div class="container-fluid">
                <CardComment v-if="step === 3"/>
              </div>
            </div>

            <div class="content-tab__panel tab_active">
              <div class="container-fluid">
                <div class="content-tab-wrapper">
                  <!-- content-tab-wrapper -->
                  <div class="btn-box btn-box-save" v-scroll="handleScroll"
                       v-if="requestItem.UF_IS_EDIT || $route.params.copy">
                    <div class="link_style-blue" @click="editRequest">сохранить</div>
                  </div>
                </div>
              </div>
            </div>
            <a href="#" class="link_up"></a>
            <!--ссылка на верх-->
          </div>
          <!-- content-tab-area -->
        </div>
        <!-- content-indent -->
      </div>
      <!-- content-indent-wrapper -->
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";
import CardContent from "@/components/cabinet/request/CardContent";
import CardExpertContent from "@/components/cabinet/request/CardExpertContent";
import {mapGetters} from "vuex";
import CardProduct from "@/components/cabinet/request/CardProduct";
import moment from "moment";
import {RequestService} from "@/common/api.service";
import MainMenu from "../../components/cabinet/MainMenu";
import SendRequest from "@/components/cabinet/request/SendRequest";
import CardComment from "@/components/cabinet/request/CardComment";

export default {
  name: "RequestEdit",
  components: {CardComment, SendRequest, CardExpertContent, MainMenu, CardProduct, CardContent, Footer, Header},
  directives: {
    scroll: {
      // определение директивы
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },
  data() {
    return {
      step: 1,
      toasterErrors: [],
      isLoaded: false,
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "status_request"
    ]);
    await this.fetchItem()
  },
  computed: {
    numStatuses() {
      let r = 0
      if (this.requestItem.HISTORY_COMMENTS) {
        for (let i = 0; i < this.requestItem.HISTORY_COMMENTS.length; i++) {
          if (!this.requestItem.HISTORY_COMMENTS[i].UF_STATUS_ID) {
            r++
          }
        }
      }
      return r
    },
    ...mapGetters([
      "requestItem",
      "directory",
      "isRequestsLoading",
      "currentUser"
    ]),
  },
  methods: {
    async fetchItem() {
      this.$store.dispatch('fetchRequestItem', this.$route.params.id).then(() => {
        document.title = unescape(this.requestItem.META_TITLE);
        this.isLoaded = true
      }, this)
    },
    setStep(step) {
      this.step = step
    },
    getStatusName: function (id) {
      let t = this.directory.status_request.filter(c => c.ID === id);
      if (t.length > 0) {
        return t[0].UF_NAME;
      }
    },
    editRequest() {
      if (this.$route.params.copy || !this.requestItem.ID) {
        RequestService.createRequest(this.requestItem).then(({data}) => {
          this.$route.params.id = data.ID
          this.fetchItem()
          this.$router.push({name: "LK/request/edit", params: {id: data.ID}})
          if (data.errorAr.length > 0) {
            data.errorAr.forEach(function (err) {
              this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                  err.TEXT,
                  {
                    theme: "bubble",
                    position: "bottom-right",
                    duration: null,
                    action: [
                      {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                          toastObject.goAway(0);
                        }
                      },
                      {
                        text: "Закрыть все",
                        onClick: () => {
                          this.toasterErrors.forEach(function (err) {
                            err.goAway(0);
                          });
                        }
                      }
                    ]
                  }
              );
            }, this);
          } else {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                'Заявка создана, номер ' + data.UF_NUMBER,
                {
                  theme: "green",
                  position: "bottom-right",
                  duration: 5000,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                }
            );
          }

          location.hash = "#htop";
        }, this)
      } else {
        this.toasterErrors.forEach(function (err) {
          err.goAway(0);
        });
        RequestService.editRequest(this.requestItem.ID, this.requestItem).then(({data}) => {
          this.fetchItem()
          if (data.errorAr.length > 0) {
            data.errorAr.forEach(function (err) {
              this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                  err.TEXT,
                  {
                    theme: "bubble",
                    position: "bottom-right",
                    duration: null,
                    action: [
                      {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                          toastObject.goAway(0);
                        }
                      },
                      {
                        text: "Закрыть все",
                        onClick: () => {
                          this.toasterErrors.forEach(function (err) {
                            err.goAway(0);
                          });
                        }
                      }
                    ]
                  }
              );
            }, this);
          } else {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                'Проект сохранен',
                {
                  theme: "green",
                  position: "bottom-right",
                  duration: 3000,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                }
            );
          }
        }, this)
      }
    },
    handleScroll: function (evt, el) {
      let body = document.body,
          html = document.documentElement;

      let scrollStopFix = Math.max(body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight) - 10;

      let scrollBottom = window.scrollY + window.screen.height;
      if (window.scrollY > 50 && (scrollBottom < scrollStopFix)) {
        if (el.classList.contains('btn-box-save')) {
          el.classList.add('btn-box-save_fix')
        }
      } else if (scrollBottom >= scrollStopFix) {
        if (el.classList.contains('btn-box-save')) {
          el.classList.remove('btn-box-save_fix')
        }
      } else if (window.scrollY < 10) {
        if (el.classList.contains('btn-box-save')) {
          el.classList.remove('btn-box-save_fix')
        }
      }
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    }
    ,
  },
  beforeRouteLeave(to, from, next) {
    this.toasterErrors.forEach(function (err) {
      err.goAway(0);
    });
    next();
  }
}
</script>

<style scoped>

</style>
