import {ContractService} from "@/common/api.service";

const state = {
    contracts: {
        counts: 0,
        items: [],
        sortColumn: "",
        sortOrder: "",
        showFilterModal: false,
        showGroupModal: false,
        filters: [],
        groups: [],
        applyFilters:[],
        applyGroups: [],
        itemsPerPage: 20,
        currentPage: 1,
        checkedItems: [],
        checkAllBox: false,
    },
    contractItem: {},
    isContractsLoading: true,
    isContractLoadingError: false
};

const getters = {
    contracts(state) {
        return state.contracts;
    },
    contractItem(state) {
        return state.contractItem;
    },
    isContractsLoading(state) {
        return state.isContractsLoading;
    },
    isContractsLoadingError(state) {
        return state.isContractLoadingError;
    }
};

const actions = {
    fetchContracts({commit}, params) {
        commit("fetchContractStart");
        return ContractService.list(params.type, params)
            .then(({data}) => {
                commit('fetchContractEnd', data);
            })
            .catch(error => {
                commit('fetchContractError');
                throw new Error(error);
            });
    },
    fetchContractItem({commit}, slug) {
        commit('fetchContractStart');
        if (slug) {
            return ContractService.item(slug)
                .then(({data}) => {
                    commit('fetchContractItemEnd', data);
                })
                .catch(error => {
                    commit('fetchContractError');
                    throw new Error(error);
                });
        } else {
            return ContractService.loadTemplate()
                .then(({data}) => {
                    commit('fetchContractItemEnd', data);
                })
                .catch(error => {
                    commit('fetchContractError');
                    throw new Error(error);
                });
        }
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    ['fetchContractStart'](state) {
        state.isContractsLoading = true;
    },
    ['fetchContractEnd'](state, items) {
        state.contracts.META_TITLE = items.META_TITLE;
        state.contracts.counts = items.counts;
        state.contracts.group = items.group;
        state.contracts.items = items.items;
        state.contracts.settings = items.settings;
        state.isContractsLoading = false;
    },
    ['fetchContractError'](state) {
        state.isContractsLoading = false;
        state.isContractsLoadingError = true;
    },
    ['fetchContractItemEnd'](state, item) {
        state.isContractsLoading = false;
        state.contractItem = item;
        return item;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
