<template>
    <div class="content-tab__panel tab_active">
        <div class="container-fluid">
            <div class="content-tab-wrapper" v-if="!isNewsLoading">
                <div class="news-item main-clear" v-for="item in news.items" v-bind:key="item.ID">
                    <img :src="item.PREVIEW_PICTURE" alt="Новость" class="news-item__picture">
                    <div class="news-item__content">
                        <span class="news-item__data">{{item.DATE_CREATE}}</span>
                        <h3 class="news-item__title">{{item.NAME}}</h3>
                        <div class="news-item__anons">
                            <div class="news-item__anons">{{item.PREVIEW_TEXT}}</div>
                            <router-link :to="{name: 'ViewNews', params: { id: item.ID }}" class="news-item__link">Читать полностью >></router-link>
                        </div>
                        <!-- news-item__anons -->
                    </div>
                    <!-- news-item__content -->
                </div>
                <div class="main-pagination">
                    <Pagination
                            :pages="pages"
                            :currentPage.sync="currentPage"
                    />
                    <span class="pagination_all">Всего: {{news.counts}}</span>
                    <span>Выводить по: </span>
                    <select class="pagination__select" v-model="itemsPerPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>
            <div v-else>
                Загрузка
            </div>
        </div>
    </div>
    <!-- content-tab__panel -->
</template>

<script>
    import {FETCH_NEWS} from "@/store/actions.type";
    import {mapGetters} from "vuex";
    import Pagination from "@/components/site/Pagination";

    export default {
        name: "NewsList",
        components: {Pagination},
        data() {
            return {
                itemsPerPage: 20,
                currentPage: 1
            };
        },
        mounted() {
            this.fetchNews()
        },
        computed: {
            ...mapGetters([
                "news",
                "isNewsLoading"
            ]),
            listConfig() {
                const q = {
                    page: this.currentPage,
                    limit: this.itemsPerPage,
                };
                return q;
            },
            pages() {
                if (this.isLoading || this.news.counts <= this.itemsPerPage) {
                    return [];
                }
                return [
                    ...Array(Math.ceil(this.news.counts / this.itemsPerPage)).keys()
                ].map(e => e + 1);
            }
        },
        watch: {
            currentPage(newValue) {
                this.listConfig.page = newValue;
                this.fetchNews();
            },
            itemsPerPage(newValue) {
                this.listConfig.limit = newValue;
                this.fetchNews();
            }
        },
        methods: {
            fetchNews() {
                this.$store.dispatch(FETCH_NEWS, this.listConfig).then(() => {
                    document.title = unescape(this.news.META_TITLE);
                }, this)
            },
        }
    }
</script>

<style scoped>

</style>