<template>
  <div class="auth-area__form">

    <h3 class="form-title">Регистрация в системе</h3>

    <form action="#" class="form-auth-login" @submit.prevent="register">
      <span class="reg-danger reg-danger_auth" v-if="serverErrors" style="padding-bottom: 10px">{{ showServerError("recaptchaToken") }}</span>
      <div class="auth-field">
        <p><label for="reg-email" class="auth_label">E-mail</label></p>
        <input type="text"
               autocomplete="off"
               name="reg-email"
               class="auth_input"
               id="email_mask"
               v-model.trim="regData.USER_EMAIL"
               @keydown.space.prevent
               :class="{invalid: ($v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.required) || ($v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.email)}"/>
        <span class="reg-danger reg-danger_auth" v-if="$v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.required">введите email</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.email">email введен неверно</span>
        <span class="reg-danger reg-danger_auth"
              v-if="errorMessage('USER_EMAIL')">{{ showServerError("USER_EMAIL") }}</span>
      </div>
      <div class="auth-field">
        <p><label for="reg-pass" class="auth_label">Пароль</label></p>
        <input type="password"
               name="reg-pass"
               autocomplete="new-password"
               class="auth_input" value=""
               v-model.trim="regData.USER_PASSWORD"
               @keydown.space.prevent
               :class="{invalid: ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.required) || ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.minLength) || ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.valid)}"/>
        <span class="reg-danger reg-danger_auth"
              v-if="$v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.required">введите пароль</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.minLength">длина пароля минимум 8 символов</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.valid">пароль должен содержать латинские буквы, как минимум одну заглавную, а также одну цифру</span>
        <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError("USER_PASSWORD") }}</span>
      </div>
      <div class="auth-field">
        <p><label for="reg-pass-repeat" class="auth_label">Повторите пароль</label></p>
        <input type="password"
               name="reg-pass-repeat"
               class="auth_input" value=""
               v-model.trim="regData.USER_CONFIRM_PASSWORD"
               @keydown.space.prevent
               :class="{invalid: ($v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.required) || ($v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.sameAsPassword)}"/>
        <span class="reg-danger reg-danger_auth"
              v-if="$v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.required">подтвердите пароль</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.sameAsPassword">пароли не совпадают</span>
        <span class="reg-danger reg-danger_auth"
              v-if="serverErrors">{{ showServerError("USER_CONFIRM_PASSWORD") }}</span>
      </div>
      <div class="auth-field">
        <p><label for="fio" class="auth_label">ФИО</label></p>
        <input type="text"
               name="fio"
               class="auth_input"
               v-model.trim="regData.USER_FIO"
               :class="{invalid: ($v.regData.USER_FIO.$dirty && !$v.regData.USER_FIO.required)}"/>
        <span class="reg-danger reg-danger_auth" v-if="$v.regData.USER_FIO.$dirty && !$v.regData.USER_FIO.required">введите ФИО</span>
        <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError("USER_FIO") }}</span>
      </div>
      <div class="auth-field">
        <p><label for="mob-tell" class="auth_label">Мобильный телефон</label></p>
        <input type="tel"
               name="mob-tell"
               id="phone_number_mask"
               class="auth_input"
               v-model="regData.USER_PHONE"
               :class="{invalid: ($v.regData.USER_PHONE.$dirty && !$v.regData.USER_PHONE.required) || ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.validPhone)}"/>
        <span class="reg-danger reg-danger_auth" v-if="$v.regData.USER_PHONE.$dirty && !$v.regData.USER_PHONE.required">введите номер телефона</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.USER_PHONE.$dirty && !$v.regData.USER_PHONE.validPhone">Неверно введен номер телефона</span>
        <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError("USER_PHONE") }}</span>
      </div>
      <div class="auth-field">
        <p><label for="snails" class="auth_label">СНИЛС</label></p>
        <input type="text"
               name="snails"
               id="snails_mask"
               class="auth_input"
               v-model="regData.USER_SNILS"
               :class="{invalid: ($v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.required) || ($v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.validSnils) }"/>
        <span class="reg-danger reg-danger_auth" v-if="$v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.required">введите СНИЛС</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.validSnils">Неверно введен СНИЛС</span>
        <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError("USER_SNILS") }}</span>
      </div>
      <div class="auth-field auth-field__org">
        <p><label for="ogrn-org" class="auth_label">ОГРН организации</label></p>
        <input type="text"
               name="ogrn-ogrn-org"
               id="ogrn-org_mask"
               class="auth_input"
               @blur="toggleOgrnInfo"
               v-model="regData.ORG_OGRN"
               :class="{invalid: ($v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.required) || ($v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.validOgrn)}"/>
        <span class="reg-danger reg-danger_auth" v-if="$v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.required">введите номер ОГРН</span>
        <span class="reg-danger reg-danger_auth"
              v-else-if="$v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.validOgrn">Неверно введен ОГРН</span>
        <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError("ORG_OGRN") }}</span>
        <span class="reg-danger reg-danger_auth" v-if="ogrnError">{{ogrnError}}</span>
      </div>
      <span class="form-org-name" v-show="ogrn.isFind">{{ogrn.name}}</span>
      <div class="auth-field auth-field_check check-flex">
        <input type="checkbox"
               class="main_check"
               :disabled="brokerCheckbox.disabled"
               v-model="checkBox.broker"
        />
        <span class="auth_label label_check broker-check">Брокер</span>
      </div>
      <span class="soglasie-text">Согласие на обработку персональных данных:</span>
      <div class="auth-field auth-field_check check-flex">
        <input type="checkbox"
               class="main_check"
               v-model="checkBox.USER_IS_AGREE_RULES"
               :class="{invalid: ($v.checkBox.USER_IS_AGREE_RULES.$dirty && !$v.checkBox.USER_IS_AGREE_RULES.sameAs)}"/>
        <span
            class="auth_label label_check">Согласен(-на) <button
            @click.prevent="showConsent"
            class="button-consent"
        >Согласие на обработку</button></span>
        <div v-if="loading">
          <div class="spinner">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div v-else>
          <consent
              v-show="isConsentVisible"
              @close="closeConsent"
              @closeAndCheck="closeAndCheckConsent"
              :consentText="consent.text"
              :consentName="consent.name"
          />
        </div>
      </div>
      <span class="reg-danger" v-if="$v.checkBox.USER_IS_AGREE_RULES.$dirty && !$v.checkBox.USER_IS_AGREE_RULES.sameAs">подтвердите согласие</span>
      <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError("USER_IS_AGREE_RULES") }}</span>
      <span class="reg-danger reg-danger_block">Внимание! Все поля обязательны для заполнения.</span>
      <div v-if="isUseCaptcha" class="google-captha">
        <vue-recaptcha
            ref="recaptcha"
            size="checkbox"
            class="g-recaptcha"
            :sitekey="siteKey"
            @verify="validate"
            @expired="onCaptchaExpired"
        />
      </div>
      <input type="submit" class="auth_btn" @keyup.enter="register" value="зарегистрироваться"/>
    </form>
  </div>
</template>

<script>
  import VueRecaptcha from "vue-recaptcha"
  import Inputmask from 'inputmask';
  import {email, required, minLength, sameAs,} from "vuelidate/lib/validators"
  import {mapGetters} from "vuex";
  import ApiService from "@/common/api.service";
  import {UserService} from "@/common/api.service";
  import {OAUTH} from "@/store/actions.type";
  import {SITE_KEY} from "@/common/config";
  import Consent from "../popup/Consent";


  export default {
    name: 'Registration',
    components: {
      VueRecaptcha,
      Consent,
    },
    data() {
      return {
        regData: {
          USER_EMAIL: '',
          USER_PASSWORD: '',
          USER_CONFIRM_PASSWORD: '',
          USER_FIO: '',
          USER_PHONE: '',
          USER_SNILS: '',
          ORG_OGRN: '',
          USER_IS_BROKER: "0",
          USER_IS_AGREE_RULES: "0",
          recaptchaToken: null,
        },
        siteKey: SITE_KEY,
        isUseCaptcha: false,
        ogrn: {
          name: 'piece',
          isFind: 0,
          isBroker: 0,
          isFindDB: 0,
        },
        brokerCheckbox: {
          disabled: false,
        },
        consent: {
          name: '',
          text: '',
        },
        checkBox: {
          broker: false,
          USER_IS_AGREE_RULES: false,
        },
        isConsentVisible: false,
        loading: false,
        recaptchaChecked: false,
        ogrnError: '',
        serverErrors: [],
        otherErrors: '',
        tosterErrors: [],
      }
    },

    beforeCreate: function () {
      document.body.className = 'gradient';
      document.documentElement.className = 'gradient';
    },

    mounted() {
      ApiService.get3("services/getStatusUseReCaptcha/")
        .then(response => (this.isUseCaptcha = response.data.IS_USE_RECAPTCHA))
        .catch(error => this.otherErrors = error)
      let emailMask = new Inputmask("email");
      emailMask.mask(document.getElementById('email_mask'));
      let phoneMask = new Inputmask("+7(999) 999-99-99");
      phoneMask.mask(document.getElementById('phone_number_mask'));
      let snailsMask = new Inputmask("999-999-999 99");
      snailsMask.mask(document.getElementById('snails_mask'));
      let ogrnMask = new Inputmask("9999999999999[99]");
      ogrnMask.mask(document.getElementById('ogrn-org_mask'));
    },

    destroyed() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.documentElement;
      body.classList.remove('gradient');
      html.classList.remove('gradient');
    },

    validations: {
      regData: {
        USER_EMAIL: {email, required},
        USER_PASSWORD: {
          required,
          minLength: minLength(8),
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /[0-9]/.test(value);
            return containsUppercase && containsLowercase && containsNumber;
          }
        },
        USER_CONFIRM_PASSWORD: {required, sameAsPassword: sameAs('USER_PASSWORD')},
        USER_FIO: {required},
        USER_PHONE: {
          required,
          validPhone: function (value) {
            const containsPhone = /(\+7)\(\d{3}\)\s*\d{3}-\d{2}-\d{2}/.test(value);
            return containsPhone;
          }
        },
        USER_SNILS: {
          required,
          validSnils: function (value) {
            const containsSnils = /\d{3}-\d{3}-\d{3}\s\d{2}/.test(value);
            return containsSnils;
          }
        },
        ORG_OGRN: {
          required,
          validOgrn: function (value) {
            const containsOgrn = /\d{13,15}/.test(value);
            return containsOgrn;
          }
        },
      },
      checkBox: {
        USER_IS_AGREE_RULES: {sameAs: sameAs(() => true)}
      }
    },

    computed: {
      ...mapGetters(["isAuthenticated", "currentUser"]),
    },

    methods: {

      errorMessage(inputName) {
        // todo проверить метод после исправления CORS
        if(this.serverErrors) {
          return this.serverErrors.filter(el => el.FIELD === inputName).length === 0;
        }
      },

      register() {
        if ((this.isUseCaptcha && !this.recaptchaChecked) || this.$v.$invalid) {
          this.$v.$touch();
          return;
        }
        this.serverErrors = '';
        this.regData.USER_IS_AGREE_RULES = '1';
        this.checkBox.broker === true ? this.regData.USER_IS_BROKER = '1' : this.regData.USER_IS_BROKER = '0';
        const body = {...this.regData};
        ApiService.post('registration/', body)
          .then(
            ({data}) => {
              if (data.error === true) {
                this.serverErrors = data.errorArr;
                if (data.errorArr.length > 0) {
                  data.errorArr.forEach(function (err) {
                    this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                        err.TEXT,
                        {
                          theme: "bubble",
                          position: "bottom-right",
                          duration: null,
                          action: [
                            {
                              text: "Закрыть",
                              onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                              }
                            },
                            {
                              text: "Закрыть все",
                              onClick: () => {
                                this.tosterErrors.forEach(function (err) {
                                  err.goAway(0);
                                });
                              }
                            }
                          ]
                        }
                    );
                  }, this);
                }
                return;
              }
              if (data.isAuth === 0) {
                this.$router.push({name: 'Login'});
              }
              this.$store.dispatch(OAUTH, data.access_token).then(() => {
                UserService.getUserDefaultSetting().then(({data}) => {
                  this.$router.push({name: `LK/${data.START_PAGE_URL}`});
                });
              });

            }
          )
          .catch(error => {
            this.otherErrors = error;
          });
      },

      validate(recaptchaToken) {
        this.regData.recaptchaToken = recaptchaToken;
        this.recaptchaChecked = true;
      },

      onCaptchaExpired() {
        this.$refs.recaptcha.reset();
        this.recaptchaChecked = false;
      },

      toggleOgrnInfo() {
        ApiService.get3(`orgs/getOrgByOGRN/${this.regData.ORG_OGRN}/`)
          .then(response => {
            if (response.data.ERROR) {
              this.ogrnError = response.data.ERROR;
              return;
            }
            this.ogrn.isFind = response.data.IS_FIND;
            this.ogrn.name = response.data.ORG_INFO.UF_NAME;
            this.ogrn.isBroker = response.data.UF_IS_BROKER;
            this.ogrn.isFindDB = response.data.ORG_INFO.IS_FIND_DB;
            if (this.ogrn.isBroker) {
              this.checkBox.broker = true;
              this.brokerCheckbox.disabled = true;
            } else if (!this.ogrn.isFindDB) {
              this.checkBox.broker = false;
              //this.brokerCheckbox.disabled = true;
              this.brokerCheckbox.disabled = false;
            } else {
              this.checkBox.broker = false;
              this.brokerCheckbox.disabled = false;
            }
          })
      },

      showConsent() {
        this.loading = true;
        ApiService.get3('info/getServicesPage/soglasie_personal/')
          .then(response => {
            this.consent.name = response.data.NAME;
            this.consent.text = response.data.PREVIEW_TEXT;
          })
        this.loading = false;
        this.isConsentVisible = true;
      },

      closeConsent() {
        this.isConsentVisible = false;
      },

      closeAndCheckConsent() {
        this.checkBox.USER_IS_AGREE_RULES = true;
        this.isConsentVisible = false;
      },

      showServerError(inputName) {
        let arrayError = this.serverErrors
          .filter((item) => {
            return (item["FIELD"] === inputName)
          })
          .map((item) => {
            return item["TEXT"];
          });
        return arrayError[0];
      }
    }
  }
</script>

<style scoped>

</style>
