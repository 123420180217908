<template>
    <div v-if="faqItem.NAME">
        <div class="breadcrumbs">
            <router-link :to="{name: 'Faq'}">Часто задаваемые вопросы</router-link> > <span v-text="faqItem.NAME"/>
        </div>
        <!-- breadcrumbs -->
        <div class="question-item-inside">
            <div class="question-item-inside__title" v-text="faqItem.NAME"/>
            <div v-html="faqItem.DETAIL_TEXT"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "FaqItem",
        mounted() {
            this.fetchFaq()
        },
        computed: {
            ...mapGetters([
                "faqItem",
                "isFaqsLoading"
            ]),
        },
        methods: {
            fetchFaq() {
                this.$store.dispatch('fetchFaqItem', this.$route.params.id).then(() => {
                    document.title = unescape(this.newsItem.META_TITLE);
                }, this)
            },
        }
    }
</script>

<style scoped>

</style>