<template>
    <div>
    <footer class="footer-area">
        <div class="footer">
            <FooterText/>
        </div>
    </footer>
        <Support/>
    </div>
</template>

<script>
    import FooterText from "@/views/partial/FooterText";
    import Support from "@/views/partial/Support";

    export default {
        name: "Footer",
        components: {Support, FooterText}
    }
</script>

<style scoped>

</style>