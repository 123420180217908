import Vue from "vue";
import axios from "axios";
import qs from "qs";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import {API_PATH, API_URL} from "@/common/config";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL + API_PATH;
    },
    setHeader() {
        if (JwtService.getToken() !== "" && JwtService.getToken() !== null) {
            Vue.axios.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${JwtService.getToken()}`;
        }
    },

    query(resource, params) {
        this.setHeader();

        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    async get(resource, slug = "") {
        this.setHeader();

        return await Vue.axios.get(`${resource}${slug}/`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    get2(resource, slug = "") {
        this.setHeader();

        return Vue.axios.get(`${resource}${slug}`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    get3(resource) {
        this.setHeader();

        return Vue.axios.get(`${resource}`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    post(resource, params) {
        this.setHeader();

        return Vue.axios.post(`${resource}`, params);
    },

    postForm(resource, form) {
        this.setHeader();

        return Vue.axios.post(`${resource}`, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    update(resource, slug, params) {
        this.setHeader();

        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        this.setHeader();

        return Vue.axios.put(`${resource}`, params);
    },

    delete(resource, params) {
        this.setHeader();

        return Vue.axios.delete(resource, params).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    }
};

export default ApiService;

export const NewsService = {
    list(type, params) {
        return ApiService.query("/info/getListNews/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/info/getNew/${id}`);
    },
    mp() {
        return ApiService.get(`/info/getMainPage`);
    },
    spSupport() {
        return ApiService.get(`/info/getServicesPage/support`);
    },
    spRequestSupport() {
        return ApiService.get(`/info/getServicesPage/request_support`);
    },
    servicePage(page) {
        return ApiService.get(`/info/getServicesPage/${page}`);
    }
};

export const MethodsService = {
    list(type, params) {
        return ApiService.query("/info/getListMethodics/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    archive() {
        return ApiService.get("/services/getLinkArhiveMethodics");
    }
};

export const TariffsService = {
    list(type, params) {
        return ApiService.query("/info/getListTariffs/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    archive() {
        return ApiService.get("/services/getLinkArhiveTariffs");
    }
};

export const FaqService = {
    list(type, params) {
        return ApiService.query("/info/getListFAQ/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/info/getFAQ/${id}`);
    }
};

export const ReportService = {
    list(type, params) {
        return ApiService.query("/report/getReportList/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/report/getReport/${id}`);
    },
    checkRulesNewReport(params) {
        return ApiService.post(`/report/checkRulesNewReport/`, params);
    },
    createReport(params) {
        return ApiService.post(`/report/createReport/`, params);
    },
    saveReport(params) {
        return ApiService.post(`/report/saveReport/${params.ID}/`, params);
    },
    sendReport(params) {
        return ApiService.post(`/report/sendReport/${params.ID}/`, params);
    },
    editFactSendTime(params) {
        return ApiService.post(`/report/editFactSendTime/${params.ID}/`, params);
    },
    downloadReport(date) {
        return ApiService.get2(`/services/getLinkReportXLS/?UF_DATE_OTCHET=${date}`);
    },
    getLinkReportXML() {
        return ApiService.get2(`/services/getLinkReportXML/`);
    }
};

export const RequestService = {
    list(type, params) {
        return ApiService.query("/request/getListRequest/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    downloadReport(type, params) {
        return ApiService.query("/services/getLinkRequestXLS/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    deleteRequest(params) {
        return ApiService.post(`/request/deleteRequest/`, params);
    },
    editRequest(id, params) {
        return ApiService.post(`/request/editRequest/${id}/`, params);
    },
    addDocument(id, form) {
        return ApiService.postForm(`/request/documents/addDocument/${id}/`, form);
    },
    changeStatusDocument(id, docId, params) {
        return ApiService.post(`/request/documents/changeStatusDocument/${id}/${docId}/`, params);
    },
    editDocument(id, docId, form) {
        return ApiService.postForm(`/request/documents/editDocument/${id}/${docId}/`, form);
    },
    loadTemplate() {
        return ApiService.get(`/request/getShablonFieldsRequest`);
    },
    createRequest(params) {
        return ApiService.post(`/request/createRequest/`, params);
    },
    getListCopyRequest() {
        return ApiService.get(`/request/getListCopyRequest`);
    },
    item(id) {
        return ApiService.get(`/request/getRequest/${id}`);
    },
    getECPInfoFile(id) {
        return ApiService.get2(`/services/getECPInfoFile/${id}/`);
    },
    getECPInfoRequest(id) {
        return ApiService.get2(`/services/getECPInfoRequest/${id}/`);
    },
    setECPRequest(id, params) {
        return ApiService.post(`/request/setECPRequest/${id}/`, params);
    },
    sendRequest(id) {
        return ApiService.post(`/request/sendRequest/${id}/`);
    },
    requestDocs(id) {
        return ApiService.post(`/request/zaprosDoc/${id}/`);
    },
    createConclusions(id) {
        return ApiService.post(`/conclusions/createConclusions/${id}/`);
    },
    saveComment(id, params) {
        return ApiService.post(`/request/comment/saveComment/${id}/`, params);
    },
    changeStatusComments(id, params) {
        return ApiService.post(`/request/comment/changeStatusComments/${id}/`, params);
    },
    getListAccessExportOrgForExpert(id) {
        return ApiService.get(`/orgs/getListAccessExportOrgForExpert/${id}`);
    },
    setOrgProfileInfoByExpertOrg(params) {
        return ApiService.post(`/orgs/setOrgProfileInfoByExpertOrg/`, params);
    },
    addOrgProfileInfoByExpertOrg(params) {
        return ApiService.post(`/orgs/addOrgProfileInfoByExpertOrg/`, params);
    },
    editDogovor(id, params) {
        return ApiService.post(`/dogovor/expertise/editDogovor/${id}/`, params);
    },
    createDogovor(params) {
        return ApiService.post(`/dogovor/expertise/createDogovor/`, params);
    },
    getOrgByOGRN_INN(params) {
        return ApiService.post(`/orgs/getOrgByOGRN_INN/`, params);
    },
    getListOrgEconomicActivityByExpert(id) {
        return ApiService.get(`/orgs/getListOrgEconomicActivityByExpert/${id}`);
    },
    addOrgEconomicActivityConfidenceByExpert(idZakazOrg, idOrg, params) {
        return ApiService.post(`/orgs/addOrgEconomicActivityConfidenceByExpert/${idZakazOrg}/${idOrg}/`, params);
    },
    setOrgEconomicActivityByExpert(idZakazOrg, params) {
        return ApiService.post(`/orgs/setOrgEconomicActivityByExpert/${idZakazOrg}/`, params);
    },
    addOrgEconomicActivityByExpert(idZakazOrg, params) {
        return ApiService.post(`/orgs/addOrgEconomicActivityByExpert/${idZakazOrg}/`, params);
    },

};

export const ConclusionService = {
    list(type, params) {
        return ApiService.query("/conclusions/getListConclusions/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/conclusions/getConclusions/${id}`);
    },
    getLinkConclusionsPDF(id) {
        return ApiService.get(`/services/getLinkConclusionsPDF/${id}`);
    },
    getListPrintPDF() {
        return ApiService.get(`/conclusions/getListPrintPDF`);
    },
    getListOrgSPConclusions() {
        return ApiService.get(`/conclusions/getListOrgSPConclusions`);
    },
    updateField(id, params) {
        return ApiService.post(`/conclusions/updateField/${id}/`, params);
    },
    setNumber(id) {
        return ApiService.post(`/conclusions/setNumber/${id}/`);
    },
    sendConclusions(id) {
        return ApiService.post(`/conclusions/sendConclusions/${id}/`);
    },
    createTableAnalisys(id) {
        return ApiService.post(`/conclusions/createTableAnalisys/${id}/`);
    },
    getTreeControlList() {
        return ApiService.get(`/directory/getTreeControlList`);
    },
    editConclusion(id, params) {
        return ApiService.post(`/conclusions/editConclusions/${id}/`, params);
    },
    startExpertise(params) {
        return ApiService.post(`/conclusions/startExpertise/`, params);
    },
    setECPRequest(id, params) {
        return ApiService.post(`/conclusions/setECPConclusions/${id}/`, params);
    },
    returnWorkExpertise(id, params) {
        return ApiService.post(`/conclusions/returnWorkExpertise/${id}/`, params);
    },
    getECPInfoConclusions(id) {
        return ApiService.get2(`/services/getECPInfoConclusions/${id}/`);
    },
};

export const UserService = {
    getUserDefaultSetting() {
        return ApiService.get(`/users/getUserDefaultSetting`);
    }
};

export const ListService = {
    list(resource) {
        return ApiService.query(`directory/${resource}/`, {});
    }
};

export const KPService = {
    list(type, params) {
        return ApiService.query("/request_kp/getListRequest/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/request_kp/getRequest/${id}`);
    },
    getListCopyKP() {
        return ApiService.get(`/request_kp/getListCopyRequest`);
    },
    loadTemplate() {
        return ApiService.get(`/request_kp/getShablonFieldsRequest`);
    },
    createKP(params) {
        return ApiService.post(`/request_kp/createRequest/`, params);
    },
    editKP(id, params) {
        return ApiService.post(`/request_kp/editRequest/${id}/`, params);
    },
    sendKP(id, params) {
        return ApiService.post(`/request_kp/sendRequest/${id}/`, params);
    },
    deleteKP(params) {
        return ApiService.post(`/request_kp/deleteRequest/`, params);
    },
    itemManager(id) {
        return ApiService.get(`/request_kp/getRequestReplyOrg/${id}`);
    },
    itemManagerOrg(id) {
        return ApiService.get(`/orgs/getOrgInfoById/${id}`);
    },
    sendKPManager(id, params) {
        return ApiService.post(`/request_kp/sendRequestReplyOrg/${id}/`, params);
    },
    editKPManager(id, params) {
        return ApiService.post(`/request_kp/setRequestReplyOrg/${id}/`, params);
    },
    uploadDocument(id, params) {
        return ApiService.post(`/request_kp/saveProjectDogovorRequestReplyOrg/${id}/`, params);
    },
};

export const AccreditationOMService = {
    list(type, params) {
        return ApiService.query("/accreditation/getListAccreditation/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/accreditation/getAccreditationOperatorCompany/${id}`);
    },
    listORGSPAccreditation() {
        return ApiService.get(`/accreditation/getListOrgSPAccreditation`);
    },
};

export const TZService = {
    item(id) {
        return ApiService.get(`/dogovor/expertise/tz/getDataTZ/${id}`);
    },
    createTZ(id) {
        return ApiService.post(`/dogovor/expertise/tz/createTZDogovor/${id}/`);
    },
    editTZ(id, idTZ, params) {
        return ApiService.post(`/dogovor/expertise/tz/editTZDogovor/${id}/${idTZ}/`, params);
    },
    sb() {
        return ApiService.get(`/dogovor/expertise/tz/getShablonFields`);
    },
    setECPRequest(id, params) {
        return ApiService.post(`/dogovor/expertise/tz/setECP/${id}/`, params);
    },
    getLinkTZPDF(id) {
        return ApiService.get(`/services/getLinkTZPDF/${id}`);
    }
}

export const ContractService = {
    list(type, params) {
        return ApiService.query("/dogovor/expertise/getListDogovor/", {
            params: params,
            paramsSerializer: function (params) {
                return qs.stringify(params, {encode: false});
            }
        });
    },
    item(id) {
        return ApiService.get(`/dogovor/expertise/getDogovor/${id}`);
    },
    getListCopyDogovor() {
        return ApiService.get(`/dogovor/expertise/getListCopyDogovor`);
    },
    editDogovor(id, params) {
        return ApiService.post(`/dogovor/expertise/editDogovor/${id}/`, params);
    },
    createDogovor(params) {
        return ApiService.post(`/dogovor/expertise/createDogovor/`, params);
    },
    sendDogovor(params) {
        return ApiService.post(`/dogovor/expertise/sendDogovor/`, params);
    },
    setECP(id, params) {
        return ApiService.post(`/dogovor/expertise/setECP/${id}/`, params);
    },
}