<template>
    <div class="auth-wrapper">
        <div class="auth-header__menu main-clear">
            <router-link  :to="{name:'Home'}" class="prev-page" title="Назад"></router-link>
        </div>
        <div class="auth-content">
            <div class="auth-area">
                <Logo/>
                <AuthForm/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import AuthForm from '@/components/auth/Dialog.vue'
    import Logo from '@/views/auth/Logo.vue'
    import Footer from "@/views/auth/Footer";

    export default {
        name: 'Auth',
        metaInfo () {
            return {
                title: '"ИС-НЕЙВА", вход/регистрация',
            }
        },
        components: {
            Footer,
            Logo,
            AuthForm
        }
    }
</script>
