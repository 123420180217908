import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import UserService from "@/common/user.service";
import {API_URL} from "@/common/config";

import {
  LOGIN,
  LOGIN_ECP,
  LOGOUT,
  REGISTER,
  CHECK_AUTH,
  OAUTH,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  GET_USER_ROLE,
  REFRESH_TOKEN,
  //profile task
  UPDATE_AVATAR,
  //  org task
  UPDATE_USER_ORG
} from "./actions.type";
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_ERROR,
  SET_ROLE,
  SET_FULL_ROLE,
  SET_TOKEN,
  //  recovery task
  SET_RECOVERY_EMAIL,
  SET_RECOVERY_CAPTCHA,
  //  profile task
  SET_AVATAR,
  SET_NAME,
  //  org task
  SET_ORG,
} from "./mutations.type";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  //recovery task
  setRecoveryEmail: '',
  setRecoveryCaptcha: '',
  //  personal profile
  name: "",
  avatar: API_URL,
  isAvatar: false,

};

const getters = {
  currentUser(state) {
    console.log(UserService.getUser());
    if (UserService.getUser() == null) {
      return state.user;
    }
    return UserService.getUser();

  },
  isAuthenticated(state) {
    return JwtService.getToken() !== "undefined" && state.isAuthenticated;
  },
  //recovery task
  setRecoveryEmail(state) {
    return state.setRecoveryEmail;
  },
  setRecoveryCaptcha(state) {
    return state.setRecoveryCaptcha;
  },
  getAvatar(state) {
    return state.avatar;
  },
  getFullName(state) {
    return state.name;
  },
    getIsAvatar(state) {
        return state.isAvatar;
    },
};

const actions = {
    [LOGIN](context, credentials) {
      return new Promise(resolve => {
        ApiService.post("auth/getAccessToken/", credentials)
          .then(({data}) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [REFRESH_TOKEN](context) {
      return new Promise(resolve => {
        ApiService.get("auth/getRefreshToken")
          .then(({data}) => {
            context.commit(SET_TOKEN, data);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [LOGIN_ECP](context, credentials) {
      return new Promise(resolve => {
        ApiService.post("auth/getAccessToken/", credentials)
          .then(({data}) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
    [REGISTER](context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post("users", {user: credentials})
          .then(({data}) => {
            context.commit(SET_AUTH, data.user);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
            reject(response);
          });
      });
    },
    [CHECK_AUTH](context) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("user")
          .then(({data}) => {
            context.commit(SET_AUTH, data.user);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      } else {
        context.commit(PURGE_AUTH);
      }
    },
    [OAUTH](context, credentials) {
      return new Promise(resolve => {
        ApiService.query("auth/getToken/", {
          params: {access_token: credentials}
        })
          .then(({data}) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [UPDATE_USER_ORG](context, org) {
      console.log(org);
      return new Promise(resolve => {
        ApiService.post("users/setActiveSessionOrg/", {idOrgSession: org})
          .then(async ({data}) => {
            context.commit(SET_ROLE, data);
            resolve(data);
            await ApiService.get("auth/getRefreshTokenByChangeSessionOrg/")
              .then(({data}) => {
                context.commit(SET_TOKEN, data);
                resolve(data);
              })
              .catch(({response}) => {
                context.commit(SET_ERROR, response.data.errors);
              });
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [UPDATE_USER_ROLE](context, roleId) {
      return new Promise(resolve => {
        ApiService.post("users/setActiveGroup/", {idGroup: roleId})
          .then(async ({data}) => {
            context.commit(SET_ROLE, data);
            resolve(data);
            await ApiService.get("auth/getRefreshTokenByChangeRole")
              .then(({data}) => {
                context.commit(SET_TOKEN, data);
                resolve(data);
              })
              .catch(({response}) => {
                context.commit(SET_ERROR, response.data.errors);
              });
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [GET_USER_ROLE](context) {
      return new Promise(resolve => {
        ApiService.get("users/getActiveGroup")
          .then(({data}) => {
            context.commit(SET_FULL_ROLE, data);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [UPDATE_AVATAR](context) {
      return new Promise(resolve => {
        ApiService.get3("users/getProfileUser/")
          .then(({data}) => {
              context.commit(SET_AVATAR, data.PERSONAL_PHOTO);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [UPDATE_USER](context, payload) {
      const {email, username, password, image, bio} = payload;
      const user = {
        email,
        username,
        bio,
        image
      };
      if (password) {
        user.password = password;
      }

      return ApiService.put("user", user).then(({data}) => {
        context.commit(SET_AUTH, data.user);
        return data;
      });
    }
    ,
  }
;

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    if (typeof data.jwt === "undefined") {
      return;
    }
    JwtService.saveToken(data.jwt);
    ApiService.setHeader();
    UserService.saveUser(data.user);
    state.isAuthenticated = true;
    state.user = data;
    state.errors = {};
    state.avatar = API_URL + data.user.AVATAR;
    state.name = data.user.NAME;
  },
  [SET_TOKEN](state, data) {
    if (typeof data.jwt === "undefined") {
      return;
    }
    JwtService.saveToken(data.jwt);
    state.errors = {};
  },
  [SET_ORG](state, org) {
    let user = UserService.getUser();
    user.COMPANY_ACTIVE_SESSION_NAME = org;
    UserService.saveUser(user);
  },
  [SET_ROLE](state, role) {
    let user = UserService.getUser();
    user.ACTIVE_ROLE = role;
    UserService.saveUser(user);
  },
  [SET_FULL_ROLE](state, data) {
    let user = UserService.getUser();
    user.ACTIVE_ROLE = data.activeGroupId;
    user.ACTIVE_ROLE_CODE = data.activeGroupCode;
    user.TYPE_USER = data.typeUser;
    UserService.saveUser(user);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    UserService.destroyUser();
  },
//  recovery task
  [SET_RECOVERY_EMAIL](state, payload) {
    return state.setRecoveryEmail = payload;
  },
  [SET_RECOVERY_CAPTCHA](state, payload) {
    return state.setRecoveryCaptcha = payload;
  },
  //profile task
  [SET_AVATAR](state, avatar) {
    let user = UserService.getUser();
    if(avatar.length) {
        state.isAvatar = true
        user.AVATAR = avatar;
        state.avatar = API_URL + avatar;
    } else {
        state.isAvatar = false;
    }
    UserService.saveUser(user);
  },
  [SET_NAME](state, name) {
    let user = UserService.getUser();
    user.NAME = name;
    state.name = name;
    UserService.saveUser(user);
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
