<template>
    <div>
        <span><router-link  :to="{'name': 'ServicePage', params: { url: 'soglasie_system' }}">Соглашение о пользовании информационными системами и ресурсами</router-link></span>
        <span>Информационная система взаимодействия участников ВЭД "ИС-Нейва”</span>
    </div>
</template>

<script>
    export default {
        name: "FooterText"
    }
</script>

<style scoped>

</style>