import {ConclusionService} from "@/common/api.service";

const state = {
    conclusions: {
        counts: 0,
        items: [],
        sortColumn: "",
        sortOrder: "",
        showFilterModal: false,
        showGroupModal: false,
        filters: [],
        groups: [],
        applyFilters:[],
        applyGroups: [],
        itemsPerPage: 20,
        currentPage: 1,
        checkedItems: [],
        checkAllBox: false,
    },
    conclusionItem: {},
    isConclusionsLoading: true,
    isConclusionLoadingError: false
};

const getters = {
    conclusions(state) {
        return state.conclusions;
    },
    conclusionItem(state) {
        return state.conclusionItem;
    },
    isConclusionsLoading(state) {
        return state.isConclusionsLoading;
    },
    isConclusionsLoadingError(state) {
        return state.isConclusionLoadingError;
    }
};

const actions = {
    fetchConclusions({commit}, params) {
        commit("fetchConclusionStart");
        return ConclusionService.list(params.type, params)
            .then(({data}) => {
                commit('fetchConclusionEnd', data);
            })
            .catch(error => {
                commit('fetchConclusionError');
                throw new Error(error);
            });
    },
    fetchConclusionItem({commit}, slug) {
        commit('fetchConclusionStart');
        if (slug) {
            return ConclusionService.item(slug)
                .then(({data}) => {
                    commit('fetchConclusionItemEnd', data);
                })
                .catch(error => {
                    commit('fetchConclusionError');
                    throw new Error(error);
                });
        } else {
            return ConclusionService.loadTemplate()
                .then(({data}) => {
                    commit('fetchConclusionItemEnd', data);
                })
                .catch(error => {
                    commit('fetchConclusionError');
                    throw new Error(error);
                });
        }
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    ['fetchConclusionStart'](state) {
        state.isConclusionsLoading = true;
    },
    ['fetchConclusionEnd'](state, items) {
        state.conclusions.META_TITLE = items.META_TITLE;
        state.conclusions.counts = items.counts;
        state.conclusions.group = items.group;
        state.conclusions.items = items.items;
        state.conclusions.settings = items.settings;
        state.isConclusionsLoading = false;
    },
    ['fetchConclusionError'](state) {
        state.isConclusionsLoading = false;
        state.isConclusionsLoadingError = true;
    },
    ['fetchConclusionItemEnd'](state, item) {
        state.isConclusionsLoading = false;
        state.conclusionItem = item;
        return item;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
