<template>
  <div class="content-indent" v-if="managerIsLoading">
    <div class="breadcrumbs">
      <router-link :to="{name: 'LK/request-kp'}">Реестр запросов КП</router-link> &gt;
      <span>КП по запросу Рег. № {{ kpItemManager.UF_REG_NUMBER }} от
                    {{ kpItemManager.UF_DATE_CREATE | dateFormat }}</span>
    </div>
    <div class="page-top-title">
      <div class="page-top-title__item">
        <div class="content-title" v-if="veoName">КП по запросу № {{ kpItemManager.UF_REG_NUMBER }}
          Организация
          {{ kpItemManagerOrg.UF_NAME }};
          {{ veoName.UF_NAME }}
        </div>
        <br>
        <div class="content-title">
          Страна (ы) операции:
        </div>
        <div class="content-title">
          <p v-for="(country, index) in countries" :key="index">{{ country.UF_NAME }};</p>
        </div>
        <br>
        <div class="content-title">
          {{ kpItemManager.UF_VEO_SUBJECT }}; MAX КОЛ-ВО КОДОВ ТН ВЭД ЕАЭС {{ kpItemManager.UF_MAX_CNT_TN_VED }}
        </div>
        <br>
        <div class="content-title">
          Желательный срок получения заключения от {{ kpItemManager.UF_MIN_DAY_CONCLUSIONS }} до
          {{ kpItemManager.UF_MAX_DAY_CONCLUSIONS }} рабочих дней
        </div>
        <br>
        <div v-if="!!kpItemManager.UF_IS_OWN_FORM_DGR" class="content-title">
          Своя форма договора.
        </div>
        <br v-if="!!kpItemManager.UF_IS_OWN_FORM_DGR">
        <div class="content-title" v-if="!!kpItemManager.UF_DATE_REPLY_PLAN">
          Плановая дата ответа: {{ kpItemManager.UF_DATE_REPLY_PLAN }}
        </div>
        <br>
        <div class="content-title" v-if="kpItemManager.KP_ORG_REPLY && !!kpItemManager.KP_ORG_REPLY[0].UF_DATE_SEND">
          Дата ответа: {{ kpItemManager.KP_ORG_REPLY[0].UF_DATE_SEND }}
        </div>
      </div>
      <div class="page-top-title__item text-right">
      </div>
    </div>
    <div class="content-tab-area">
      <div class="content-tab__panel tab_active">
        <div class="container-fluid">
          <div class="content-tab-wrapper">
            <div class="content-tab-indent">
              <div class="table-responsive production-area t-small">
                <table class="production-table_child">
                  <thead>
                  <tr>
                    <th class="mob-cell-w_150">Срок (рабочие дни)</th>
                    <th class="mob-cell-w_150">Цена с НДС (руб.)</th>
                    <th class="mob-cell-w_100 text-center">Действие</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in composition" :key="index">
                    <td>
                      <div class="production-cell">
                        <input
                            :disabled="kpItemManager.KP_ORG_REPLY && kpItemManager.KP_ORG_REPLY.length > 0 && !kpItemManager.KP_ORG_REPLY[0].UF_IS_EDIT"
                            type="number"
                            class="inp-production-cell text-right work-days"
                            v-model="item.UF_TIME_RELEASE">
                      </div>
                    </td>
                    <td>
                      <div class="production-cell">
                        <vue-numeric
                            :disabled="kpItemManager.KP_ORG_REPLY && kpItemManager.KP_ORG_REPLY.length > 0 && !kpItemManager.KP_ORG_REPLY[0].UF_IS_EDIT"
                            v-model="item.UF_PRICE_NDS"
                            :min="0"
                            :max="1000000000000"
                            :precision="2"
                            separator="space"
                            output-type="Number"
                            class="inp-production-cell text-right">
                        </vue-numeric>
                      </div>
                    </td>
                    <td class="text-center">
                      <button
                          :disabled="kpItemManager.KP_ORG_REPLY && kpItemManager.KP_ORG_REPLY.length > 0 && !kpItemManager.KP_ORG_REPLY[0].UF_IS_EDIT"
                          @click.prevent="deleteItem(index)"
                          class="production-del_link stl-mb_0 href__button">
                      </button>
                    </td>
                  </tr>
                  <tr class="no-border-bot">
                    <td colspan="3">
                      <button
                          :disabled="kpItemManager.KP_ORG_REPLY && kpItemManager.KP_ORG_REPLY.length > 0 && !kpItemManager.KP_ORG_REPLY[0].UF_IS_EDIT"
                          class="control-panel__link control-add_link href__button"
                          @click.prevent="addItem">
                        Добавить строку
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="content-tab-wrapper">
            <h4 class="h4-title">Дополнительная информация</h4>
            <div class="row">
              <div class="col-md-12" v-if="kpItemManager.KP_ORG_REPLY">
                      <textarea
                          :disabled="kpItemManager.KP_ORG_REPLY.length > 0 && !kpItemManager.KP_ORG_REPLY[0].UF_IS_EDIT"
                          class="main_textarea"
                          v-model="kpItemManager.KP_ORG_REPLY[0].UF_COMMENT">
                      </textarea>
              </div>
            </div>
            <a v-if="kpItemManager.KP_ORG_REPLY && kpItemManager.KP_ORG_REPLY[0].UF_FILE>0" @click="openFile">
              Проект договора
            </a>
          </div>
          <div
              v-if="kpItemManager.KP_ORG_REPLY && !!kpItemManager.KP_ORG_REPLY.length && kpItemManager.KP_ORG_REPLY[0].UF_IS_EDIT"
              class="btn-box">
            <button
                type="button"
                class="link_style-blue"
                @click="editKPManager">
              Сохранить
            </button>
            <button
                type="button"
                class="link_style-blue"
                @click="showUploadModal(item, item.ID, 0)">
              Прикрепить проект договора
            </button>
            <button
                type="button"
                class="link_style-blue"
                @click="sendKPManager">
              Отправить КП
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- content-tab-area -->
    <!--          <div class="modal fade in show" tabindex="-1" v-if="uploadModalIsShowed(item)" role="dialog"-->
    <!--               aria-labelledby="myModalLabel">-->
    <!--            <div class="modal-dialog " style="width: 490px" role="document">-->
    <!--              <div class="modal-content">-->
    <!--                <div class="modal-add-document"-->
    <!--                     style="z-index: 10000;width: 490px">-->
    <!--                  <div class="modal-header main-clear">-->
    <!--                    <span class="modal-title">Прикрепление проекта договора</span>-->
    <!--                    <a @click="closeUploadModal(item)" class="modal-exit stl-FR"></a>-->
    <!--                  </div>-->
    <!--                  <div class="modal-body" v-show="!uploadCard.showECP">-->

    <!--                    <div class="modal_row main-clear" :disabled="uploadCard.blockName">-->
    <!--                      <span class="modal-field_name">Название</span>-->
    <!--                      <div v-if="uploadCard.ID>0">-->
    <!--                        {{ uploadCard.OLD_NAME }}-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                    <div class="modal_row main-clear">-->
    <!--                      <div class="modal_upload-form" v-if="uploadCard.accept">-->
    <!--                        <label>-->
    <!--                          <input :accept="uploadCard.accept" name="modal-file"-->
    <!--                                 @change="onChangeFile"-->
    <!--                                 type="file"-->
    <!--                                 class="modal_i_file">-->
    <!--                          <div class="modal_link_add">выбрать файл</div>-->
    <!--                          <input name="modal-i-file_text" type="text"-->
    <!--                                 class="modal_i_file_text"-->
    <!--                                 :value="uploadCard.UP_NAME" disabled>-->
    <!--                        </label>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                    <div class="modal_row main-clear">-->
    <!--                      <button class="link_style-blue modal_btn_wid" style="width: 30%"-->
    <!--                              @click="uploadDocument(item)"-->
    <!--                              v-if="uploadCard.UF_FILE && uploadCard.UF_NAME && uploadCard.UF_VID_DOC_REQUEST > 0 && !uploadTypesItem(item, uploadCard.UF_VID_DOC_REQUEST).UF_IS_ECP_REQ">-->
    <!--                        сохранить-->
    <!--                      </button>-->
    <!--                      <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"-->
    <!--                           @click="closeUploadModal(item)">-->
    <!--                        отмена-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"-->
    <!--                       @click="closeUploadModal(item)">-->
    <!--                    отмена-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ApiService, {KPService} from "@/common/api.service";
import {SET_COMPOSITION} from "@/store/mutations.type";
import Vue from "vue";
import VueNumeric from 'vue-numeric'
import moment from "moment";

export default {
  name: "ReqKPManager",

  data() {
    return {
      managerIsLoading: false,
      veoName: '',
      countries: [],
      composition: [],
      uploadCard: {
        UF_VID_DOC_REQUEST: 0,
        UF_FORM_DOC_REQ_ID: 1,
        ID: 0,
        UF_NAME: "",
        UF_FILE: "",
        UP_NAME: "",
        blockName: false,
        showECP: false,
        file: ""
      },
      tosterErrors: [],
    }
  },

  components: {
    VueNumeric
  },

  computed: {
    ...mapGetters([
      "directory",
      "kpItemManager",
      "kpItemManagerOrg",
      "isKPLoading",
    ])
  },

  async created() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country",
    ]);
    await this.fetchItem();
    // console.log(this.kpItemManager);
  },

  methods: {

    openFile() {
      ApiService.get(`services/getFileById/${this.kpItemManager.KP_ORG_REPLY.UF_FILE}`)
          .then(data => window.open(data));
    },

    addItem() {
      this.composition.push({
        ID: this.composition.length,
        UF_TIME_RELEASE: null,
        UF_REQUEST_KP_ORGRPL_ID: 1,
        UF_DATE_CREATE: null,
        UF_PRICE_NDS: 0.00,
      });
    },

    deleteItem(index) {
      this.composition.splice(index, 1);
    },

    async fetchItem() {
      await this.$store.dispatch('fetchKPItemManagerOrg', this.kpItemManager.UF_COMPANY_ID).then(() => {
        this.veoName = this.directory.veo.filter(el => el.ID === this.kpItemManager.UF_VEO_ID)[0]
        this.countries = this.directory.country.filter(el => -1 !== this.kpItemManager.UF_COUNTRY_ID.indexOf(el.ID));
        if (this.kpItemManager.KP_ORG_REPLY && this.kpItemManager.KP_ORG_REPLY[0].KP_ORG_REPLY_COMPOSITION.length !== 0) {
          this.composition = this.kpItemManager.KP_ORG_REPLY.KP_ORG_REPLY_COMPOSITION;
        } else {
          this.addItem();
        }
        this.managerIsLoading = true;
      }, this)
    },

    editKPManager() {
      this.$store.commit(SET_COMPOSITION, this.composition);
      // console.log(this.kpItemManager.ID);
      // console.log(this.kpItemManager);
      KPService.editKPManager(this.kpItemManager.ID, this.kpItemManager).then(({data}) => {
        // console.log(data);
        this.$route.params.id = data.ID
        if (data.errorAr.length > 0) {
          data.errorAr.forEach(function (err) {
            this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                err.TEXT.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.tosterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
        } else {
          this.fetchItem();
          this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
              'Запрос КП сохранен, Рег. номер ' + data.UF_REG_NUMBER,
              {
                theme: "green",
                position: "bottom-right",
                duration: 5000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
        // location.hash = "#htop";
      }, this)
    },

    sendKPManager() {
      this.$store.commit(SET_COMPOSITION, this.composition);
      // console.log(this.kpItemManager.ID);
      // console.log(this.kpItemManager);
      KPService.sendKPManager(this.kpItemManager.ID, this.kpItemManager).then(({data}) => {
        // console.log(data);
        this.$route.params.id = data.ID
        if (data.errorAr.length > 0) {
          data.errorAr.forEach(function (err) {
            this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.tosterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
        } else {
          this.fetchItem()
          this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
              'Запрос КП отправлен, Рег. номер ' + data.UF_REG_NUMBER,
              {
                theme: "green",
                position: "bottom-right",
                duration: 5000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
        // location.hash = "#htop";
      }, this)
    },

    showUploadModal(item, child, doc, oldName) {
      this.uploadCard.UF_VID_DOC_REQUEST = child
      if (child > 0) {
        this.uploadCard.accept = "." + item.UF_FORMAT_TYPE.replace(/;/g, ",.")
      }
      this.uploadCard.UP_NAME = "Файл не выбран"
      this.uploadCard.UF_FILE = ""
      this.uploadCard.UF_NAME = oldName
      this.uploadCard.OLD_NAME = oldName
      this.uploadCard.ID = doc
      this.uploadCard.showECP = false
      this.ecpValue = ""
      Vue.set(item, 'showUploadModal', true)
    },

    closeUploadModal(item) {
      Vue.set(item, 'showUploadModal', false)
    },

    uploadDocument(item) {
      let formData = new FormData();
      formData.append("UF_VID_DOC_REQUEST", this.uploadCard.UF_VID_DOC_REQUEST)
      formData.append("UF_FORM_DOC_REQ_ID", this.uploadCard.UF_FORM_DOC_REQ_ID)
      formData.append("UF_FILE", this.uploadCard.file)
      formData.append("UF_NAME", this.uploadCard.UF_NAME)
      formData.append("UF_FILE_SIGN_ECP", this.ecpValue)
      formData.append("UF_FILE_SIGN_AUTHOR", this.ecpCert.text)

      KPService.uploadDocument(this.kpItemManager.ID, formData).then(({data}) => {
        if (!data.error) {
          this.closeUploadModal(item)
        }
      }, this)
    },

    getStatus(data) {
      const status = data.filter(el => {
        if (el.ID === this.kpItem.UF_STATUS_ID) {
          return el;
        }
      })
      if (status[0] && !!status[0].UF_NAME.length) {
        return status[0].UF_NAME;
      }
    }
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
}
</script>

<style scoped>
.href__button {
  border: none;
}

.work-days::-webkit-outer-spin-button,
.work-days::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.work-days[type=number] {
  -moz-appearance: textfield;
}
</style>

