<template>
  <div class="auth-wrapper">
    <div class="auth-header__menu main-clear">
      <router-link :to="{name:'Login'}" class="prev-page" title="Назад"></router-link>
    </div>
    <div class="auth-content">
      <div class="auth-area">
        <Logo/>
        <ChangePassword/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import ChangePassword from '@/components/auth/ChangePassword.vue'
  import Logo from '@/views/auth/Logo.vue'
  import Footer from "@/views/auth/Footer";

  export default {
    name: 'Auth',
    metaInfo() {
      return {
        title: '"ИС-НЕЙВА", изменение пароля',
      }
    },
    components: {
      Footer,
      Logo,
      ChangePassword
    }
  }
</script>
