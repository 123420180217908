<template>
  <div class="content-tab__panel tab_active">
    <div class="container-fluid">
      <div class="content-tab-wrapper">
        <div class="row">
          <div class="col-md-12">
            <input v-if="conclusionItem.TN_VED_GROUP.length === 0 && currentUser.ACTIVE_ROLE_CODE === 'expert_org_expert' " class="link_style-blue"
                   type="button"
                   @click="createTableAnalisys"
                   value="Сформировать таблицы"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-name col-name_bold">4.1. Позиции (пункты) контрольных списков, выбранные
            для сравнительного анализа
          </div>
          <div class="col-md-12 col-lg-12 col-data">
            <div class="table-responsive production-area">
              <table class="production-table_child">
                <thead>
                <tr>
                  <th class="mob-cell-w_100">№ объекта</th>
                  <th class="mob-cell-w_250">Номер позиции (пункта)</th>
                  <th class="mob-cell-w_250">Номер раздела</th>
                  <th class="mob-cell-w_200">Наименование списка</th>
                </tr>
                </thead>
                <tbody class="tbl-box-wed" v-for="item in conclusionItem.TN_VED_GROUP" :key="item.ID">
                <tr>
                  <td colspan="4" class="stl-row-wed">
                    <b>код ТН ВЭД ЕАЭС {{ item.UF_TN_VED_CODE }}:</b>
                    <p v-for="tn in item.UF_OBJECT" :key="tn.ID"><b>{{ tn.UF_NUMBER_OBJECT }}</b> {{ tn.UF_NAME }}</p>
                  </td>
                </tr>
                <tr v-for="(rcl, index) in item.RAZDEL_CONTROL_LIST" :key="rcl.ID">
                  <td>{{index+1}}</td>
                  <td>
                    <div class="production-cell">
                      <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="inp-production-cell"
                             style="width: 100%"
                             v-model="rcl.UF_NUMBER_PST_TEXT">
                    </div>
                  </td>
                  <td>
                    <div class="production-cell">
                      <select :disabled="!conclusionItem.UF_IS_EDIT" v-model="rcl.UF_CNT_RAZDEL_ID"
                              class="main_select inp-production-cell">
                        <optgroup v-for="c in cl" :key="c.ID" :label="c.UF_NAME">
                          <option :value="o.ID" v-for="o in rzd(c.CHILDS)" :key="o.ID">{{ o.name }}</option>
                        </optgroup>
                      </select>
                    </div>
                  </td>
                  <td class="stl-vermid">{{ getCode(conclusionItem.UF_CNT_RAZDEL_ID) }}</td>
                </tr>
                <tr>
                  <td colspan="4">
                    <a v-if="conclusionItem.UF_IS_EDIT" @click="item.RAZDEL_CONTROL_LIST.push({})" class="control-panel__link control-add_link">Добавить
                      позицию</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-name col-name_bold">4.2. Результаты сравнительного анализа</div>
          <div class="col-md-12 col-lg-12 col-data">
            <div class="table-responsive production-area">
              <table class="production-table_child">
                <thead>
                <tr>
                  <th class="mob-cell-w_100">№ объекта</th>
                  <th class="mob-cell-w_250">Характеристики объекта</th>
                  <th class="mob-cell-w_250">Описание позиции (пункта)</th>
                  <th class="mob-cell-w_200">Выводы</th>
                </tr>
                </thead>
                <tbody class="tbl-box-wed" v-for="item in conclusionItem.TN_VED_GROUP" :key="item.ID">
                <tr>
                  <td colspan="5" class="stl-row-wed">
                    <b>код ТН ВЭД ЕАЭС {{ item.UF_TN_VED_CODE }}:</b>
                    <p v-for="tn in item.UF_OBJECT" :key="tn.ID"><b>{{ tn.UF_NUMBER_OBJECT }}</b> {{ tn.UF_NAME }}</p>
                  </td>
                </tr>
                <tr v-for="(rcl, index) in item.COMPARATIVE_ANALISYS" :key="rcl.ID">
                  <td>{{index+1}}</td>
                  <td>
                    <div class="production-cell"><textarea-autosize :disabled="!conclusionItem.UF_IS_EDIT" class="inp-production-cell" placeholder=""
                                                           v-model="rcl.UF_CHARACTERISTICS_OBJECT"></textarea-autosize></div>
                  </td>
                  <td>
                    <div class="production-cell">
                      <select :disabled="!conclusionItem.UF_IS_EDIT" v-model="rcl.UF_CNT_RAZDEL_ID" class="main_select inp-production-cell">
                        <optgroup v-for="c in cl" :key="c.ID" :label="c.UF_NAME">
                          <option :value="o.ID" v-for="o in rzd(c.CHILDS)" :key="o.ID">{{ o.name }}</option>
                        </optgroup>
                      </select>
                    </div>
                    <div class="production-cell">
                                  <textarea-autosize :disabled="!conclusionItem.UF_IS_EDIT" class="inp-production-cell" placeholder="" v-model="rcl.UF_DESCRIPTION">
                                  </textarea-autosize>
                    </div>
                  </td>
                  <td>
                    <div class="production-cell">
                      <select :disabled="!conclusionItem.UF_IS_EDIT" v-model="rcl.UF_CONCLUSIONS_ID" class="main_select inp-production-cell">
                        <option :value="o.ID" v-for="o in directory.conclusions_list" :key="o.ID">{{
                            o.UF_NAME
                          }}
                        </option>
                      </select>
                    </div>
                    <div class="production-cell"><textarea-autosize :disabled="!conclusionItem.UF_IS_EDIT" class="inp-production-cell" placeholder=""
                                                           v-model="rcl.UF_CONCLUSIONS"></textarea-autosize>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <a v-if="conclusionItem.UF_IS_EDIT" @click="item.COMPARATIVE_ANALISYS.push({})" class="control-panel__link control-add_link">Добавить
                      позицию</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- content-tab-wrapper -->
    </div>
    <!-- container-fluid -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ConclusionService} from "@/common/api.service";

export default {
  name: "Review",
  data() {
    return {
      cl: []
    }
  },
  computed: {
    ...mapGetters([
      "conclusionItem",
      "isConclusionsLoading",
      "directory",
      "currentUser"
    ]),
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "conclusions_list"
    ])

    await ConclusionService.getTreeControlList().then(({data}) => {
      this.cl = data
    }, this)
  },
  methods: {
    async createTableAnalisys() {
      ConclusionService.createTableAnalisys(this.conclusionItem.ID).then(() => {
        this.$parent.fetchItem()
      }, this)
    },
    rzd(items) {
      let r = []
      for (let i = 0; i < items.length; i++) {
        if (!items[i].CHILDS) {
          items[i].name = items[i].UF_SMALL_NAME
          r.push(items[i])
        } else {
          for (let k = 0; k < items[i].CHILDS.length; k++) {
            items[i].CHILDS[k].name = items[i].UF_SMALL_NAME + ' ' + items[i].CHILDS[k].UF_SMALL_NAME
            r.push(items[i].CHILDS[k])
          }
        }
      }
      return r
    },
    getCode(id) {
      for (let c = 0; c < this.cl.length; c++)
        for (let i = 0; i < this.cl[c].CHILDS.length; i++) {
          if (this.cl[c].CHILDS[i].ID === id) {
            return this.cl[c].UF_CODE
          }
          if (this.cl[c].CHILDS[i].CHILDS) {
            for (let k = 0; k < this.cl[c].CHILDS[i].CHILDS.length; k++) {
              if (this.cl[c].CHILDS[i].CHILDS[k].ID === id) {
                return this.cl[c].UF_CODE
              }
            }
          }
        }
    }
  },
}
</script>

<style scoped>

</style>