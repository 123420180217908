<template>
  <div class="content-tab-wrapper">
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Регистрационный № договора (№договора)</div>
      <div class="col-md-9 col-lg-8 col-data"><input type="text" class="main_input"  v-model="contractItem.UF_NUMBER"></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Срок подписания договора</div>
      <div class="col-md-9 col-lg-8 col-data">
        <datepicker
            v-model="contractItem.UF_DATE_SIGN_EXPERT"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Статус договора</div>
      <div class="col-md-9 col-lg-8 col-data">
        <select v-model="contractItem.UF_STATUS_DOGOVOR" class="main_input main_select">
          <option v-for="item in directory.status_dogovor" :key="item.ID" :value="item.ID">
            {{ item.UF_NAME }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Договор распространяется только на 1 заявку</div>
      <div class="col-md-9 col-lg-8 col-data"><input v-model="contractItem.UF_IS_ONE_REQUEST" type="checkbox" class="main_check"></div>
    </div>
    <div class="row" v-if="!contractItem.UF_IS_ONE_REQUEST">
      <div class="col-md-3 col-lg-4 col-name">Срок действия договора</div>
      <div class="col-md-9 col-lg-8 col-data"><span style="float: left;padding-top: 5px;padding-right: 4px">с</span>
        <datepicker
            v-model="contractItem.UF_DATE_START_MAIN_D"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>

        <span style="float: left;padding-top: 5px;padding-right: 4px"> по</span>
        <datepicker
            v-model="contractItem.UF_DATE_END_MAIN_D"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>
      </div>
    </div>
    <div class="row" v-if="!contractItem.UF_IS_ONE_REQUEST">
      <div class="col-md-3 col-lg-4 col-name">Автопролонгация</div>
      <div class="col-md-9 col-lg-8 col-data"><input v-model="contractItem.UF_IS_AUTO_PROLONG" type="checkbox" checked class="main_check"></div>
    </div>
    <div class="row" v-if="!contractItem.UF_IS_ONE_REQUEST && contractItem.UF_IS_AUTO_PROLONG">
      <div class="col-md-3 col-lg-4 col-name">Срок автопролонгации</div>
      <div class="col-md-9 col-lg-8 col-data">
        <select class="main_input main_select" v-model="contractItem.UF_PERIOD_AUTO_PROLONG_ID">
          <option value="1 год">1 год</option>
          <option value="2 год">2 год</option>
          <option value="3 год">3 год</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ru} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "ContractMainInfo",
  components: {Datepicker},
  props: {
    contractItem: {
      type: Object
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org", "vid_dogovor", "status_dogovor",
    ]);
  },
  data() {
    return {
      ru: ru
    }
  },
  computed: {
    ...mapGetters([
      "isConclusionsLoading",
      "directory"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    directoryVal(directory, id) {
      for (let i = 0; i < directory.length; i++) {
        if (directory[i].ID === id) {
          return directory[i]
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>

</style>