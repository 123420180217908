<template>
    <div class="content-tab__panel tab_active">
        <div class="container-fluid" v-if="!isTariffsLoading">
            <p class="tariff-text">{{tariffs.PREVIEW_TEXT}}</p>
            <div class="table-area t-small">
                <table class="table_style">
                    <thead>
                    <tr class="">
                        <th rowspan="2" class="size_midle">Нзвание плана</th>
                        <th colspan="2" class="after_dashed style-pl_0">Количество заявок</th>
                        <th rowspan="2">Стоимость с НДС<br>(тыс.руб.)</th>
                        <th rowspan="2">Действует с</th>
                        <th rowspan="2" class="text-center">Скачать</th>
                    </tr>
                    <tr class="">
                        <th style="width: 62px;" class="style-pl_38">от</th>
                        <th style="width: 62px;" class="style-pl_0">до</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="tariff in tariffs.TARIFFS" :key="tariff.ID">
                        <td data-label="Нзвание плана"><a href="#">{{tariff.NAME}}</a></td>
                        <td data-label="Количество заявок 'от'">{{tariff.COUNT_REQ_MIN}}</td>
                        <td data-label="Количество заявок 'до'" class="style-pl_0">{{tariff.COUNT_REQ_MAX}}</td>
                        <td data-label="Стоимость с НДС (тыс.руб.)">{{tariff.NAME}}</td>
                        <td data-label="Действует с">{{tariff.PRICE}}</td>
                        <td data-label="Скачать" class="text-center"><a :href="tariff.FILE" class="i-file"></a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {FETCH_TARIFFS, FETCH_TARIFFS_ARCHIVE} from "@/store/actions.type";
    import {mapGetters} from "vuex";

    export default {
        name: "TariffsList",
        data() {
            return {
                itemsPerPage: 20,
                currentPage: 1
            };
        },
        mounted() {
            this.fetchTariffs()
        },
        computed: {
            ...mapGetters([
                "tariffs",
                "isTariffsLoading"
            ]),
            listConfig() {
                const q = {
                    page: this.currentPage,
                    limit: this.itemsPerPage,
                };

                return q;
            },
            pages() {
                if (this.isLoading || this.tariffs.counts <= this.itemsPerPage) {
                    return [];
                }
                return [
                    ...Array(Math.ceil(this.tariffs.counts / this.itemsPerPage)).keys()
                ].map(e => e + 1);
            }
        },
        watch: {
            currentPage(newValue) {
                this.listConfig.page = newValue;
                this.fetchTariffs();
            },
            itemsPerPage(newValue) {
                this.listConfig.limit = newValue;
                this.fetchTariffs();
            }
        },
        methods: {
            fetchTariffs() {
                this.$store.dispatch(FETCH_TARIFFS, this.listConfig).then(() => {
                    document.title = unescape(this.tariffs.META_TITLE);
                }, this)
            },
            fetchTariffsArchive() {
                this.$store.dispatch(FETCH_TARIFFS_ARCHIVE);
            }
        }
    }
</script>

<style scoped>

</style>