<template>
    <div class="main-wraper">
        <Header/>
        <section class="content-area">
            <div class="content-indent">
                <div class="page-top-title">
                    <div class="page-top-title__item">
                        <h2 class="content-title">Методические материалы</h2>
                    </div>
                    <div class="page-top-title__item text-right">
                    </div>
                </div>
                <!-- page-top-title -->
                <div class="content-tab-area">
                    <ul class="content-tab-menu">
                        <router-link tag="li" class="tab-menu__item" :to="{'name': 'News'}">Новости</router-link>
                        <router-link tag="li" class="tab-menu__item tab_active" :to="{'name': 'Methods'}">Методические материалы</router-link>
                        <router-link tag="li" class="tab-menu__item" :to="{'name': 'Tariffs'}">Тарифы</router-link>
                    </ul>
                    <MethodsList/>
                </div>
                <!-- content-tab-area -->
            </div>
            <!-- content-indent -->
        </section>
        <!-- content-area -->
        <Footer />
    </div>
</template>

<script>
    import Footer from "@/views/site/Footer";
    import Header from "@/views/site/Header";
    import MethodsList from "@/components/site/MethodsList";

    export default {
        name: "Methods",
        components: {MethodsList, Header, Footer},
    }
</script>

<style scoped>

</style>