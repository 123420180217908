const USER_DATA_KEY = "user_data_key";

export const getUser = () => {
  if (window.localStorage.getItem(USER_DATA_KEY) == null) {
    return null;
  }
  return JSON.parse(window.localStorage.getItem(USER_DATA_KEY));
};

export const saveUser = data => {
  window.localStorage.setItem(USER_DATA_KEY, JSON.stringify(data));
};

export const destroyUser = () => {
  window.localStorage.removeItem(USER_DATA_KEY);
};

export default { getUser, saveUser, destroyUser };
