import {KPService} from "@/common/api.service";
import {SET_COMPOSITION} from "./mutations.type";

const state = {
    kp: {
        counts: 0,
        items: []
    },
    kpItem: {},
    kpItemManager: {},
    kpItemManagerOrg: {},
    isKPLoading: true,
    isKPLoadingError: false
};

const getters = {
    kp(state) {
        return state.kp;
    },
    kpItem(state) {
        return state.kpItem;
    },
    kpItemManager(state) {
        return state.kpItemManager;
    },
    kpItemManagerOrg(state) {
        return state.kpItemManagerOrg;
    },
    isKPLoading(state) {
        return state.isKPLoading;
    },
    isKPLoadingError(state) {
        return state.isKPLoadingError;
    }
};

const actions = {
    fetchKPs({commit}, params) {
        commit("fetchKPStart");
        return KPService.list(params.type, params)
            .then(({data}) => {
                commit('fetchKPEnd', data);
            })
            .catch(error => {
                commit('fetchKPError');
                throw new Error(error);
            });
    },
    fetchKPItem({commit}, slug) {
        commit('fetchKPStart');
        if (slug) {
            return KPService.item(slug)
                .then(({data}) => {
                    commit('fetchKPItemEnd', data);
                })
                .catch(error => {
                    commit('fetchKPError');
                    throw new Error(error);
                });
        } else {
            return KPService.loadTemplate()
                .then(({data}) => {
                    commit('fetchKPItemEnd', data);
                })
                .catch(error => {
                    commit('fetchKPError');
                    throw new Error(error);
                });
        }
    },
    fetchKPItemManager({commit}, slug) {
        commit('fetchKPStart');
        return KPService.itemManager(slug)
            .then(({data}) => {
                commit('fetchKPItemManagerEnd', data);
            })
            .catch(error => {
                commit('fetchKPError');
                throw new Error(error);
            });
    },
    fetchKPItemManagerOrg({commit}, slug) {
        commit('fetchKPStart');
        return KPService.itemManagerOrg(slug)
            .then(({data}) => {
                commit('fetchKPItemManagerOrgEnd', data);
            })
            .catch(error => {
                commit('fetchKPError');
                throw new Error(error);
            });
    }
};

const mutations = {
    ['fetchKPStart'](state) {
        state.isKPLoading = true;
    },
    ['fetchKPEnd'](state, news) {
        state.kp = news;
        state.isKPLoading = false;
    },
    ['fetchKPError'](state) {
        state.isKPLoading = false;
        state.isKPLoadingError = true;
    },
    ['fetchKPItemEnd'](state, item) {
        state.isKPLoading = false;
        state.kpItem = item;
        return item;
    },
    ['fetchKPItemManagerEnd'](state, item) {
        state.isKPLoading = false;
        state.kpItemManager = item;
        return item;
    },
    ['fetchKPItemManagerOrgEnd'](state, item) {
        state.isKPLoading = false;
        state.kpItemManagerOrg = item;
        return item;
    },
    [SET_COMPOSITION](state, arr) {
        state.kpItemManager.KP_ORG_REPLY[0].KP_ORG_REPLY_COMPOSITION = arr;
        console.log(state.kpItemManager);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
