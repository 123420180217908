<template>
    <div>
        <div class="support-widget">
            <router-link :to="{name: 'Faq'}" class="support_question"/>
            <span class="support_hr"/>
            <a href="#" @click="$store.commit('switchContactsModal')" class="support_call"/>
            <span class="support_hr"/>
            <a href="#" @click="$store.commit('switchTechSupportModal')" class="support_mail"/>
        </div>
        <Contacts v-if="$store.state.showContactsModal"/>
        <TechSupport v-if="$store.state.showTechSupportModal"/>
    </div>
</template>

<script>
    import Contacts from "@/components/support/Contacts";
    import TechSupport from "@/components/support/TechSupport";

    export default {
        name: "Support",
        components: {TechSupport, Contacts}
    }
</script>

<style scoped>

</style>