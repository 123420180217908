import {NewsService} from "@/common/api.service";
import {
  FETCH_MP,
  FETCH_NEWS,
  FETCH_NEWS_ITEM
} from "./actions.type";

import {
  FETCH_NEWS_START, FETCH_NEWS_END, FETCH_NEWS_ERROR, FETCH_NEWS_ITEM_END, FETCH_MP_START, FETCH_MP_END, FETCH_MP_ERROR
} from "./mutations.type";

const state = {
  newsItem: {
    ID: "",
    UF_NUMBER: "",
    UF_NAME: "",
  },
  news: {
    counts: 0,
    items: []
  },
  mp:{},
  isNewsLoading: true,
  isNewsLoadingError: false
};

const getters = {
  news(state) {
    return state.news;
  },
  isNewsLoading(state) {
    return state.isNewsLoading;
  },
  isNewsLoadingError(state) {
    return state.isNewsLoadingError;
  },
  mp(state) {
    return state.mp;
  },
  newsItem(state) {
    return state.newsItem;
  }
};

const actions = {
  [FETCH_MP]({ commit }) {
    commit(FETCH_MP_START);
    return NewsService.mp()
        .then(({ data }) => {
          commit(FETCH_MP_END, data);
        })
        .catch(error => {
          commit(FETCH_MP_ERROR);
          throw new Error(error);
        });
  },
  [FETCH_NEWS_ITEM]({ commit }, slug) {
    commit(FETCH_NEWS_START);
    return NewsService.item(slug)
        .then(({ data }) => {
          commit(FETCH_NEWS_ITEM_END, data);
        })
        .catch(error => {
          commit(FETCH_NEWS_ERROR);
          throw new Error(error);
        });
  },
  [FETCH_NEWS]({ commit }, params) {
    commit(FETCH_NEWS_START);
    return NewsService.list(params.type, params)
      .then(({ data }) => {
        commit(FETCH_NEWS_END, data);
      })
      .catch(error => {
        commit(FETCH_NEWS_ERROR);
        throw new Error(error);
      });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_NEWS_START](state) {
    state.isNewsLoading = true;
  },
  [FETCH_NEWS_END](state, news) {
    state.news = news;
    state.isNewsLoading = false;
  },
  [FETCH_NEWS_ERROR](state) {
    state.isNewsLoading = false;
    state.isNewsLoadingError = true;
  },
  [FETCH_MP_START](state) {
    state.isNewsLoading = true;
  },
  [FETCH_MP_END](state, mp) {
    state.mp = mp;
    state.isNewsLoading = false;
  },
  [FETCH_MP_ERROR](state) {
    state.isNewsLoading = false;
    state.isNewsLoadingError = true;
  },
  [FETCH_NEWS_ITEM_END](state, item) {
    state.isNewsLoading = false;
    state.newsItem = item;
    return item;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
