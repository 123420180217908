<template>
    <div class="main-wraper">
        <Header/>
        <section class="content-area">
            <div class="content-indent">
                <FaqItem/>
            </div>
            <!-- content-indent -->
        </section>
        <!-- content-area -->
        <Footer />
    </div>
</template>

<script>
    import FaqItem from "@/components/site/FaqItem";
    import Footer from "@/views/site/Footer";
    import Header from "@/views/site/Header";

    export default {
        name: "ViewNews",
        components: {Header, Footer, FaqItem},
    }
</script>

<style scoped>

</style>