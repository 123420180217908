import {AccreditationOMService} from "@/common/api.service";

const state = {
    accreditationOM: {
        counts: 0,
        items: []
    },
    orgSPA: {},
    accreditationOMItem: {},
    isAccreditationOMLoading: true,
    isAccreditationOMLoadingError: false,
    isOrgSPALoading: true,
    isOrgSPALoadingError: false,
};

const getters = {
    accreditationOM(state) {
        return state.accreditationOM;
    },
    accreditationOMItem(state) {
        return state.accreditationOMItem;
    },
    orgSPA(state) {
        return state.orgSPA;
    },
    isAccreditationOMLoading(state) {
        return state.isAccreditationOMLoading;
    },
    isAccreditationOMLoadingError(state) {
        return state.isAccreditationOMLoadingError;
    }
};

const actions = {
    fetchAccreditationOMs({commit}, params) {
        commit("fetchAccreditationOMStart");
        return AccreditationOMService.list(params.type, params)
            .then(({data}) => {
                commit('fetchAccreditationOMEnd', data);
            })
            .catch(error => {
                commit('fetchAccreditationOMLoadingError');
                throw new Error(error);
            });
    },
    fetchAccreditationOMItem({commit}, slug) {
        commit('fetchAccreditationOMStart');
        return AccreditationOMService.item(slug)
            .then(({data}) => {
                commit('fetchAccreditationOMItemEnd', data);
            })
            .catch(error => {
                commit('fetchAccreditationOMLoadingError');
                throw new Error(error);
            });
    },
    fetchOrgSPA({commit}) {
        commit('fetchOrgSPAStart');
        return AccreditationOMService.listORGSPAccreditation()
            .then(({data}) => {
                commit('fetchOrgSPAEnd', data.result);
            })
            .catch(error => {
                commit('fetchOrgSPALoadingError');
                throw new Error(error);
            });
    },
};

const mutations = {
    ['fetchAccreditationOMStart'](state) {
        state.isAccreditationOMLoading = true;
    },
    ['fetchOrgSPAStart'](state) {
        state.isOrgSPALoading = true;
    },
    ['fetchAccreditationOMEnd'](state, data) {
        state.accreditationOM = data;
        state.isAccreditationOMLoading = false;
    },
    ['fetchAccreditationOMItemEnd'](state, item) {
        state.accreditationOMItem = item;
        state.isAccreditationOMLoading = false;
        return item;
    },
    ['fetchOrgSPAEnd'](state, data) {
        state.orgSPA = data;
        state.isOrgSPALoading = false;
        return data;
    },
    ['fetchAccreditationOMLoadingError'](state) {
        state.isAccreditationOMLoading = false;
        state.isAccreditationOMLoadingError = true;
    },
    ['fetchOrgSPALoadingError'](state) {
        state.isOrgSPALoading = false;
        state.isOrgSPALoadingError = true;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
