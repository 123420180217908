<template>
  <div class="auth-wrapper">
    <div class="auth-header__menu main-clear">
      <router-link  :to="{name:'Login'}" class="prev-page" title="Назад"></router-link>
    </div>
    <div class="auth-content">
      <div class="auth-area">
        <Logo/>
        <ControlString/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
    import ControlString from '@/components/auth/ControlString.vue'
    import Logo from '@/views/auth/Logo.vue'
    import Footer from "@/views/auth/Footer";

    export default {
        name: 'Auth',
        components: {
            Footer,
            Logo,
            ControlString
        }
    }
</script>
