<template>
  <div>
    <h3 class="form-title">Восстановление пароля</h3>
    <form action="#" class="form-auth-login" @submit.prevent="sendSmsCode">
      <div class="auth-field">
        <p><label for="sms" class="auth_label">Введите код подтверждения, полученный по смс</label></p>
        <input
            type="password"
            name="sms"
            class="auth_input"
            v-model="recoveryData.SMS_CODE"
        >
        <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError() }}</span>
      </div>
      <div class="recovery-code-area">
        <input type="submit" class="auth_btn" value="Восстановить" @keyup.enter="sendSmsCode">
      </div>
      <div class="recovery-code-area" v-if="currentTime===0 && counterSmsRecovery<3">
        <button
            class="button-consent recovery-code__btn"
            @click.prevent="rebootSmsCode"
        ></button>
        Запросить код повторно
      </div>
      <p class="recovery-code-hint" v-if="currentTime!==0">Повторный запрос кода через {{ prettyTime }}.</p>
    </form>
  </div>
</template>

<script>
    import ApiService from "@/common/api.service";
    import {mapGetters} from "vuex";

    export default {
        name: "SmsCode",
        data() {
            return {
                currentTime: 179,
                minutes: 0,
                seconds: 0,
                timer: null,
                counterSmsRecovery: 0,
                IS_SEND_SMS: 0,
                recoveryData: {
                    SMS_CODE: '',
                    USER_EMAIL: this.$store.getters.setRecoveryEmail,
                    recaptchaToken: this.$store.getters.setRecoveryCaptcha,
                },
                isSendEmail: 0,
                serverErrors: '',
                otherErrors: '',
            }

        },

        mounted() {
            this.startTimer()
        },

        destroyed() {
            this.stopTimer()
        },

        computed: {
            prettyTime() {
                let time = this.currentTime / 60;
                let minutes = parseInt(time);
                let seconds = Math.round((time - minutes) * 60);
                if (minutes === 0) {
                    return `${seconds} секунд`;
                }
                return `${minutes} минут ${seconds} секунд`;
            },
            ...mapGetters(["setRecoveryEmail", "setRecoveryCaptcha"]),

        },

        methods: {
            startTimer() {
                this.timer = setInterval(() => {
                    this.currentTime--
                }, 1000)
            },
            stopTimer() {
                clearTimeout(this.timer);
            },
            sendSmsCode() {
                if (this.recoveryData.SMS_CODE.length === 6) {
                    ApiService.post('auth/recovery/', this.recoveryData)
                        .then(
                            ({data}) => {
                                if (data.result.error === true) {
                                    this.serverErrors = data.result.errorArr;
                                    return;
                                }
                                this.isSendEmail = data.result.isSendEmail;
                                if (this.isSendEmail) {
                                    this.$router.push({name: 'ControlString'});
                                }
                            })
                        .catch(error => {
                            this.otherErrors = error;
                        });
                }
            },
            showServerError() {
                let arrayError = this.serverErrors
                    .map((item) => {
                        return item["TEXT"];
                    });
                return arrayError[0];
            },
            rebootSmsCode() {
                const {USER_EMAIL, recaptchaToken} = this.recoveryData;
                const body = {USER_EMAIL, recaptchaToken};
                ApiService.post('services/sendSMSCodeUserForRegistation/', body)
                    .then(
                        ({data}) => {
                            if (data.result.error === true) {
                                this.serverErrors = data.result.errorArr;
                                return;
                            }
                            this.IS_SEND_SMS = data.result.IS_SEND_SMS;
                        })
                    .catch(error => {
                        this.otherErrors = error;
                    });
                if (this.IS_SEND_SMS) {
                    this.counterSmsRecovery++;
                    this.currentTime = 179;
                }
            },
        },
        watch: {
            currentTime(time) {
                if (time === 0) {
                    this.stopTimer();
                }
            }
        },
    }
</script>

