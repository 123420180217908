<template>
    <div class="page-top-title">
        <div class="page-top-title__item">
            <h2 class="content-title">{{title}}</h2>
        </div>
        <div class="page-top-title__item text-right">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Title",
        props: {
            title: String
        }
    }
</script>

<style scoped>

</style>