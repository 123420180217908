import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from "@/views/auth/Dialog";
import Login from "@/views/auth/Login";
import Registration from "@/views/auth/Registration";
import News from "@/views/site/News";
import Methods from "@/views/site/Methods";
import Tariffs from "@/views/site/Tariffs";
import ViewNews from "@/views/site/ViewNews";
import ApiService from "@/common/api.service";
import ServicePage from "@/views/site/ServicePage";
import Faq from "@/views/site/Faq";
import ViewFaq from "@/views/site/ViewFaq";
import ReestrFSTEK from "@/views/lk/ReestrFSTEK";
import Requests from "@/views/lk/Requests";
import RequestCard from "@/views/lk/RequestCard";
import ResetPassword from "@/views/auth/ResetPassword";
import SmsCode from "@/views/auth/SmsCode";
import ControlString from "@/views/auth/ControlString";
import ChangePassword from "@/views/auth/ChangePassword";
import UserProfile from "@/views/lk/UserProfile";
import OrganizationProfile from "@/views/lk/OrganizationProfile";
import Conclusions from "@/views/lk/Conclusions";
import RequestsKP from "@/views/lk/RequestsKP";
import ConclusionCard from "@/views/lk/ConclusionCard";
import ReportCard from "@/views/lk/ReportCard";
import ResponseKP from "@/views/lk/ResponseKP";
import TZCard from "@/views/lk/TZCard";
import KPOnRequest from "@/components/cabinet/kp/KPOnRequest";
import Contracts from "@/views/lk/Contracts";
import ContractCard from "@/views/lk/ContractCard";
import AccreditationOM from "@/views/lk/AccreditationOM";
import DogovorsServicesOM from "@/views/lk/DogovorsServicesOM";
import PersonalAccountOM from "@/views/lk/PersonalAccountOM";
import AccreditationItemOM from "@/views/lk/AccreditationItemOM";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/auth/registration',
    name: 'Registration',
    component: Registration
  },

  {
    path: '/auth/recovery',
    name: 'ResetPassword',
    component: ResetPassword
  },

  {
    path: '/auth/recovery/code',
    name: 'SmsCode',
    component: SmsCode,
    beforeEnter: (to, from, next) => {
      if (from.name !== 'ResetPassword') next({ name: 'Login' })
      else next()
    }
  },

  {
    path: '/auth/recovery/send',
    name: 'ControlString',
    component: ControlString
  },

  {
    path: '/auth/confirmation',
    name: 'ChangePassword',
    component: ChangePassword
  },

  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: "/news/:id",
    name: "ViewNews",
    component: ViewNews
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: "/faq/:id",
    name: "ViewFaq",
    component: ViewFaq
  },
  {
    path: '/methods',
    name: 'Methods',
    component: Methods
  },
  {
    path: '/tariffs',
    name: 'Tariffs',
    component: Tariffs
  },
  {
    path: "/sp/:url",
    name: "ServicePage",
    component: ServicePage
  },
  {
    path: '/lk/reestr-fstec',
    name: 'LK/reestr-fstec',
    component: ReestrFSTEK
  },
  {
    path: '/lk/reestr-fstec/:id',
    name: 'LK/reestr-fstec/edit',
    component: ReportCard
  },
  {
    path: '/lk/request-list',
    name: 'LK/request',
    component: Requests
  },
  {
    path: '/lk/conclusion-list',
    name: 'LK/conclusions',
    component: Conclusions
  },
  {
    path: '/lk/contract-list',
    name: 'LK/dogovors',
    component: Contracts
  },
  {
    path: '/lk/edit-contract/:id',
    name: 'LK/dogovors/edit',
    component: ContractCard
  },
  {
    path: '/lk/create-contract',
    name: 'LK/dogovors/create',
    component: ContractCard
  },
  {
    path: '/lk/copy-contract/:id',
    name: 'LK/dogovors/copy',
    params: {copy: 1},
    component: ContractCard
  },
  {
    path: '/lk/create-request',
    name: 'LK/request/create',
    component: RequestCard
  },
  {
    path: '/lk/edit-request/:id',
    name: 'LK/request/edit',
    component: RequestCard
  },
  {
    path: '/lk/copy-request/:id/',
    name: 'LK/request/copy',
    params: {copy: 1},
    component: RequestCard
  },
  {
    path: '/lk/user/profile',
    name: 'LK/user/profile',
    component: UserProfile
  },
  {
    path: '/lk/profile-org',
    name: 'LK/profile-org',
    component: OrganizationProfile
  },
  {
    path: '/lk/profile-org-accr',
    name: 'LK/profile-org-accreditation',
    component: OrganizationProfile
  },
  {
    path: '/lk/request-kp',
      name: 'LK/request-kp',
    component: RequestsKP
  },
  {
    path: '/lk/edit-conclusion/:id',
    name: 'LK/conclusions/edit',
    component: ConclusionCard
  },
  {
    path: '/lk/edit-tz/:id',
    name: 'LK/conclusions/edit-tz',
    component: TZCard
  },
  {
    path: '/lk/create-response-kp',
    name: 'LK/response-kp/create',
    component: ResponseKP
  },
  {
    path: '/lk/copy-response-kp/:id/',
    name: 'LK/response-kp/copy',
    params: {copy: 1},
    component: ResponseKP
  },
  {
    path: '/lk/edit-response-kp/:id',
    name: 'LK/response-kp/edit',
    component: ResponseKP
  },
  {
    path: '/lk/kp-on-request',
    name: 'LK/kp-on-request',
    component: KPOnRequest
  },
  {
    path: '/lk/accreditation',
    name: 'LK/accreditation',
    component: AccreditationOM
  },
  {
    path: '/lk/accreditation/:id',
    name: 'LK/accreditation/edit',
    component: AccreditationItemOM
  },
  {
    path: '/lk/dogovors-services',
    name: 'LK/dogovors-services',
    component: DogovorsServicesOM
  },
  {
    path: '/lk/personal-account',
    name: 'LK/personal-account',
    component: PersonalAccountOM
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to => {
  ApiService.post("/services/addRecordLogUserURI/", {
    URI: to.path
  });
});

export default router
