import {ListService} from "@/common/api.service";

const state = {
    directory: {
        expert_org: [],
        country: [], //                       - страны
        veo: [], //                         - ВЭО (внешнеэкономические операции)
        name_control_list: [], //               - наименования контрольных списков
        categorii_control_list: [], //          - категории контрольных списков
        razdel_control_list: [], //           - разделы контрольных списков
        status_org: [], //               - статусы организаций
        status_user: [], //               - статусы пользователей
        vid_documents: [], //                 - виды документов
        vid_dogovor: [], //                - виды договоров
        vid_dop_soglashenie: [], //            - виды допольнительных соглашений
        status_dogovor: [], //            - статусы договоров
        status_zapros_kp: [], //            - статусы запроса КП
        status_documents_request: [], //        - статусы документов заявки
        status_request: [], //        - статусы заявок на экспертизу
        vid_documents_request: [], //        - виды документов заявки на экспертизу (древовидный справочник)
        vid_documents_accreditation: [], // - виды документов в аккредитации
        status_indent_finalies: [], //        - статусы идентификационных заключений
        forms_documents_request: [], //       - формы документов заявки
        conclusion_indent_finalies: [], //     - выводы идентификационных заключений
        vid_period_tp: [], //        - виды периодов тарифных планов
        form_company: [], //        - правовые формы организаций
        conclusions_list: [], //        - виды выводов о соответствии объекта контроля товару, подпадающему под действие ЭК
        status_request_comments: [],
        stavka_nds:[]
    }
};

const getters = {
    directory() {
        return state.directory;
    }
};

const actions = {
    ['fetchDirectory']({state}, r, force = false) {
        let rw = r[0].replace("/", "_");
        if (!force && state.directory[rw].length > 0) {
            return;
        }
        return ListService.list(r)
            .then(({data}) => {
                state.directory[rw] = data;
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    async ['fetchDirectories']({state}, rs = [], force = false) {
        for (const r of rs) {
            let rw = r.replace("/", "_");
            if (!force && state.directory[rw].length > 0) {
                continue;
            }
            await ListService.list(r)
                .then(({data}) => {
                    state.directory[rw] = data;
                })
                .catch(error => {
                    throw new Error(error);
                });
        }
    }
};

export default {
    state,
    getters,
    actions
};
