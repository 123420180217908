<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class=" modal-container__role modal-add-document"
             role="dialog"
             aria-labelledby="modal-title"
             aria-describedby="modalDescription"
        >
          <header
              class="modal-header main-clear"
          >
            <slot name="header">
              <div class="modal-title">СМЕНА РОЛИ
                <button
                    type="button"
                    @click="closeRole"
                    aria-label="Close modal"
                    class="modal-exit stl-FR reset-button"
                >
                </button>
              </div>
            </slot>
          </header>
          <section
              class="modal-body"
              id="modalDescription"
          >
            <slot name="body">
              <div class="modal_row main-clear modal_row_text">
                Роль
                <select name="organizations"
                        @change="selectRole"
                        v-model="roleValue"
                >
                  <option
                      v-for="(role, index) in changeRole"
                      :key="index"
                      :class="{ghost: activeRole!==role.ID}"
                  >
                    {{ role.NAME }}
                  </option>
                </select>
              </div>
            </slot>
          </section>
          <footer class="modal-footer modal_row main-clear text-center">
            <slot name="footer">
              <button
                  type="button"
                  class="btn-green link_style-blue"
                  @click="closeAndChangeRole"
                  aria-label="Close modal"
              >
                ИЗМЕНИТЬ
              </button>
              <button
                  type="button"
                  class="btn-green link_style-white"
                  @click="closeRole"
                  aria-label="Close modal"
              >
                ОТМЕНА
              </button>
            </slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ChangeRole",
  props: ['changeRole', 'activeRole'],
  data() {
    return {
      roleValue: "",
    }
  },
  computed: {},
  methods: {
    closeRole() {
      this.$emit('closeRole');
    },
    closeAndChangeRole() {
      this.$emit('closeAndChangeRole');
    },
    selectRole() {
      this.$emit('selectRole', this.roleValue);
    },
  },
}
</script>

<style scoped>
.ghost{
  display: none;
}
</style>
