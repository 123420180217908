<template>
    <div class="content-tab__panel tab_active">
        <div class="news-wrapper" v-if="!isNewsLoading">
            <div class="news-item main-clear">
                <img :src="newsItem.PREVIEW_PICTURE" alt="Новость" class="news-item__picture">
                <div class="news-item__content">
                    <span class="news-item__data">{{newsItem.DATE_CREATE}}</span>
                    <div class="news-item__detail" v-html="newsItem.DETAIL_TEXT">
                    </div>
                </div>
                <!-- news-item__content -->
            </div>
            <!-- news-item -->
        </div>
        <div v-else>
            Загрузка
        </div>
        <!-- news-wrapper -->
    </div>
    <!-- content-tab__panel -->
</template>

<script>
    import {FETCH_NEWS_ITEM} from "@/store/actions.type";
    import {mapGetters} from "vuex";

    export default {
        name: "NewsItem",
        mounted() {
            this.fetchNews()
        },
        computed: {
            ...mapGetters([
                "newsItem",
                "isNewsLoading"
            ]),
        },
        methods: {
            fetchNews() {
                this.$store.dispatch(FETCH_NEWS_ITEM, this.$route.params.id).then(() => {
                    document.title = unescape(this.newsItem.META_TITLE);
                }, this)
            },
        }
    }
</script>

<style scoped>

</style>