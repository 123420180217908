import {FaqService} from "@/common/api.service";

const state = {
  faqItem: {
    ID: "",
    UF_NUMBER: "",
    UF_NAME: "",
  },
  faqs: {
    counts: 0,
    items: []
  },
  isFaqLoading: true,
  isFaqLoadingError: false
};

const getters = {
  faqs(state) {
    return state.faqs;
  },
  isFaqLoading(state) {
    return state.isFaqLoading;
  },
  isFaqLoadingError(state) {
    return state.isFaqLoadingError;
  },
  faqItem(state) {
    return state.faqItem;
  }
};

const actions = {
  fetchFaqs({ commit }, params) {
    commit('fetchFaqStart');
    return FaqService.list(params.type, params)
      .then(({ data }) => {
        commit('fetchFaqsEnd', data);
      })
      .catch(error => {
        commit('fetchFaqError');
        throw new Error(error);
      });
  },
  fetchFaqItem({ commit }, slug) {
    commit('fetchFaqStart');
    return FaqService.item(slug)
        .then(({ data }) => {
          commit('fetchFaqItemEnd', data);
        })
        .catch(error => {
          commit('fetchFaqError');
          throw new Error(error);
        });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  fetchFaqStart(state) {
    state.isFaqLoading = true;
  },
  fetchFaqsEnd(state, faqs) {
    state.faqs = faqs;
    state.isFaqLoading = false;
  },
  fetchFaqError(state) {
    state.isFaqLoading = false;
    state.isFaqLoadingError = true;
  },
  fetchFaqItemEnd(state, item) {
    state.isFaqLoading = false;
    state.faqItem = item;
    return item;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
