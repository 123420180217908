<template>
  <div class="content-tab-wrapper">
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">№ доверенности</div>
      <div class="col-md-9 col-lg-8 col-data"><input type="text" class="main_input"
                                                     v-model="attorney.UF_PROCURATORY_NUMB"></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Дата выдачи доверенности</div>
      <div class="col-md-9 col-lg-8 col-data">
        <datepicker
            v-model="attorney.UF_PROCURATORY_DATE_CRT"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Предмет доверенности</div>
      <div class="col-md-9 col-lg-8 col-data"><textarea class="main_input"
                                                     v-model="attorney.UF_PROCURATORY_SUBJECT"/></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Дата начала действия доверенности</div>
      <div class="col-md-9 col-lg-8 col-data">
        <datepicker
            v-model="attorney.UF_PROCURATORY_ACTIVE_FROM"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Дата окончания действия доверенности</div>
      <div class="col-md-9 col-lg-8 col-data">
        <datepicker
            v-model="attorney.UF_PROCURATORY_ACTIVE_TO"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ru} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "ContractOrgAttorney",
  components: {Datepicker},
  props: {
    attorney: {
      type: Object
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', []);
  },
  data() {
    return {
      ru: ru
    }
  },
  computed: {
    ...mapGetters([
      "isConclusionsLoading",
      "directory"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    directoryVal(directory, id) {
      for (let i = 0; i < directory.length; i++) {
        if (directory[i].ID === id) {
          return directory[i]
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>

</style>