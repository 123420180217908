<template>
  <div>
    <notification :messages="messageNotification"/>
    <!-- Общая информация -->
    <div class="content-tab__panel tab_active">
      <form action="#" @submit.prevent="changeOrg">
        <div class="container-fluid">
          <div class="content-tab-wrapper">
            <div class="col-xs-12 status-accreditation">
              Статус организации на площадке:
              <span
                  v-show="accreditationAnswer"
                  v-if="accreditation"
                  class="status-accreditation__yes"
              >
                Аккредитована
              </span>
              <span
                  v-show="accreditationAnswer"
                  v-else
                  class="status-accreditation__no"
              >
                Не аккредитована
              </span>
            </div>
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="toggleBasicFacts" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: !showBasicFacts}"/>
                  Основные сведения
                </a>
              </div>
            </div>
            <div class="content-tab-indent" v-if="showBasicFacts">
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">
                  ИНН
                  <span class="required_color">*</span>
                </div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input
                      type="text"
                      class="main_input"
                      v-model="profileInfo.UF_INN"
                      disabled
                      :class="{invalid: !$v.profileInfo.UF_INN.required}"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">ОГРН<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input
                      type="text"
                      class="main_input"
                      v-model="profileInfo.UF_OGRN"
                      disabled
                      :class="{invalid: !$v.profileInfo.UF_OGRN.required}"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">
                  Полное наименование <br> (в соответствии с Уставом)<span class="required_color">*</span>
                </div>
                <div class="col-md-9 col-lg-10 col-data">
                <textarea class="main_textarea"
                          v-model="profileInfo.UF_FULL_NAME"
                          :class="{invalid: $v.profileInfo.UF_FULL_NAME.required && !$v.profileInfo.UF_FULL_NAME.required}">
                </textarea>
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_FULL_NAME.required && !$v.profileInfo.UF_FULL_NAME.required">Поле обязательно для заполнения</span>
                  <span v-if="serverErrors"
                        class="reg-danger reg-danger_auth"
                  >
                    {{ showServerError("UF_FULL_NAME") }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">
                  Краткое наименование<span class="required_color">*</span>
                </div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-model="profileInfo.UF_NAME"
                         :class="{invalid: $v.profileInfo.UF_NAME.$dirty && !$v.profileInfo.UF_NAME.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_NAME.$dirty && !$v.profileInfo.UF_NAME.required">Поле обязательно для заполнения</span>
                  <span v-if="serverErrors"
                        class="reg-danger reg-danger_auth"
                  >
                    {{ showServerError("UF_NAME") }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">
                  Дата внесения записи в ЕГРЮЛ<span class="required_color">*</span>
                </div>
                <div class="col-md-9 col-lg-10 col-data">
                  <datepicker
                      v-model="profileInfo.UF_DATE_EGRYUL"
                      :language="languages[language]"
                      :format="format"
                      input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
                      :class="{invalid: $v.profileInfo.UF_DATE_EGRYUL.$dirty && !$v.profileInfo.UF_DATE_EGRYUL.required}"
                  />
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_DATE_EGRYUL.$dirty && !$v.profileInfo.UF_DATE_EGRYUL.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_DATE_EGRYUL") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Дата государственной регистрации<span
                    class="required_color">*</span>
                </div>
                <div class="col-md-9 col-lg-10 col-data">
                  <datepicker
                      v-model="profileInfo.UF_DATE_GOS_REGISTR"
                      :language="languages[language]"
                      :format="format"
                      input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
                      :class="{invalid: $v.profileInfo.UF_DATE_GOS_REGISTR.$dirty && !$v.profileInfo.UF_DATE_GOS_REGISTR.required}"
                  ></datepicker>
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_DATE_GOS_REGISTR.$dirty && !$v.profileInfo.UF_DATE_GOS_REGISTR.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_DATE_GOS_REGISTR") }}</span>
                </div>

              </div>
              <div class="row" v-show="profileInfo.UF_IS_BROKER">
                <div class="col-md-3 col-lg-2 col-name">Брокер</div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="checkbox"
                         checked
                         class="main_check"
                         disabled>
                </div>
              </div>
            </div>
            <!-- content-tab-indent -->
          </div>

          <div class="content-tab-wrapper">
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="toggleAddressesPhones" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: !showAddressesPhones}"></i>
                  Адреса и телефоны
                </a>
              </div>
            </div>
            <div class="content-tab-indent" v-if="showAddressesPhones">
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Юридический<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                <textarea class="main_textarea"
                          v-model="profileInfo.UF_ADDRES_JUR"
                          :class="{invalid: $v.profileInfo.UF_ADDRES_JUR.$dirty && !$v.profileInfo.UF_ADDRES_JUR.required}">
                </textarea>
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_ADDRES_JUR.$dirty && !$v.profileInfo.UF_ADDRES_JUR.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_ADDRES_JUR") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Фактический<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                <textarea class="main_textarea"
                          v-model="profileInfo.UF_ADDRES_FACT"
                          :class="{invalid: $v.profileInfo.UF_ADDRES_FACT.$dirty && !$v.profileInfo.UF_ADDRES_FACT.required}">
                </textarea>
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_ADDRES_FACT.$dirty && !$v.profileInfo.UF_ADDRES_FACT.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_ADDRES_FACT") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Почтовый<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                <textarea class="main_textarea"
                          v-model="profileInfo.UF_ADDRES_POCHTA"
                          :class="{invalid: $v.profileInfo.UF_ADDRES_POCHTA.$dirty && !$v.profileInfo.UF_ADDRES_POCHTA.required}">
                </textarea>
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_ADDRES_POCHTA.$dirty && !$v.profileInfo.UF_ADDRES_POCHTA.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_ADDRES_POCHTA") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Телефоны<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <div class="row-tell" v-for="(phone, index) in profileInfo.UF_PHONES" :key="index">
                    <input type="text"
                           class="main_input phone__mask"
                           :name="phone"
                           v-mask="'+7(###) ###-##-##'"
                           placeholder="+7(___) ___-__-__"
                           v-model="profileInfo.UF_PHONES[index]"
                           :class="{invalid: ($v.profileInfo.UF_PHONES.$dirty && !$v.profileInfo.UF_PHONES.validPhone) || ($v.profileInfo.UF_PHONES.$dirty && !$v.profileInfo.UF_PHONES.required)}"/>
                    <button class="link-dell-tell button-consent"
                            v-if="index!==0"
                            @click.prevent="deletePhone(index)"
                    >
                    </button>
                  </div>
                  <button class="link-add-tell button-consent"
                          @click.prevent="addPhone"
                  >Добавить телефон
                  </button>
                  <div>
                    <span class="reg-danger reg-danger_auth"
                          v-if="$v.profileInfo.UF_PHONES.$dirty && !$v.profileInfo.UF_PHONES.validPhone">Номер должен состоять из цифр</span>
                    <span class="reg-danger reg-danger_auth"
                          v-else-if="$v.profileInfo.UF_PHONES.$dirty && !$v.profileInfo.UF_PHONES.required">Поле обязательно для заполнения</span>
                    <span class="reg-danger reg-danger_auth"
                          v-if="serverErrors">{{ showServerError("UF_PHONES") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- content-tab-indent -->
          </div>

          <div class="content-tab-wrapper">
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="toggleBankDetails" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: !showBankDetails}"></i>
                  Банковские реквизиты
                </a>
              </div>
            </div>
            <div class="content-tab-indent" v-if="showBankDetails">
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">БИК банка<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-mask="'#########'"
                         placeholder="_________"
                         v-model="profileInfo.UF_BANK_BIK"
                         :class="{invalid: $v.profileInfo.UF_BANK_BIK.$dirty && !$v.profileInfo.UF_BANK_BIK.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_BANK_BIK.$dirty && !$v.profileInfo.UF_BANK_BIK.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_BANK_BIK") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">ИНН банка<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-mask="'##########'"
                         placeholder="__________"
                         v-model="profileInfo.UF_BANK_INN"
                         :class="{invalid: $v.profileInfo.UF_BANK_INN.$dirty && !$v.profileInfo.UF_BANK_INN.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_BANK_INN.$dirty && !$v.profileInfo.UF_BANK_INN.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_BANK_INN") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">КПП банка<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-mask="'#########'"
                         placeholder="_________"
                         v-model="profileInfo.UF_BANK_KPP"
                         :class="{invalid: $v.profileInfo.UF_BANK_KPP.$dirty && !$v.profileInfo.UF_BANK_KPP.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_BANK_KPP.$dirty && !$v.profileInfo.UF_BANK_KPP.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_BANK_KPP") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Название банка<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-model="profileInfo.UF_BANK_NAME"
                         :class="{invalid: $v.profileInfo.UF_BANK_NAME.$dirty && !$v.profileInfo.UF_BANK_NAME.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_BANK_NAME.$dirty && !$v.profileInfo.UF_BANK_NAME.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_BANK_NAME") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Расчетный счет<span class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-mask="'407##810############'"
                         placeholder="407__810____________"
                         v-model="profileInfo.UF_RASH_SHET"
                         :class="{invalid: $v.profileInfo.UF_RASH_SHET.$dirty && !$v.profileInfo.UF_RASH_SHET.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_RASH_SHET.$dirty && !$v.profileInfo.UF_RASH_SHET.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_RASH_SHET") }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-name">Корреспондентский счет банка<span
                    class="required_color">*</span></div>
                <div class="col-md-9 col-lg-10 col-data">
                  <input type="text"
                         class="main_input"
                         v-mask="'####################'"
                         placeholder="____________________"
                         v-model="profileInfo.UF_BANK_KOR_SCHET"
                         :class="{invalid: $v.profileInfo.UF_BANK_KOR_SCHET.$dirty && !$v.profileInfo.UF_BANK_KOR_SCHET.required}">
                  <span class="reg-danger reg-danger_auth"
                        v-if="$v.profileInfo.UF_BANK_KOR_SCHET.$dirty && !$v.profileInfo.UF_BANK_KOR_SCHET.required">Поле обязательно для заполнения</span>
                  <span class="reg-danger reg-danger_auth"
                        v-if="serverErrors">{{ showServerError("UF_BANK_KOR_SCHET") }}</span>
                </div>
              </div>
            </div>
            <!-- content-tab-indent -->
          </div>

          <p class="required_color stl-pb_30">Все поля, отмеченные * - обязательны для заполнения</p>

          <div class="btn-box">
            <button type="submit" class="link_style-blue">сохранить</button>
            <button class="link_style-white" @click.prevent="cancelChanges">отмена</button>
          </div>
        </div>
      </form>
    </div>

    <!--ссылка на верх-->
    <a href="#" class="link_up" />
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import ApiService from "@/common/api.service";
import {required} from "vuelidate/lib/validators";
import Notification from "@/components/popup/Notification";

export default {
  name: "OrgProfile",
  components: {
    Datepicker,
    Notification,
  },
  metaInfo() {
    return {
      title: this.profileInfo.META_TITLE,
    }
  },
  data() {
    return {
      profileInfo: {},
      forReset: {},
      accreditation: false,
      accreditationAnswer: '',
      serverErrors: [],
      otherErrors: {},
      nameProfile: "Профиль организации",
      componentKey: 0,
      language: "ru",
      languages: lang,
      showBasicFacts: true,
      showAddressesPhones: true,
      showBankDetails: true,
      messageNotification: [],
    }
  },
  mounted() {
    ApiService.get3("orgs/getOrgProfileInfo/")
        .then(({data}) => {
          this.profileInfo = {...data};
          this.forReset = {...data};
          this.accreditationAnswer = data.UF_STATUS;
          this.accreditation = data.UF_STATUS === "Аккредитована";
          if (data.UF_PHONES.length === 0) {
            this.profileInfo.UF_PHONES.push('');
          }
        })
        .catch(error => this.otherErrors = error)
  },
  validations: {
    profileInfo: {
      UF_ADDRES_FACT: {required},
      UF_ADDRES_JUR: {required},
      UF_ADDRES_POCHTA: {required},
      UF_BANK_BIK: {required},
      UF_BANK_INN: {required},
      UF_BANK_KOR_SCHET: {required},
      UF_BANK_KPP: {required},
      UF_BANK_NAME: {required},
      UF_DATE_EGRYUL: {required},
      UF_DATE_GOS_REGISTR: {required},
      UF_FULL_NAME: {required},
      UF_INN: {required},
      UF_NAME: {required},
      UF_OGRN: {required},
      UF_PHONES: {
        required,
        validPhone: function (value) {
          return /(\+7)\(\d{3}\)\s*\d{3}-\d{2}-\d{2}/.test(value);
        }
      },
      UF_RASH_SHET: {required},
    }
  },
  methods: {
    format(value) {
      return moment(value).format('DD.MM.YYYY')
    },
    deletePhone(index) {
      this.profileInfo.UF_PHONES.splice(index, 1)
    },
    addPhone() {
      this.profileInfo.UF_PHONES.push("");
    },
    changeOrg() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let phoneArr = [];
      this.profileInfo.UF_PHONES.forEach(el => {
        if (/(\+7)\(\d{3}\)\s*\d{3}-\d{2}-\d{2}/.test(el)) {
          phoneArr.push(el);
        }
      })
      this.profileInfo.UF_PHONES = phoneArr;
      ApiService.post('orgs/setOrgProfileInfo/', this.profileInfo)
          .then(
              ({data}) => {
                if (data.error === true) {
                  this.serverErrors = data.errorAr;
                  return;
                }
                let timeStamp = Date.now().toLocaleString();
                this.messageNotification.unshift({
                  name: "Ваши изменения сохранены!",
                  id: timeStamp
                });
              })
          .catch(error => this.otherErrors = error)
    },
    showServerError(inputName) {
      let arrayError = this.serverErrors
          .filter((item) => {
            return (item["FIELD"] === inputName)
          })
          .map((item) => {
            return item["TEXT"];
          });
      return arrayError[0];
    },
    cancelChanges() {
      this.profileInfo = {...this.forReset};
    },
    toggleBasicFacts() {
      this.showBasicFacts = !this.showBasicFacts
    },
    toggleAddressesPhones() {
      this.showAddressesPhones = !this.showAddressesPhones
    },
    toggleBankDetails() {
      this.showBankDetails = !this.showBankDetails
    },
  },
}
</script>

<style scoped>

</style>
