<template>
    <div class="main-wraper">
        <Header/>
        <section class="content-area">
            <div class="content-indent">
                <Title title="Часто задаваемые вопросы"/>
                <FaqList/>
            </div>
            <!-- content-indent -->
        </section>
        <!-- content-area -->
        <Footer />
    </div>
</template>

<script>
    import FaqList from "@/components/site/FaqList";
    import Footer from "@/views/site/Footer";
    import Header from "@/views/site/Header";
    import Title from "@/views/partial/Title";
    export default {
        name: "Faq",
        components: {Title, Header, Footer, FaqList},
    }
</script>

<style scoped>

</style>