<template>
    <div class="main-wraper">
      <Header/>
      <section class="content-area">
        <MainMenu/>
        <RequestKp/>
      </section>
      <Footer/>
    </div>
</template>

<script>
import MainMenu from "@/components/cabinet/MainMenu";
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";
import RequestKp from "@/components/cabinet/kp/RequestKp";

export default {
  name: "RequestsKP",
  components: {MainMenu, RequestKp, Header, Footer},
}
</script>

<style scoped>

</style>