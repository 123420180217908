import Vue from 'vue'
import Vuex from 'vuex'

import news from "./news.module";
import methods from "./methodics.module";
import tariffs from "./tariffs.module";
import faq from "./faq.module";
import auth from "./auth.module";
import report from "./report.module";
import request from "./request.module";
import kp from "./kp.module";
import directory from "./directory.module";
import conclusion from "./conclusion.module";
import tz from "./tz.module";
import contract from "./contract.module"
import om from "./om.module"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showContactsModal: false,
        showTechSupportModal: false
    },
    mutations: {
        switchContactsModal(state) {
            state.showTechSupportModal = false
            state.showContactsModal = !state.showContactsModal
        },
        switchTechSupportModal(state) {
            state.showContactsModal = false
            state.showTechSupportModal = !state.showTechSupportModal
        }
    },
    actions: {},
    modules: {
        news, methods, tariffs, faq, auth, report, request, directory, conclusion, kp, tz, contract, om
    }
})
