<template>
    <div class="main-wraper">
        <Header/>
        <section class="content-area">
            <MainMenu/>
            <div class="content-indent-wrapper">
                <div class="content-indent">
                    <Title :title="'Реестр договоров'"></Title>
                    <List/>
                </div>
                <!-- content-indent -->
            </div>
            <!-- content-indent-wrapper -->
        </section>
        <!-- content-area -->
        <Footer />
    </div>
</template>

<script>
    import Footer from "@/views/site/Footer";
    import Header from "@/views/site/Header";
    import List from "@/components/cabinet/contract/ContractList";
    import MainMenu from "../../components/cabinet/MainMenu";
    import Title from "@/views/partial/Title";

    export default {
        name: "Contract",
        components: {Title, MainMenu, List, Header, Footer},
    }
</script>

<style scoped>

</style>
