<template>
  <div class="content-tab__panel tab_active">
    <div class="container-fluid">
      <div class="content-tab-wrapper">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-name col-name_bold">6. Общие выводы по результатам идентификации</div>
          <div class="col-md-12 col-lg-12 col-data">
            <div class="conclusion-form">
              <p v-for="item in directory.conclusion_indent_finalies" :key="item.ID">
                <input :disabled="!conclusionItem.UF_IS_EDIT" name="conclusion"
                       v-model="conclusionItem.UF_CONCLUSION_ID"
                       :value="item.ID"
                       class="stl-versub inp-radio"
                       checked=""
                       type="radio"> <span v-html="item.UF_NAME"></span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- container-fluid -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Output",
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "conclusion_indent_finalies"
    ]);
  },
  computed: {
    ...mapGetters([
      "conclusionItem",
      "isConclusionsLoading",
      "directory"
    ]),
  },
}
</script>

<style scoped>

</style>