<template>
  <div class="modal fade in show" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-middle" role="document">
      <div class="modal-content">
        <div class="modal-add-document">
          <div class="modal-header main-clear">
            <span class="modal-title">{{data.NAME}}</span>
            <a href="#" class="modal-exit stl-FR" @click="$store.commit('switchTechSupportModal')"/>
          </div>
          <div class="modal-body">
            <div class="modal_row main-clear modal_row_text" v-html="data.PREVIEW_TEXT"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {NewsService} from "@/common/api.service";

  export default {
    name: "TechSupport",
    data() {
      return {
        data: {
          NAME: "",
          PREVIEW_TEXT: ""
        }
      }
    },
    mounted() {
      NewsService.spRequestSupport().then(
        ({data}) => {
          this.data = data
        }
      ), this
    }
  }
</script>

<style scoped>

</style>
