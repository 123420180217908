<template>
  <div class="content-tab__panel tab_active">
    <div class="container-fluid">
      <div class="content-tab-wrapper">
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">
            Внешнеэкономическая операция
            <span class="required_color">*</span>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <div class="checked_row main-inlineBlock stl-pb_10">
              <select :disabled="!kpItem.UF_IS_EDIT && !isACopy" v-model="kpItem.UF_VEO_ID"
                      class="main_input main_select main-inlineBlock">
                <option v-for="item in directory.veo" :key="item.ID" :value="item.ID">{{ item.UF_NAME }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Страна(ы) назначения<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data col-data-select"
               v-if="kpItem && directory.country">
            <Multiselect
                @input="setCountry"
                :disabled="!kpItem.UF_IS_EDIT && !isACopy"
                label="UF_NAME"
                track-by="ID"
                v-model="opCountry"
                :options="directory.country"
                placeholder=""
                :multiple="true"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Предмет внешнеэкономической операции<span
              class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data">
            <textarea class="main_textarea" :disabled="!kpItem.UF_IS_EDIT && !isACopy" v-model="kpItem.UF_VEO_SUBJECT"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Максимальное количество кодов ТН ВЭД ЕАЭС<span class="required_color">*</span>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="text" class="main_input"
                   :disabled="!kpItem.UF_IS_EDIT && !isACopy"
                   v-model="kpItem.UF_MAX_CNT_TN_VED">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Желательный срок получения заключения (рабочие дни)</div>
          <div class="col-md-9 col-lg-10 col-data">
            min <input type="text"
                       class="main_input input_calendar-inline input_small-size"
                       v-model="kpItem.UF_MIN_DAY_CONCLUSIONS"
                       :disabled="!kpItem.UF_IS_EDIT && !isACopy"
          >
            max <input type="text"
                       class="main_input input_calendar-inline input_small-size"
                       v-model="kpItem.UF_MAX_DAY_CONCLUSIONS"
                       :disabled="!kpItem.UF_IS_EDIT && !isACopy"
          >
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Экспертная организация<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data">
            <p class="checked_row"
               v-for="item in directory.expert_org"
               :key="item.ID">
              <input type="checkbox"
                     class="main_check"
                     name="expertOrg"
                     :disabled="!kpItem.UF_IS_EDIT && !isACopy"
                     :id="item.ID"
                     :value="item.ID"
                     :checked="kpItem.KP_ORG_REPLY.filter(el => el.UF_COMPANY_ID === item.ID).length > 0"
                     @input="setExpertOrg">
              <label :for="item.ID">{{ item.UF_NAME }}</label>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Своя форма договора</div>
          <div class="col-md-9 col-lg-10 col-data">
            <input type="checkbox" class="main_check"
                   v-model="kpItem.UF_IS_OWN_FORM_DGR"
                   :disabled="!kpItem.UF_IS_EDIT && !isACopy">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Дата ответа до<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data">
            <datepicker
                :disabled="!kpItem.UF_IS_EDIT && !isACopy"
                v-model="kpItem.UF_DATE_REPLY_PLAN"
                :language="ru"
                :format="dateFormat"
                input-class="main_input small_calendar"
            ></datepicker>
          </div>
        </div>
      </div>
    </div>
    <p class="required_color stl-pb_30">Все поля, отмеченные * - обязательны для заполнения</p>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";

export default {
  name: "ReqKP",
  data() {
    return {
      ru: ru,
      showMembers: true,
      showDocs: true,
      opCountry: [],
      opExpOrg: [],
      isACopy: false,
    };
  },
  components: {Multiselect, Datepicker},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country",
    ]);
    this.opCountry = [];
    this.opExpOrg = [];
    if (this.kpItem.UF_COUNTRY_ID && !!this.kpItem.UF_COUNTRY_ID.length) {
      for (let i = 0; i < this.kpItem.UF_COUNTRY_ID.length; i++) {
        let v = {
          ID: this.kpItem.UF_COUNTRY_ID[i]
        }
        for (let d = 0; d < this.directory.country.length; d++) {
          if (this.directory.country[d].ID === this.kpItem.UF_COUNTRY_ID[i]) {
            v.UF_NAME = this.directory.country[d].UF_NAME
          }
        }
        this.opCountry.push(v)
      }
      for (let i = 0; i < this.kpItem.KP_ORG_REPLY.length; i++) {
        let v = {
          ID: this.kpItem.KP_ORG_REPLY[i].ID
        }
        for (let d = 0; d < this.directory.expert_org.length; d++) {
          if (this.directory.expert_org[d].ID === this.kpItem.KP_ORG_REPLY[i].ID) {
            v.UF_NAME = this.directory.expert_org[d].UF_NAME
          }
        }
        this.opExpOrg.push(v)
      }
    }
    this.isACopy = this.$route.path.includes('copy');
  },
  computed: {
    ...mapGetters([
      "directory",
      "kpItem",
      "isKPLoading",
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    setCountry() {
      this.kpItem.UF_COUNTRY_ID = [];
      for (let i = 0; i < this.opCountry.length; i++) {
        this.kpItem.UF_COUNTRY_ID.push(this.opCountry[i].ID)
      }
    },
    setExpertOrg() {
      this.kpItem.KP_ORG_REPLY = [];
      let expert = document.getElementsByName('expertOrg');
      for (let i = 0; i < expert.length; i++) {
        if (expert[i].checked) {
          this.kpItem.KP_ORG_REPLY.push(expert[i].value);
        }
      }
    }
  }
}
</script>
