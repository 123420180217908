import {RequestService} from "@/common/api.service";

const state = {
    requests: {
        counts: 0,
        items: []
    },
    requestItem: {},
    isRequestsLoading: true,
    isRequestLoadingError: false
};

const getters = {
    requests(state) {
        return state.requests;
    },
    requestItem(state) {
        return state.requestItem;
    },
    isRequestsLoading(state) {
        return state.isRequestsLoading;
    },
    isRequestsLoadingError(state) {
        return state.isRequestLoadingError;
    }
};

const actions = {
    fetchRequests({commit}, params) {
        commit("fetchRequestStart");
        return RequestService.list(params.type, params)
            .then(({data}) => {
                commit('fetchRequestEnd', data);
            })
            .catch(error => {
                commit('fetchRequestError');
                throw new Error(error);
            });
    },
    fetchRequestItem({commit}, slug) {
        commit('fetchRequestStart');
        if (slug) {
            return RequestService.item(slug)
                .then(({data}) => {
                    commit('fetchRequestItemEnd', data);
                })
                .catch(error => {
                    commit('fetchRequestError');
                    throw new Error(error);
                });
        } else {
            return RequestService.loadTemplate()
                .then(({data}) => {
                    commit('fetchRequestItemEnd', data);
                })
                .catch(error => {
                    commit('fetchRequestError');
                    throw new Error(error);
                });
        }
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    ['fetchRequestStart'](state) {
        state.isRequestsLoading = true;
    },
    ['fetchRequestEnd'](state, news) {
        state.requests = news;
        state.isRequestsLoading = false;
    },
    ['fetchRequestError'](state) {
        state.isRequestsLoading = false;
        state.isRequestsLoadingError = true;
    },
    ['fetchRequestItemEnd'](state, item) {
        state.isRequestsLoading = false;
        state.requestItem = item;
        return item;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
