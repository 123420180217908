<template>
  <div>
    <div class="content-tab__panel tab_active">
      <form action="#">
        <div class="container-fluid">
          <div class="content-tab-wrapper">
            <!--      Шапка под меню со статусом      -->
            <div class="col-xs-12 status-accreditation">
              Статус организации на площадке:
              <span
                  v-show="accreditationAnswer"
                  v-if="accreditation"
                  class="status-accreditation__yes">
                Аккредитована
              </span>
              <span
                  v-show="accreditationAnswer"
                  v-else
                  class="status-accreditation__no">
                Не аккредитована
              </span>
            </div>

            <!--      Документы для аккредитации      -->
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="toggleDocs" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: !showDocs}"></i>
                  Документы для аккредитации
                </a>
              </div>
            </div>

            <!--      Список всех документов      -->
            <div class="content-tab-indent">
              <div class="row">
                <div class="col-md-12 col-name">
                  <div class="stl-pb_10">
                    <span class="i-doc-desc">
                      <i class="i-doc i-doc_y stl-vermid"></i>
                      — документ принят
                    </span>
                    <span class="i-doc-desc">
                      <i class="i-doc i-doc_n stl-vermid"></i>
                      — документ отклонен
                    </span>
                    <span class="i-doc-desc">
                      <i class="i-doc i-doc_np stl-vermid"></i>
                      — документ не проверен
                    </span>
                  </div>
                  <div class="content-tab-wrapper" :key="item.ID" v-for="item in directory.vid_documents_accreditation">
                    <div class="row row_link_title">
                      <div class="col-md-12">
                        <a @click="toggleVid(item)" class="link_title d-inline-block">
                          <i class="i_arrow" :class="{i_arrow_hidden: item.hide}"/>
                          {{ item.UF_NAME }}
                        </a>
                        <div v-if="item.UF_DESC" class="d-inline-block"> ({{ item.UF_DESC }})</div>
                      </div>
                    </div>

                    <!--          Документы аккредитации с ЭЦП            -->
                    <div class="content-tab-indent" v-if="!item.hide">
                      <div v-if="docs && ORG_ACCR.DOCUMENTES[item.ID]">
                        <div v-for="doc in ORG_ACCR.DOCUMENTES[item.ID]"
                             :key="doc.ID"
                             class="col-md-12 col-name"
                        >
                          <div class="stl-pb_10" v-if="doc.UF_FILE_BASE_NAME">
                            <span class="versia-box_doc">
                              <a :href="docUrl(doc)"
                                 :class="{'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                          'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                          'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                          'link-doc-y': doc.UF_STATUS_DOC_ID === 4,}"
                              >
                                {{ doc.UF_FILE_BASE_NAME }}
                              </a>
                            </span>
                            <span v-if="doc.UF_IS_SIGN_ECP"
                                  v-tooltip="{ content: new Object(() => ecpInfo(doc)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }"
                            >
                              <span class="e-signature">эцп</span>
                              <span
                                  class="e-signature_data">{{ doc.UF_SIGN_ECP_DATE | dateFormat }}</span>
                            </span>
                          </div>
                          <div class="">
                            <div class="col-name" v-if="doc.UF_COMMENT">
                              Комментарий: {{ doc.UF_COMMENT }}<br/><br/>
                            </div>
                            <div v-if="doc.UF_DATE_ADD_NEW_VERSION" class="col-name">
                              Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                            </div>
                            <div class="link_style-white"
                                 v-if="(!accreditation || ORG_ACCR.UF_IS_REPEAT_ACCREDITATION==1) && ORG_ACCR.DOCUMENTES[item.ID]"
                                 @click="showUploadModal(item, doc.ID, doc.UF_NAME)">
                              обновить документ
                            </div>
                          </div>
                          <div class="col-name" v-if="doc.UF_DATE_ADD_NEW_VERSION">
                            Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                          </div>
                          <div class="content-tab-wrapper" v-if="doc.VERSION_LIST.length > 0">
                            <div class="row row_link_title">
                              <div class="col-md-12">
                                <a @click="toggleVid(doc)"
                                   class="link_title link_suptitle">
                                  <i class="i_arrow" :class="{i_arrow_hidden: !doc.hide}"></i>
                                  Предыдущие версии
                                </a>
                              </div>
                            </div>
                            <div v-if="doc.hide">
                              <div class="content-tab-indent versia-tab-indent"
                                   v-for="dh in doc.VERSION_LIST"
                                   :key="dh.ID">
                                <div class="row">
                                  <div class="col-md-12 col-name">
                                    Название: {{ dh.UF_NAME }}
                                  </div>
                                  <div class="col-md-12 col-name">
                                    <span class="versia-box_doc">
                                      <a :href="docUrl(doc)"
                                         :class="{'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                  'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                  'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                  'link-doc-y': doc.UF_STATUS_DOC_ID === 4,}"
                                      >
                                        {{ dh.UF_FILE_BASE_NAME }}
                                      </a>
                                      <span class="link-doc_required">{{ dh.UF_COMMENT }}</span>
                                    </span>
                                    <span v-if="doc.UF_IS_SIGN_ECP"
                                          v-tooltip="{ content: new Object(() => ecpInfo(dh)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }"
                                    >
                                      <span v-if="dh.UF_IS_SIGN_ECP" class="e-signature">эцп</span>
                                      <span v-if="dh.UF_IS_SIGN_ECP" class="e-signature_data">
                                        {{ dh.UF_SIGN_ECP_DATE | dateFormat }}
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-name" v-if="dh.UF_COMMENT">
                                    Комментарий: {{ dh.UF_COMMENT }}<br/><br/>
                                  </div>
                                  <div class="col-name" v-if="dh.UF_DATE_ADD_NEW_VERSION">
                                    Срок предоставления новой версии: {{ dh.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="stl-mt_10" v-if="!item.hide && docs && !ORG_ACCR.DOCUMENTES[item.ID]">
                      <div class="link_style-blue" @click="showUploadModal(item, 0)">
                        добавить документ
                      </div>
                    </div>

                    <!--  Модальное окно добавления документа  -->
                    <div class="modal fade in show"
                         v-if="uploadModalIsShowed(item)"
                         tabindex="-1"
                         role="dialog"
                         aria-labelledby="myModalLabel">
                      <div class="modal-dialog "
                           style="width: 490px"
                           role="document">
                        <div class="modal-content">
                          <div class="modal-add-document"
                               style="z-index: 10000;width: 490px">
                            <div class="modal-header main-clear">
                              <span class="modal-title">
                                Добавление документа
                              </span>
                              <a @click="closeUploadModal(item)"
                                 class="modal-exit stl-FR">
                              </a>
                            </div>
                            <div class="modal-body" v-show="!uploadCard.showECP">
                              <div class="modal_row main-clear">
                                <span class="modal-field_name">Название</span>
                                <div v-if="uploadCard.ID>0">
                                  {{ uploadCard.OLD_NAME }}
                                </div>
                                <span>
                                  <input v-model="uploadCard.UF_NAME"
                                         style="width: 465px"
                                         class="main_input"
                                         :disabled="uploadCard.blockName"
                                  />
                                </span>
                              </div>
                              <div class="modal_row main-clear">
                                <div class="modal_upload-form" v-if="uploadCard.accept">
                                  <label>
                                    <input :accept="uploadCard.accept"
                                           name="modal-file"
                                           @change="onChangeFile"
                                           type="file"
                                           class="modal_i_file">
                                    <span class="modal_link_add">выбрать файл</span>
                                    <input name="modal-i-file_text"
                                           type="text"
                                           class="modal_i_file_text"
                                           :value="uploadCard.UP_NAME"
                                           disabled>
                                  </label>
                                </div>
                              </div>
                              <div class="modal_row main-clear">
                                <button class="link_style-blue modal_btn_wid" style="width: 30%"
                                        type="button"
                                        @click="uploadDocument(item)"
                                        v-if="uploadCard.UF_FILE && uploadCard.UF_NAME">
                                  сохранить
                                </button>
                                <button class="link_style-blue modal_btn_wid" style="width: 30%"
                                        type="button"
                                        @click="startECP"
                                        v-if="uploadCard.UF_FILE && uploadCard.UF_NAME">
                                  подписать ЭЦП
                                </button>
                                <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                                     @click="closeUploadModal(item)">
                                  отмена
                                </div>
                              </div>
                            </div>
                            <div class="modal-body" v-show="uploadCard.showECP">
                              <div v-if="!ecpPluginEnabled" style="color: red;">
                                Плагин не активен
                              </div>
                              <div v-else style="color: green;">
                                Плагин загружен.
                              </div>
                              <br/>
                              <div class="modal_row main-clear">
                                <span class="modal-field_name">Укажите сертификат подписи</span>
                                <span>
                                         <select style="width: 465px" v-model="ecpCert" class="main_input main_select">
                                           <option :key="cert.text" v-for="cert in ecpCerts" :value="cert"
                                           >{{ cert.text }}
                                        </option>
                                        </select>
                                    </span>
                              </div>

                              <div class="modal_row main-clear">
                                <button class="link_style-blue modal_btn_wid" style="width: 46%"
                                        :disabled="ecpCert === null || isSignStarted"
                                        @click="Common_SignCadesBES_File(item)"
                                >
                                  подписать и сохранить
                                </button>
                                <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                                     @click="closeUploadModal(item)">
                                  отмена
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--      Таблица со статусом организации      -->
          <div class="content-tab__panel tab_active">
            <div class="row">
              <div class="col-md-12 col-name">
                <div class="table-area t-min">
                  <table class="table_style" v-if="ORG_ACCR.HISTORY_STATUS">
                    <thead>
                    <tr class="">
                      <th>Статус</th>
                      <th>Дата начала действия</th>
                      <th>Дата окончания действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(el, idx) of ORG_ACCR.HISTORY_STATUS" :key="idx">
                      <td data-label="Статус">
                        <span v-if="el.UF_STATUS_ACCREDITATION">
                          Аккредитована
                        </span>
                        <span v-else>
                          Не аккредитована
                        </span>
                      </td>
                      <td data-label="Начало">
                        {{ el.UF_START_DATE_STATUS || " " }}
                      </td>
                      <td data-label="Окончание">
                        {{ el.UF_FINISH_DATE_STATUS || " " }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!--      Кнопки изменения статуса аккредитации      -->
          <div class="btn-box">
            <button v-if="isNewAccreditation"
                    type="button"
                    class="link_style-blue"
                    @click="sendAccreditation"
            >
              отправить на аккредитацию
            </button>
            <button v-if="isRepeatAccreditation"
                    type="button"
                    class="link_style-blue"
                    @click="sendAccreditation"
            >
              продлить аккредитацию
            </button>
          </div>
        </div>
      </form>
    </div>

    <a href="#" class="link_up"/>
  </div>
</template>

<script>
/*global cadesplugin*/
import moment from 'moment';
import ApiService, {RequestService} from "@/common/api.service";
import {ru} from "vuejs-datepicker/dist/locale";
import {mapGetters} from "vuex";
import {API_URL} from "@/common/config";
import Vue from "vue";
import "@/scripts/cadesplugin_api";

export default {
  name: "OrgAccreditation",
  components: {},
  data() {
    return {
      ORG_ACCR: {},
      accreditation: false,
      accreditationAnswer: '',
      ecpCert: "",
      ecpCerts: [],
      ecpValue: "",
      ecpPluginEnabled: false,
      showDocs: true,
      isSignStarted: false,
      uploadCard: {
        UF_VID_DOC_REQUEST: 0,
        UF_FORM_DOC_REQ_ID: 1,
        ID: 0,
        UF_NAME: "",
        UF_FILE: "",
        blockName: false,
        showECP: false,
        file: ""
      },
      org: {},
      statusCard: {
        ID: 0,
        UF_STATUS_DOC_ID: 0,
        UF_DATE_ADD_NEW_VERSION: ""
      },
      toasterErrors: [],
      serverErrors: [],
      otherErrors: {},
      ru: ru,
    }
  },
  async mounted() {
    await this.fetchInfo();
  },

  computed: {
    ...mapGetters([
      "directory",
      "currentUser"
    ]),
    docs() {
      return !!this.ORG_ACCR && !!this.ORG_ACCR.DOCUMENTES;
    },
    isRepeatAccreditation() {
      return !this.accreditation && this.ORG_ACCR.UF_IS_REPEAT_ACCREDITATION === 1 ||
          this.accreditation && this.ORG_ACCR.UF_IS_REPEAT_ACCREDITATION === 1;
    },
    isNewAccreditation() {
      return !this.accreditation && this.ORG_ACCR.UF_IS_REPEAT_ACCREDITATION === 0;
    },
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },

  methods: {
    // Загрузка информации для компонента
    async fetchInfo() {
      ApiService.get3("orgs/getOrgProfileInfo/")
          .then(({data}) => {
            this.org = data;
            this.accreditationAnswer = data.UF_STATUS;
            this.accreditation = data.UF_STATUS === "Аккредитована";
          })
      ApiService.get3("accreditation/getAccreditationCompany/")
          .then(({data}) => {
            document.title = unescape(data.META_TITLE);
            this.ORG_ACCR = data;
          })
      await this.$store.dispatch('fetchDirectories', [
        "vid_documents_accreditation",
      ]);
      if (this.directory.vid_documents_accreditation.length !== 0) {
        this.directory.vid_documents_accreditation.forEach(function (item) {
          Vue.set(item, 'showUploadModal', false)
        })
      }
    },

    // Условие показа модалки добавления документа
    uploadModalIsShowed(item) {
      return item.showUploadModal
    },

    // Открытие модального окна и установка начальных параметров документа
    showUploadModal(item, doc, oldName) {
      this.uploadCard.accept = "." + item.UF_FORMAT_TYPE.replace(/;/g, ",.")
      this.uploadCard.UF_FILE = ""
      this.uploadCard.UF_NAME = oldName
      this.uploadCard.OLD_NAME = oldName
      this.uploadCard.ID = doc
      this.uploadCard.showECP = false
      this.ecpValue = ""
      Vue.set(item, 'showUploadModal', true)
    },

    // Закрытие модального окна
    closeUploadModal(item) {
      Vue.set(item, 'showUploadModal', false)
    },

    // Функция добавления/изменения файла
    onChangeFile(e) {
      let files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + " kB",
            base64: reader.result,
            file: file
          };
          let header = ";base64,";
          let fileData = reader.result;
          Vue.set(this.uploadCard, 'UF_NAME', fileInfo.name);
          Vue.set(this.uploadCard, 'UF_FILE', fileData.substr(
              fileData.indexOf(header) + header.length
          ));
          this.uploadCard.file = file;
        };
      }
    },

    // Добавление/изменение файла в системе
    uploadDocument(item) {
      let formData = new FormData();
      formData.append("UF_FILE", this.uploadCard.file);
      formData.append("UF_NAME", this.uploadCard.UF_NAME);
      formData.append("UF_VID_DOC_ACCREDITATION", `${item.ID}`);
      formData.append("UF_FILE_SIGN_ECP", this.ecpValue);
      formData.append("UF_FILE_SIGN_AUTHOR", this.ecpCert.text);
      if (this.uploadCard.ID > 0) {
        ApiService.post(`/accreditation/documents/editDocument/${this.ORG_ACCR.ID}/${this.uploadCard.ID}/`, formData).then(({data}) => {
          if (!data.error) {
            this.closeUploadModal(item);
            this.successProcess('Файл успешно изменен');
            this.fetchInfo();
          } else {
            data.errorAr.forEach(function (err) {
              this.errorProcess(err.TEXT);
            }, this);
            this.closeUploadModal(item);
          }
        }, this)
      } else {
        ApiService.post(`/accreditation/documents/addDocument/${this.ORG_ACCR.ID}/`, formData).then(({data}) => {
          if (!data.error) {
            this.closeUploadModal(item);
            this.successProcess('Файл успешно добавлен');
            this.fetchInfo();
          } else {
            data.errorAr.forEach(function (err) {
              this.errorProcess(err.TEXT);
            }, this);
            this.closeUploadModal(item);
          }
        }, this)
      }
    },

    // Функция отправки/продления аккредитации компании
    sendAccreditation() {
      const accreditationIdData = {
        id: this.ORG_ACCR.UF_COMPANY_ID
      }
      ApiService.post(`/accreditation/sendAccreditationCompany/`, accreditationIdData).then(({data}) => {
        if (!data.error) {
          this.successProcess('Запрос успешно отправлен');
          this.fetchInfo();
        } else {
          data.errorAr.forEach(function (err) {
            this.errorProcess(err.TEXT);
          }, this);
        }
      }, this)
          .catch(error => {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                `Ошибка системы: ${error}`,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          });
    },

    toggleDocs() {
      this.showDocs = !this.showDocs
    },

    toggleVid(item) {
      Vue.set(item, 'hide', !item.hide)
    },

    docUrl(object) {
      return API_URL.replace(new RegExp("[\\/]+$"), "") + object.UF_FILE;
    },

    // Получение информации о файле по ЭЦП
    async ecpInfo(doc) {
      return await RequestService.getECPInfoFile(doc.ID).then(({data}) => {
        return `<div class="modal-content">
<div class="text-center"><span class="modal-title">Документ подписан <br> электронной
подписью</span></div>
<div class="modal-body">
<div class="modal_row modal_flex">
<span class="modal-field_name">Сертификат</span>
<span>${data.result.SERTIFICATE_NUMBER}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Организация</span>
<span>${data.result.COMPANY}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Владелец</span>
<span>${data.result.OWNER_FIO}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Должность</span>
<span>${data.result.POSITION}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Действителен</span>
<span>${data.result.DATE_USE_SERTIFICATE}</span>
</div>
</div>
</div>`
      }).catch(() => 'ERROR')
    },

    //  Старт ЭЦП
    startECP() {
      this.uploadCard.showECP = true;
      this.isSignStarted = false;
      this.Common_CheckForPlugIn();
    },

    //  ЭЦП
    Common_CheckForPlugIn() {
      cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_DEBUG);
      let canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        return this.CheckForPlugIn_Async();
      } else {
        //return CheckForPlugIn_NPAPI();
      }
    },

    CheckForPlugIn_Async() {
      this.ecpPluginEnabled = true;
      let _this = this;
      cadesplugin.async_spawn(function* () {
        //let oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
        yield _this.FillCertList_Async();
      });
    },

    FillCertList_Async() {
      let _this = this;
      cadesplugin.async_spawn(function* () {
        _this.ecpCerts = []
        let oStore;
        try {
          oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          if (!oStore) {
            alert("Ошибка чтения сертификатов ЭЦП");
            return;
          }

          yield oStore.Open();
        } catch (ex) {
          alert("Ошибка чтения сертификатов ЭЦП");
        }

        let CertificatesObj = yield oStore.Certificates;

        let certCnt = yield CertificatesObj.Count;
        if (certCnt === 0) {
          //throw "Сертификаты не найдены";
        }
        try {
          yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          let certs = yield oStore.Certificates;
          let certCnt = yield certs.Count;
          for (let i = 1; i <= certCnt; i++) {
            let cert = yield certs.Item(i);

            let oOpt = {
              text: "",
              value: ""
            };
            oOpt.text = yield cert.SubjectName;
            oOpt.value = yield cert.Thumbprint;
            _this.ecpCerts.push(oOpt);
          }
          yield oStore.Close();
        } catch (ex) {
          console.log(ex);
        }
        console.log(_this.ecpCerts)
      });
    },

    Common_SignCadesBES_File(item) {
      let canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        return this.SignCadesBES_Async_File(item);
      } else {
        //return SignCadesBES_NPAPI_File(id);
      }
    },

    SignCadesBES_Async_File(item) {
      let _this = this;
      this.isSignStarted = true
      cadesplugin.async_spawn(function* () {
        try {
          //FillCertInfo_Async(certificate);
          let oSigner;
          let errormes = "";
          try {
            oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          } catch (err) {
            errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
            throw errormes;
          }
          let oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );

          let CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
          yield oSigningTimeAttr.propset_Name(
              CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
          );
          let oTimeNow = new Date();
          yield oSigningTimeAttr.propset_Value(oTimeNow);
          let attr = yield oSigner.AuthenticatedAttributes2;
          yield attr.Add(oSigningTimeAttr);

          let oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );
          let CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
          yield oDocumentNameAttr.propset_Name(
              CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
          );
          yield oDocumentNameAttr.propset_Value("Document Name");
          yield attr.Add(oDocumentNameAttr);

          let oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          try {
            oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          } catch (e) {
            alert("Ошибка при открытии хранилища");
            console.log(e);
            return;
          }

          let CertificatesObj = yield oStore.Certificates;
          let CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
          let oCerts = yield CertificatesObj.Find(
              CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
              _this.ecpCert.value
          );

          if (oCerts.Count == 0) {
            errormes = "Certs not found";
            throw errormes;
          }

          let oCert = yield oCerts.Item(1);
          let sName = yield oCert.SubjectName;

          if (oSigner) {
            yield oSigner.propset_Certificate(oCert);
          } else {
            errormes = "Failed to create CAdESCOM.CPSigner";
            throw errormes;
          }

          let oSignedData = yield cadesplugin.CreateObjectAsync(
              "CAdESCOM.CadesSignedData"
          );
          let CADES_BES = 1;
          let dataToSign = _this.uploadCard.UF_FILE;
          if (dataToSign) {
            yield oSignedData.propset_ContentEncoding(1); //CADESCOM_BASE64_TO_BINARY
            yield oSignedData.propset_Content(dataToSign);
            yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN

            _this.ecpValue = yield oSignedData.SignCades(oSigner, CADES_BES);
            _this.showECP = false;
            _this.uploadDocument(item)
            let sn = sName.split(",");
            sn.forEach(function (v) {
              let r = v.replace("CN=", "");
              if (r.length !== v.length) {
                _this.ecpEnsurer = r.trim();
              }
            });

          }
          //alert(Signature)
        } catch (err) {
          console.log("sign err", err);
        }
      });
    },

    // Вывод сообщения об успешности операции
    successProcess(text) {
      this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
          text,
          {
            theme: "green",
            position: "bottom-right",
            duration: null,
            action: [
              {
                text: "Закрыть",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }
              },
              {
                text: "Закрыть все",
                onClick: () => {
                  this.toasterErrors.forEach(function (err) {
                    err.goAway(0);
                  });
                }
              }
            ]
          }
      );
    },

    // Вывод сообщения об ошибке
    errorProcess(text) {
      this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
          text,
          {
            theme: "bubble",
            position: "bottom-right",
            duration: null,
            action: [
              {
                text: "Закрыть",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }
              },
              {
                text: "Закрыть все",
                onClick: () => {
                  this.toasterErrors.forEach(function (err) {
                    err.goAway(0);
                  });
                }
              }
            ]
          }
      );
    },
  },
}
</script>