<template>
    <div class="auth-area__form">
        <h3 class="h3-title">{{mp.NAME}}</h3>
        <p class="auth-text" v-html="mp.PREVIEW_TEXT"></p>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {FETCH_MP} from "@/store/actions.type";

    export default {
        name: 'Welcome',
        mounted() {
            this.fetchMP()
        },
        computed: {
            ...mapGetters([
                "mp",
                "isNewsLoading"
            ]),
        },
        methods: {
            fetchMP() {
                this.$store.dispatch(FETCH_MP).then(() => {
                    document.title = unescape(this.mp.META_TITLE);
                }, this);
            },
        }
    }
</script>

