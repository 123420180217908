import {ReportService} from "@/common/api.service";

const state = {
  reports: {
    counts: 0,
    items: []
  },
  reportItem: {},
  isReportsLoading: true,
  isReportLoadingError: false
};

const getters = {
  reports(state) {
    return state.reports;
  },
  reportItem(state) {
    return state.reportItem
  },
  isReportsLoading(state) {
    return state.isReportsLoading;
  },
  isReportsReportError(state) {
    return state.isReportsReportError;
  }
};

const actions = {
  fetchReports({ commit }, params) {
    commit("fetchReportStart");
    return ReportService.list(params.type, params)
      .then(({ data }) => {
        commit('fetchReportEnd', data);
      })
      .catch(error => {
        commit('fetchReportError');
        throw new Error(error);
      });
  },
  fetchReportItem({commit}, slug) {
    commit('fetchReportItemStart');
    return ReportService.item(slug)
        .then(({data}) => {
          commit('fetchReportItemEnd', data);
        })
        .catch(error => {
          commit('fetchReportItemError');
          throw new Error(error);
        });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  ['fetchReportStart'](state) {
    state.isReportsLoading = true;
  },
  ['fetchReportEnd'](state, news) {
    state.reports = news;
    state.isReportsLoading = false;
  },
  ['fetchReportError'](state) {
    state.isReportsLoading = false;
    state.isReportsLoadingError = true;
  },
  ['fetchReportItemStart'](state) {
    state.isReportsLoading = true;
  },
  ['fetchReportItemEnd'](state, news) {
    state.reportItem = news;
    state.isReportsLoading = false;
  },
  ['fetchReportItemError'](state) {
    state.isReportsLoading = false;
    state.isReportsLoadingError = true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
