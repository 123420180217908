<template>
  <div v-if="!isSetNewPass">
    <div class="auth-area__form" v-if="isTokenCheck && isGoodToken">
      <h3 class="form-title">Изменение пароля</h3>
      <form action="#" class="form-auth-login" @submit.prevent="changePassword">
        <div class="auth-field">
          <p><label for="reg-pass" class="auth_label">Пароль</label></p>
          <input type="password"
                 name="reg-pass"
                 autocomplete="new-password"
                 class="auth_input" value=""
                 v-model.trim="recoveryData.USER_PASSWORD"
                 @keydown.space.prevent
                 :class="{invalid: ($v.recoveryData.USER_PASSWORD.$dirty && !$v.recoveryData.USER_PASSWORD.required) || ($v.recoveryData.USER_PASSWORD.$dirty && !$v.recoveryData.USER_PASSWORD.minLength) || ($v.recoveryData.USER_PASSWORD.$dirty && !$v.recoveryData.USER_PASSWORD.valid)}"/>
          <span class="reg-danger reg-danger_auth"
                v-if="$v.recoveryData.USER_PASSWORD.$dirty && !$v.recoveryData.USER_PASSWORD.required">введите пароль</span>
          <span class="reg-danger reg-danger_auth"
                v-else-if="$v.recoveryData.USER_PASSWORD.$dirty && !$v.recoveryData.USER_PASSWORD.minLength">длина пароля минимум 8 символов</span>
          <span class="reg-danger reg-danger_auth"
                v-else-if="$v.recoveryData.USER_PASSWORD.$dirty && !$v.recoveryData.USER_PASSWORD.valid">пароль должен содержать латинские буквы, как минимум одну заглавную, а также одну цифру</span>
        </div>
        <div class="auth-field">
          <p><label for="reg-pass-repeat" class="auth_label">Повторите пароль</label></p>
          <input type="password"
                 name="reg-pass-repeat"
                 class="auth_input" value=""
                 v-model.trim="recoveryData.USER_CONFIRM_PASSWORD"
                 @keydown.space.prevent
                 :class="{invalid: ($v.recoveryData.USER_CONFIRM_PASSWORD.$dirty && !$v.recoveryData.USER_CONFIRM_PASSWORD.required) || ($v.recoveryData.USER_CONFIRM_PASSWORD.$dirty && !$v.recoveryData.USER_CONFIRM_PASSWORD.sameAsPassword)}"/>
          <span class="reg-danger reg-danger_auth"
                v-if="$v.recoveryData.USER_CONFIRM_PASSWORD.$dirty && !$v.recoveryData.USER_CONFIRM_PASSWORD.required">подтвердите пароль</span>
          <span class="reg-danger reg-danger_auth"
                v-else-if="$v.recoveryData.USER_CONFIRM_PASSWORD.$dirty && !$v.recoveryData.USER_CONFIRM_PASSWORD.sameAsPassword">пароли не совпадают</span>
          <span class="reg-danger reg-danger_auth"
                v-if="serverErrors">{{ showServerError() }}</span>
        </div>
        <input type="submit" class="auth_btn recovery_btn" value="изменить">
      </form>
    </div>
    <div class="auth-area__form" v-else>
      <h3 class="form-title">Изменение пароля</h3>
      <div class="recovery-code-area">
        <span>Отсутствует проверочный код. {{ showServerError() }}</span>
      </div>
    </div>
  </div>
  <div class="auth-area__form" v-else>
    <div class="auth-field recovery-code-hint">
      Пароль успешно изменён! Теперь Вы можете войти в свой аккаунт
    </div>
  </div>
</template>

<script>
  import {required, minLength, sameAs,} from "vuelidate/lib/validators"
  import ApiService from "@/common/api.service";

  export default {
    name: "ChangePassword",
    data() {
      return {
        recoveryData: {
          USER_PASSWORD: '',
          USER_CONFIRM_PASSWORD: '',
          resetToken: '',
        },
        isTokenCheck: 0,
        isGoodToken: 0,
        isSetNewPass: 0,
        serverErrors: [],
        otherErrors: '',
      }
    },

    created() {
      if (this.$route.query.resetToken) {
        this.isTokenCheck = 1;
        this.recoveryData.resetToken = this.$route.query.resetToken;
        ApiService.get3(`auth/confirmation/?resetToken=${this.recoveryData.resetToken}`)
          .then(({data}) => {
            if (data.result.error === true) {
              this.serverErrors = data.result.errorArr;
              return;
            }
            this.isGoodToken = data.result.isGoodToken;
          })
          .catch(error => {
            this.otherErrors = error;
          });
      }
    },

    updated() {
      if (this.isSetNewPass) {
        setInterval(() => {
          this.$router.push({name: 'Login'});
        }, 15000)
      }
    },

    validations: {
      recoveryData: {
        USER_PASSWORD: {
          required,
          minLength: minLength(8),
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /[0-9]/.test(value);
            return containsUppercase && containsLowercase && containsNumber;
          }
        },
        USER_CONFIRM_PASSWORD: {required, sameAsPassword: sameAs('USER_PASSWORD')},
      }
    },

    methods: {
      changePassword() {
        if (this.$v.$invalid) {
          this.$v.$touch();
          return;
        }
        ApiService.post('auth/setNewPassword/', this.recoveryData)
          .then(
            ({data}) => {
              if (data.error === true) {
                this.serverErrors = data.errorArr;
                return;
              }
              this.isSetNewPass = data.isSetNewPass;
            })
          .catch(error => {
            this.otherErrors = error;
          });
      },
      showServerError() {
        let arrayError = this.serverErrors
          .map((item) => {
            return item["TEXT"];
          });
        return arrayError[0];
      }
    }
  }
</script>

