<template>
  <div>
    <div class="content-tab-wrapper">
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Экспертная организация<span class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data">
          <select :disabled="!requestItem.UF_IS_EDIT" v-model="requestItem.UF_COMPANY_EXPERT_ID"
                  class="main_input main_select">
            <option v-for="item in directory.expert_org" :key="item.ID" :value="item.ID">{{ item.UF_NAME }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 disable_color" v-if="!this.$route.params.copy && requestItem.DOGOVOR">Договор №
          {{ requestItem.DOGOVOR.NUMBER }} от
          {{ requestItem.DOGOVOR.DATE }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Внешнеэкономическая операция<span class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data">
          <div class="checked_row main-inlineBlock stl-pb_10">
            <select :disabled="!requestItem.UF_IS_EDIT" v-model="requestItem.UF_VEO_ID"
                    class="main_input main_select main-inlineBlock">
              <option v-for="item in directory.veo" :key="item.ID" :value="item.ID">{{ item.UF_NAME }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">С
          передачей
        </div>
        <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT"
                                                        v-model="requestItem.UF_IS_WITH_GEAR" type="checkbox"
                                                        class="main_check"></div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Страна (страны) операции<span class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data col-data-select">
          <Multiselect
              @input="setCountry"
              v-if="requestItem.UF_IS_EDIT"
              label="UF_NAME"
              track-by="ID"
              v-model="opCountry"
              :options="directory.country"
              placeholder=""
              :multiple="true"/>
          <div v-else>
            {{countries}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name col-name-center">Дата выдачи заключения</div>
        <div class="col-md-9 col-lg-10 col-data col-data-flex">
          <span class="do_stl">до</span>
          <datepicker
              :disabled="!requestItem.UF_IS_EDIT"
              v-model="requestItem.UF_DATE_ENT_CONCLUSION"
              :language="ru"
              :format="dateFormat"
              input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Срочная</div>
        <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT"
                                                        v-model="requestItem.UF_IS_IMPORTANT" type="checkbox"
                                                        class="main_check"></div>
      </div>
    </div>
    <!-- content-tab-wrapper -->

    <div class="content-tab-wrapper">
      <div class="row row_link_title">
        <div class="col-md-12">
          <a @click="showMembers = !showMembers" class="link_title">
            <i class="i_arrow" :class="{i_arrow_hidden: !showMembers}"></i>
            Участники
          </a>
        </div>
      </div>
      <div class="content-tab-indent" v-if="showMembers">

        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Покупатель<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data"><textarea-autosize
              v-model="requestItem.UF_BUYER"
              :disabled="!requestItem.UF_IS_EDIT"
              class="main_textarea"></textarea-autosize>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Потребитель (конечный
            пользователь)<span class="required_color">*</span>
          </div>
          <div class="col-md-9 col-lg-10 col-data"><textarea-autosize
              v-model="requestItem.UF_CONSUMER"
              :disabled="!requestItem.UF_IS_EDIT"
              class="main_textarea"></textarea-autosize>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Грузополучатель</div>
          <div class="col-md-9 col-lg-10 col-data"><textarea-autosize
              v-model="requestItem.UF_CARGO_RECEIVE"
              :disabled="!requestItem.UF_IS_EDIT"
              class="main_textarea"></textarea-autosize>
          </div>
        </div>
        <div class="row" v-if="requestItem.COMPANY_ZAKAZ">
          <div class="col-md-3 col-lg-2 col-name">Заказчик экспертизы:</div>
          <div class="col-md-9 col-lg-10 col-data">
            <select disabled v-model="requestItem.COMPANY_ZAKAZ[0].ID" class="main_input main_select">
              <option :value="requestItem.COMPANY_ZAKAZ[0].ID">{{ requestItem.COMPANY_ZAKAZ[0].UF_NAME }}</option>
            </select>
          </div>
        </div>
        <div class="row" v-if="requestItem.COMPANY_VED">
          <div class="col-md-3 col-lg-2 col-name">Российский участник
            внешнеэкономической операции
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <select disabled v-model="requestItem.COMPANY_VED[0].ID" class="main_input main_select">
              <option :value="requestItem.COMPANY_VED[0].ID">{{ requestItem.COMPANY_VED[0].UF_NAME }}</option>
            </select>
          </div>
        </div>
      </div>
      <br/>
      <div class="row row_link_title">
        <div class="col-md-12">
          <a @click="showDocs = !showDocs" class="link_title">
            <i class="i_arrow" :class="{i_arrow_hidden: !showDocs}"></i>
            Документы-основания
          </a>
        </div>
      </div>
      <div class="content-tab-indent" v-if="showDocs">

        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Вид основного документа<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT" type="text"
                                                          v-model="requestItem.UF_DOC_OSNOVANIE"
                                                          class="main_input">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">№ основного документа<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT" type="text"
                                                          class="main_input"
                                                          v-model="requestItem.UF_DOC_OSNOVANIE_NUMB">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Дата основного документа<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data">
            <datepicker
                :disabled="!requestItem.UF_IS_EDIT"
                v-model="requestItem.UF_DOC_OSNOVANIE_DATE"
                :language="ru"
                :format="dateFormat"
                input-class="main_input small_calendar"
            ></datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Дополнительные документы - основания списком</div>
          <div class="col-md-9 col-lg-10 col-data"><textarea-autosize :disabled="!requestItem.UF_IS_EDIT"
                                                             v-model="requestItem.UF_DOP_INFO"
                                                             class="main_textarea"></textarea-autosize>
          </div>
        </div>
      </div>
      <br/>
      <span class="required_color">Все поля, отмеченные * обязательны для заполнения</span>
    </div>

    <!-- content-tab-wrapper -->
    <CardDocs :page="1" v-if="!this.$route.params.copy && this.requestItem.ID > 0"></CardDocs>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
import CardDocs from "@/components/cabinet/request/CardDocs";
import {Funcs} from "@/common/funcs";

export default {
  name: "CardContent",
  data() {
    return {
      ru: ru,
      showMembers: true,
      showDocs: true,
      opCountry: []
    };
  },
  components: {CardDocs, Datepicker, Multiselect},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country"
    ]);
    this.opCountry = []
    for (let i = 0; i < this.requestItem.UF_COUNTRY_ID.length; i++) {
      let v = {
        ID: this.requestItem.UF_COUNTRY_ID[i]
      }
      for (let d = 0; d < this.directory.country.length; d++) {
        if (this.directory.country[d].ID === this.requestItem.UF_COUNTRY_ID[i]) {
          v.UF_NAME = this.directory.country[d].UF_NAME
        }
      }
      this.opCountry.push(v)
    }
  },
  computed: {
    countries() {
      let c = []
      if (this.directory.country.length && this.requestItem.UF_COUNTRY_ID.length) {
        for (let i = 0; i < this.requestItem.UF_COUNTRY_ID.length; i++) {
          c.push(Funcs.getDirectoryValue(this.directory.country, this.requestItem.UF_COUNTRY_ID[i]).UF_NAME)
        }
      }
      return c.join(', ')
    },
    ...mapGetters([
      "requestItem",
      "isRequestsLoading",
      "directory"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    setCountry() {
      this.requestItem.UF_COUNTRY_ID = []
      for (let i = 0; i < this.opCountry.length; i++) {
        this.requestItem.UF_COUNTRY_ID.push(this.opCountry[i].ID)
      }
    }
  }
}
</script>

<style scoped>

</style>
