import {TZService} from "@/common/api.service";

const state = {
    tzItem: {
        UF_IS_SIGN_BUTTON_ACTIVE: 0
    },
    isTZLoading: true,
    isTZLoadingError: false
};

const getters = {
    tzItem(state) {
        return state.tzItem;
    },
    isTZLoading(state) {
        return state.isTZLoading;
    },
    isTZLoadingError(state) {
        return state.isTZLoadingError;
    }
};

const actions = {
    fetchTZItem({commit}, slug) {
        commit('fetchTZStart');
        return TZService.item(slug)
            .then(({data}) => {
                commit('fetchTZItemEnd', data);
            })
            .catch(error => {
                commit('fetchTZError');
                throw new Error(error);
            });
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    ['fetchTZStart'](state) {
        state.isTZLoading = true;
    },
    ['fetchTZError'](state) {
        state.isTZLoading = false;
        state.isTZLoadingError = true;
    },
    ['fetchTZItemEnd'](state, item) {
        state.isTZLoading = false;
        state.tzItem = item;
        return item;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
