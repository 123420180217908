import Vue from 'vue'
import Vuelidate from "vuelidate/src";
import Toasted from "vue-toasted";
import VueMask from 'v-mask';
import VueMeta from 'vue-meta';
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from "./common/api.service";
import VTooltip from "v-tooltip";
import TextareaAutosize from 'vue-textarea-autosize'

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(Toasted);
Vue.use(VTooltip);
Vue.use(VueMask);
Vue.use(VueMeta);
Vue.use(TextareaAutosize)

ApiService.init();


new Vue({
  validations: {},
  router,
  store,
  render: h => h(App),
}).$mount('#app')
