<template>
  <div class="auth-area__form">
    <div v-if="!IS_USE_SMS">
      <h3 class="form-title">Восстановление пароля</h3>
      <form action="#" class="form-auth-login" @submit.prevent="resetEmailPassword">
        <div class="auth-field">
          <p><label for="reg-email" class="auth_label">Введите ваш логин (E-mail)</label></p>
          <input type="text"
                 name="reg-email"
                 autocomplete="off"
                 class="auth_input email_mask"
                 v-model.trim="recoveryData.USER_EMAIL"
                 @keydown.space.prevent
                 :class="{invalid: ($v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.required) || ($v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.email)}"/>
          <span class="reg-danger reg-danger_auth"
                v-if="$v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.required">введите email</span>
          <span class="reg-danger reg-danger_auth"
                v-else-if="$v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.email">email введен неверно</span>
          <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError() }}</span>
        </div>
        <div class="google-captha">
          <vue-recaptcha
              ref="recaptcha"
              size="checkbox"
              class="g-recaptcha"
              :sitekey="recaptcha.siteKey"
              @verify="validate"
              @expired="onCaptchaExpired"
          />
        </div>
        <input type="submit" class="auth_btn recovery_btn" value="восстановить">
      </form>
    </div>

    <div v-else>
      <h3 class="form-title">Восстановление пароля</h3>
      <form action="#" class="form-auth-login" @submit.prevent="resetPhonePassword">
        <div class="auth-field">
          <p><label for="reg-email" class="auth_label">Введите ваш логин (E-mail)</label></p>
          <input type="text"
                 name="reg-email"
                 class="auth_input email_mask"
                 v-model.trim="recoveryData.USER_EMAIL"
                 @keydown.space.prevent
                 :class="{invalid: ($v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.required) || ($v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.email)}"/>
          <span class="reg-danger reg-danger_auth"
                v-if="$v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.required">введите email</span>
          <span class="reg-danger reg-danger_auth"
                v-else-if="$v.recoveryData.USER_EMAIL.$dirty && !$v.recoveryData.USER_EMAIL.email">email введен неверно</span>
          <span class="reg-danger reg-danger_auth" v-if="serverErrors">{{ showServerError() }}</span>
        </div>
        <div class="google-captha">
          <vue-recaptcha
              ref="recaptcha"
              size="checkbox"
              class="g-recaptcha"
              :sitekey="recaptcha.siteKey"
              @verify="validate"
              @expired="onCaptchaExpired"
          />
        </div>
        <input type="submit" class="auth_btn recovery_btn" value="отправить код на телефон">
      </form>
    </div>
  </div>
</template>

<script>
    import VueRecaptcha from "vue-recaptcha";
    import Inputmask from 'inputmask';
    import ApiService from "@/common/api.service";
    import {SITE_KEY} from "@/common/config";
    import {email, required} from "vuelidate/lib/validators";
    import {SET_RECOVERY_EMAIL, SET_RECOVERY_CAPTCHA} from "@/store/mutations.type";

    export default {
        name: "ResetPassword",
        components: {
            VueRecaptcha
        },
        data() {
            return {
                recaptcha: {
                    isUseCaptcha: 0,
                    checked: false,
                    siteKey: SITE_KEY,
                },
                recaptchaToken: '',
                IS_USE_SMS: 0,
                IS_SEND_SMS: 0,
                isSendEmail: 0,
                recoveryData: {
                    USER_EMAIL: ''
                },
                serverErrors: null,
                otherErrors: null,
            }
        },

        mounted() {
            ApiService.get3("services/getStatusUseSMS/")
                .then(response => (this.IS_USE_SMS = response.data.IS_USE_SMS))
            ApiService.get3("services/getStatusUseReCaptcha/")
                .then(response => (this.recaptcha.isUseCaptcha = response.data.IS_USE_RECAPTCHA));
            let emailMask = new Inputmask("email");
            emailMask.mask(document.getElementsByClassName('email_mask'));
        },

        validations: {
            recoveryData: {
                USER_EMAIL: {email, required},
            }
        },

        methods: {
            validate(recaptchaToken) {
                this.recaptchaToken = recaptchaToken;
                this.recaptcha.checked = true;
            },

            onCaptchaExpired() {
                this.$refs.recaptcha.reset();
                this.recaptcha.checked = false;
            },

            showServerError() {
                let arrayError = this.serverErrors
                    .map((item) => {
                        return item["TEXT"];
                    });
                return arrayError[0];
            },

            resetPhonePassword() {
                if ((this.recaptcha.isUseCaptcha && !this.recaptcha.checked) || this.$v.$invalid) {
                    this.$v.$touch();
                    return;
                }
                this.$store.commit(SET_RECOVERY_EMAIL, this.recoveryData.USER_EMAIL);
                this.$store.commit(SET_RECOVERY_CAPTCHA, this.recaptchaToken);
                ApiService.post('services/sendSMSCodeUserForRegistation/', this.recoveryData)
                    .then(
                        ({data}) => {
                            if (data.result.error === true) {
                                this.serverErrors = data.result.errorArr;
                                return;
                            }
                            this.IS_SEND_SMS = data.result.IS_SEND_SMS;
                            if (this.IS_SEND_SMS) {
                                this.$router.push({name: 'SmsCode'});
                            }
                        })
                    .catch(error => {
                        this.otherErrors = error;
                    });
            },
            resetEmailPassword() {
                if ((this.recaptcha.isUseCaptcha && !this.recaptcha.checked) || this.$v.$invalid) {
                    this.$v.$touch();
                    return;
                }
                const {USER_EMAIL,} = this.recoveryData;
                const body = {USER_EMAIL, recaptchaToken: this.recaptchaToken};

                ApiService.post('auth/recovery/', body)
                    .then(
                        ({data}) => {
                            if (data.result.error === true) {
                                this.serverErrors = data.result.errorArr;
                                return;
                            }
                            this.isSendEmail = data.result.isSendEmail;
                            if (this.isSendEmail) {
                                this.$router.push({name: 'ControlString'});
                            }
                        })
                    .catch(error => {
                        this.otherErrors = error;
                    });
            },
        }
    }
</script>
