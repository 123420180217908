<template>
  <div class="content-tab__panel tab_active">
    <div class="container-fluid">
      <div class="content-tab-wrapper">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-name col-name_bold">5. Определение действующих в отношении идентифицируемых товаров и идентифицируемых продуктов научно-технической деятельности запретов и ограничений внешнеэкономической деятельности</div>
        </div>
        <div class="content-child-box">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-name">а) <b>в отношении страны назначения </b></div>
            <div class="col-md-7 col-lg-8 col-data">
              <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="data-text-box" style="width: 100%" v-model="conclusionItem.UF_MERA_COUNTRY">
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 col-lg-4 col-name">б) <b>в отношении иностранного участника внешнеэкономической операции</b></div>
            <div class="col-md-7 col-lg-8 col-data">
              <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="data-text-box" style="width: 100%" v-model="conclusionItem.UF_MERA_EXPORT_COMPANY">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-name">в) <b>в отношении идентифицируемых товаров и идентифицируемых продуктов научно-технической деятельности</b></div>
            <div class="col-md-12 col-lg-12 col-name">
              <div class="table-responsive production-area">
                <table class="production-table_child">
                  <thead>
                  <tr>
                    <th class="cell-w_100 mob-cell-w_100">№ объекта</th>
                    <th class="mob-cell-w_250">Результаты проверки</th>
                    <th class="cell-w_100 mob-cell-w_50 text-center">Действие</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in conclusionItem.VERIFICATION_RESULT_BAN" :key="item.ID">
                    <td>
                      <div class="production-cell">
                        <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="inp-production-cell" style="width: 100%" v-model="item.UF_NUMBER_OBJECT">
                      </div>
                    </td>
                    <td>
                      <div class="production-cell">
                        <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="inp-production-cell" style="width: 100%" v-model="item.UF_RESULTS_VERIFICATION">
                      </div>
                    </td>
                    <td class="text-center"><a v-if="conclusionItem.UF_IS_EDIT" @click="conclusionItem.VERIFICATION_RESULT_BAN.splice(index, 1)"><i class="control-delete_link"></i></a></td>
                  </tr>
                  <tr><td colspan="3">
                    <a v-if="conclusionItem.UF_IS_EDIT" class="control-panel__link control-add_link" @click="conclusionItem.VERIFICATION_RESULT_BAN.push({})">Добавить строку</a>
                  </td>
                  </tr></tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- row -->
          <div class="row">
            <div class="col-md-5 col-lg-4 col-name">5.2. Наличие признаков, дающих основания полагать, что идентифицируемые товары и идентифицируемые продукты научно-технической деятельности могут быть использованы в целях создания оружия массового поражения и средств его доставки, иных видов вооружения и военной техники либо приобретаются в интересах организаций или физических лиц, причастных к террористической деятельности</div>
            <div class="col-md-7 col-lg-8 col-data">
              <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="data-text-box" style="width: 100%" v-model="conclusionItem.UF_OMP">
            </div>
          </div>
        </div>
        <!-- content-child-box -->
      </div>
      <!-- content-tab-wrapper -->

    </div>
    <!-- container-fluid -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Restriction",
  computed: {
    ...mapGetters([
      "conclusionItem",
      "isConclusionsLoading",
      "directory"
    ]),
  },
}
</script>

<style scoped>

</style>