<template>
  <div class="page-top-title__item text-right">
    <div v-if="requestItem.UF_IS_SIGN_ECP && !$route.params.copy" style="display: inline-block"
         v-tooltip="{ content: new Object(() => ecpInfo()), loadingContent: '<i>Загрузка...</i>', classes: 'message' }"
    >
      <span class="e-signature">эцп</span>
      <span class="e-signature_data">{{ requestItem.UF_SIGN_ECP_DATE | dateFormat }}</span>
    </div>
    <a v-if="requestItem.UF_IS_VIEW_BTN_DOC === 1 && currentUser.ACTIVE_ROLE_CODE==='expert_org_manager'" class="link_style-blue" @click="requestDocs"
       :class="{'link-disabled': !requestItem.UF_IS_ACTIVE_BTN_DOC}"
    >Запрос документов</a>
    <a v-if="currentUser.ACTIVE_ROLE_CODE==='expert_org_manager' && requestItem.UF_IS_VIEW_BTN_SEND" class="link_style-blue"
       :class="{'link-disabled': !requestItem.UF_IS_DONE_CREATE_EXP}" @click="createConclusions">Отправить
      руководителю</a>
    <input v-if="requestItem.UF_IS_EDIT && currentUser.ACTIVE_ROLE_CODE!=='expert_org_manager'" :disabled="!requestItem.UF_IS_DONE_ECP" class="link_style-blue"
           :class="{'link-disabled': !requestItem.UF_IS_DONE_ECP}" @click="startECP" value="подписать эцп">
    <input v-if="requestItem.UF_IS_EDIT && currentUser.ACTIVE_ROLE_CODE!=='expert_org_manager'" :disabled="!requestItem.UF_IS_DONE_SEND" class="link_style-blue"
           :class="{'link-disabled': !requestItem.UF_IS_DONE_SEND}" @click="sendRequest"
           value="Отправить заявку"/>

    <div class="modal fade in show text-left" tabindex="-1" v-if="showEcpModal" role="dialog"
         aria-labelledby="myModalLabel">
      <div class="modal-dialog " style="width: 490px" role="document">
        <div class="modal-content">
          <div class="modal-add-document"
               style="z-index: 10000;width: 490px">
            <div class="modal-header main-clear">
              <span class="modal-title">Подпись заявки</span>
              <a @click="showEcpModal = false" class="modal-exit stl-FR"></a>
            </div>
            <div class="modal-body">
              <div v-if="!ecpPluginEnabled" style="color: red;">
                Плагин не активен
              </div>
              <div v-else style="color: green;">
                Плагин загружен.
              </div>
              <br/>
              <div class="modal_row main-clear">
                <span class="modal-field_name">Укажите сертификат подписи</span>
                <span>
                                         <select style="width: 465px" v-model="ecpCert" class="main_input main_select">
                                           <option :key="cert.text" v-for="cert in ecpCerts" :value="cert"
                                           >{{ cert.text }}
                                        </option>
                                        </select>
                                    </span>
              </div>

              <div class="modal_row main-clear">
                <button class="link_style-blue modal_btn_wid" style="width: 46%"
                        :disabled="ecpCert === null"
                        @click="Common_SignCadesBES_File(item)"
                >
                  подписать
                </button>
                <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                     @click="showEcpModal = false">
                  отмена
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*global cadesplugin*/
import {mapGetters} from "vuex";
//import {RequestService} from "@/common/api.service";
import "@/scripts/cadesplugin_api";
import {RequestService} from "@/common/api.service";
import moment from "moment";

export default {
  name: "SendRequest",
  computed: {
    ...mapGetters([
      "requestItem",
      "currentUser"
    ]),
  },
  data() {
    return {
      showEcpModal: false,
      ecpPluginEnabled: false,
      ecpCert: null,
      ecpCerts: [],
      toasterErrors: []
    }
  },
  methods: {
    sendRequest() {
      if (!this.requestItem.UF_IS_DONE_SEND) {
        return
      }
      RequestService.sendRequest(this.requestItem.ID).then(({data}) => {
        if (!data.error) {
          this.$toasted.show(
              "Заявка отправлена",
              {
                theme: "green",
                position: "bottom-right",
                duration: null,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
          this.$parent.fetchItem()
        }
      }, this)
    },
    startECP() {
      if (!this.requestItem.UF_IS_DONE_ECP) {
        return
      }
      this.showEcpModal = true;
      this.Common_CheckForPlugIn();
    },
    Common_CheckForPlugIn() {
      cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_DEBUG);
      let canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        return this.CheckForPlugIn_Async();
      } else {
        //return CheckForPlugIn_NPAPI();
      }
    },
    CheckForPlugIn_Async() {
      this.ecpPluginEnabled = true;
      let _this = this;
      cadesplugin.async_spawn(function* () {
        //let oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
        yield _this.FillCertList_Async();
      });
    },
    FillCertList_Async() {
      let _this = this;
      cadesplugin.async_spawn(function* () {
        let oStore;
        try {
          oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          if (!oStore) {
            alert("Ошибка чтения сертификатов ЭЦП");
            return;
          }

          yield oStore.Open();
        } catch (ex) {
          alert("Ошибка чтения сертификатов ЭЦП");
        }

        let CertificatesObj = yield oStore.Certificates;

        let certCnt = yield CertificatesObj.Count;
        if (certCnt === 0) {
          //throw "Сертификаты не найдены";
        }
        try {
          yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          let certs = yield oStore.Certificates;
          let certCnt = yield certs.Count;
          for (let i = 1; i <= certCnt; i++) {
            let cert = yield certs.Item(i);

            let oOpt = {
              text: "",
              value: ""
            };
            oOpt.text = yield cert.SubjectName;
            oOpt.value = yield cert.Thumbprint;
            _this.ecpCerts.push(oOpt);
          }
          yield oStore.Close();
        } catch (ex) {
          console.log(ex);
        }
        console.log(_this.ecpCerts)
      });
    },
    Common_SignCadesBES_File(item) {
      let canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        return this.SignCadesBES_Async_File(item);
      } else {
        //return SignCadesBES_NPAPI_File(id);
      }
    },
    SignCadesBES_Async_File(item) {
      let _this = this;
      cadesplugin.async_spawn(function* () {
        try {
          //FillCertInfo_Async(certificate);
          let oSigner;
          let errormes = "";
          try {
            oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          } catch (err) {
            errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
            throw errormes;
          }
          let oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );

          let CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
          yield oSigningTimeAttr.propset_Name(
              CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
          );
          let oTimeNow = new Date();
          yield oSigningTimeAttr.propset_Value(oTimeNow);
          let attr = yield oSigner.AuthenticatedAttributes2;
          yield attr.Add(oSigningTimeAttr);

          let oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );
          let CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
          yield oDocumentNameAttr.propset_Name(
              CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
          );
          yield oDocumentNameAttr.propset_Value("Document Name");
          yield attr.Add(oDocumentNameAttr);

          let oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          try {
            oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          } catch (e) {
            alert("Ошибка при открытии хранилища");
            console.log(e);
            return;
          }

          let CertificatesObj = yield oStore.Certificates;
          let CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
          let oCerts = yield CertificatesObj.Find(
              CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
              _this.ecpCert.value
          );

          if (oCerts.Count == 0) {
            errormes = "Certs not found";
            throw errormes;
          }

          let oCert = yield oCerts.Item(1);
          let sName = yield oCert.SubjectName;

          if (oSigner) {
            yield oSigner.propset_Certificate(oCert);
          } else {
            errormes = "Failed to create CAdESCOM.CPSigner";
            throw errormes;
          }

          let oSignedData = yield cadesplugin.CreateObjectAsync(
              "CAdESCOM.CadesSignedData"
          );
          let CADES_BES = 1;
          let buff = new Buffer(_this.requestItem.UF_NUMBER + _this.requestItem.UF_COMPANY_ID);
          let dataToSign = buff.toString('base64');
          if (dataToSign) {
            yield oSignedData.propset_ContentEncoding(1); //CADESCOM_BASE64_TO_BINARY
            yield oSignedData.propset_Content(dataToSign);
            yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN

            _this.ecpValue = yield oSignedData.SignCades(oSigner, CADES_BES);
            _this.showECP = false;
            _this.ecpRequest(item)
            let sn = sName.split(",");
            sn.forEach(function (v) {
              let r = v.replace("CN=", "");
              if (r.length !== v.length) {
                _this.ecpEnsurer = r.trim();
              }
            });

          }
          //alert(Signature)
        } catch (err) {
          console.log("sign err", err);
        }
      });
    },
    ecpRequest() {
      RequestService.setECPRequest(this.requestItem.ID, {UF_SIGN_ECP: this.ecpValue}).then(({data}) => {
        if (!data.error) {
          this.showEcpModal = false
          this.$parent.fetchItem()
        }
      }, this)
    },
    requestDocs() {
      if (this.requestItem.UF_IS_DONE_CREATE_EXP) {
        return
      }
      RequestService.requestDocs(this.requestItem.ID).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
        } else {
          this.$parent.fetchItem()
          this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
              'Документы запрошены',
              {
                theme: "green",
                position: "bottom-right",
                duration: 3000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
      }, this)
    },
    createConclusions() {
      RequestService.createConclusions(this.requestItem.ID).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
        } else {
          this.$parent.fetchItem()
          this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
              'Отправлено руководителю',
              {
                theme: "green",
                position: "bottom-right",
                duration: 3000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
      }, this)
    },
    async ecpInfo() {
      return await RequestService.getECPInfoRequest(this.requestItem.ID).then(({data}) => {
        return `<div class="modal-content">
<div class="text-center"><span class="modal-title">Заявка подписана <br> электронной
подписью</span></div>
<div class="modal-body">
<div class="modal_row modal_flex">
<span class="modal-field_name">Сертификат</span>
<span>${data.result.SERTIFICATE_NUMBER}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Организация</span>
<span>${data.result.COMPANY}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Владелец</span>
<span>${data.result.OWNER_FIO}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Должность</span>
<span>${data.result.POSITION}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Действителен</span>
<span>${data.result.DATE_USE_SERTIFICATE}</span>
</div>
</div>
</div>`
      }).catch(() => 'ERROR')
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
}
</script>

<style scoped>

</style>