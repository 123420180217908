<template>
    <div class="content-tab-wrapper" v-if="!isFaqsLoading">
        <div class="row">
            <router-link tag="div" :to="{name: 'ViewFaq', params: { id: item.ID }}" v-for="item in faqs.items"
                         v-bind:key="item.ID" class="col-md-4">
                <div class="question-item">
                    {{item.NAME}}
                </div>
            </router-link>
        </div>
    </div>
    <div v-else>
        Загрузка
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "FaqList",
        data() {
            return {
                itemsPerPage: 20,
                currentPage: 1
            };
        },
        mounted() {
            this.fetchFaq()
        },
        computed: {
            ...mapGetters([
                "faqs",
                "isFaqsLoading"
            ]),
            listConfig() {
                const q = {
                    page: this.currentPage,
                    limit: this.itemsPerPage,
                };
                return q;
            },
            pages() {
                if (this.isLoading || this.faqs.counts <= this.itemsPerPage) {
                    return [];
                }
                return [
                    ...Array(Math.ceil(this.faqs.counts / this.itemsPerPage)).keys()
                ].map(e => e + 1);
            }
        },
        watch: {
            currentPage(newValue) {
                this.listConfig.page = newValue;
                this.fetchNews();
            },
            itemsPerPage(newValue) {
                this.listConfig.limit = newValue;
                this.fetchNews();
            }
        },
        methods: {
            fetchFaq() {
                this.$store.dispatch('fetchFaqs', this.listConfig).then(() => {
                    console.log(this.faqs)
                    document.title = unescape(this.faqs.META_TITLE);
                }, this)
            },
        }
    }
</script>

<style scoped>

</style>