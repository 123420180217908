<template>
    <div class="content-tab-wrapper" v-if="load">

      <div class="row" v-if="!requestItem.UF_IS_DONE_CREATE_EXP && requestItem.UF_IS_VIEW_BTN_SEND
&& currentUser.ACTIVE_ROLE_CODE === 'expert_org_manager'
">
        <div class="col-md-3 col-lg-2 col-name">Замечание</div>
        <div class="col-md-9 col-lg-10 col-data">
          <textarea-autosize
              v-model="requestItem.UF_COMMENT_MGR"
              :disabled="!requestItem.UF_IS_VIEW_BTN_SEND"
              class="main_textarea"></textarea-autosize>
        </div>
      </div>

      <div class="btn-box" v-if="!requestItem.UF_IS_DONE_CREATE_EXP && requestItem.UF_IS_VIEW_BTN_SEND
&& currentUser.ACTIVE_ROLE_CODE === 'expert_org_manager'">
        <div class="link_style-blue" @click="saveComment">Сохранить замечание</div>
      </div>

      <div class="row row_link_title">
        <div class="col-md-12">
          <a @click="showMembers = !showMembers" class="link_title">
            <i class="i_arrow" :class="{i_arrow_hidden: !showMembers}"></i>
            История
          </a>
        </div>
      </div>

      <div class="content-tab-indent" v-if="showMembers">
        <div class="row" v-for="item in requestItem.HISTORY_COMMENTS" :key="item.ID">
          <div class="col-md-9 col-lg-10 col-name col-wrap-history">
            <p>{{item.UF_DATE_CREATE | dateFormat}} {{item.MANAGER.NAME}} {{item.MANAGER.GROUP_NAME}}</p>
            <p>{{item.UF_COMMENTS}}</p>
            <div v-if="!requestItem.UF_IS_DONE_CREATE_EXP && requestItem.UF_IS_VIEW_BTN_SEND">Статус:
              <span v-if="item.UF_STATUS_ID===1 && !item.show_form" class="status-fault_eliminated ">Устранено</span>
              <span v-if="item.UF_STATUS_ID===2 && !item.show_form" class="status-fault_not-eliminated ">Не устранено</span>
              <form action="#" class="wrap-history-form" v-if="item.show_form || !item.UF_STATUS_ID">
                              <span>
                                <input @click="changeStatus(item, 1)" :value="1" type="radio" id="status-fault-eliminated" v-model="item.UF_STATUS_ID" name="status-fault">
                                <label for="status-fault-eliminated">Устранено</label>
                              </span>
                <span>
                                <input @click="changeStatus(item, 2)" :value="2" type="radio" id="status-fault-not-eliminated" v-model="item.UF_STATUS_ID" name="status-fault">
                                <label for="status-fault-not-eliminated">Не устранено</label>
                              </span>
              </form>
              <a @click="item.show_form = true" v-show="currentUser.ACTIVE_ROLE_CODE === 'expert_org_manager'" v-if="item.UF_STATUS_ID > 0 && !item.show_form">Изменить статус замечания</a>
            </div>
          </div>
          <!-- col-md-9 col-lg-10 col-name col-wrap-history -->
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import {RequestService} from "@/common/api.service";

export default {
  name: "CardComment",
  data() {
    return {
      ru: ru,
      showMembers: true,
      opCountry: [],
      load: false,
    };
  },
  components: {},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "status_request_comments"
    ]);
    if (this.requestItem.HISTORY_COMMENTS) {
      for (let i = 0; i < this.requestItem.HISTORY_COMMENTS.length; i++) {
        Vue.set(this.requestItem.HISTORY_COMMENTS[i], "show_form", false)
      }
    }
    this.load = true
  },
  computed: {
    ...mapGetters([
      "requestItem",
      "isRequestsLoading",
      "currentUser",
      "directory"
    ]),
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    setCountry() {
      this.requestItem.UF_COUNTRY_ID = []
      for (let i = 0; i < this.opCountry.length; i++) {
        this.requestItem.UF_COUNTRY_ID.push(this.opCountry[i].ID)
      }
    },
    changeStatus(item, st) {
      RequestService.changeStatusComments(item.ID, {
        UF_STATUS_ID: st
      }).then(({data}) => {
        if (!data.error) {
          item.UF_STATUS_ID = st
          item.show_form = false
          this.$parent.fetchItem()
          this.$toasted.show(
              "Статус обновлен",
              {
                theme: "green",
                position: "bottom-right",
                duration: 3000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
      })
    },
    saveComment() {
      RequestService.saveComment(this.requestItem.ID, {
        UF_COMMENT_MGR: this.requestItem.UF_COMMENT_MGR
      }).then(({data}) => {
        if (!data.error) {
          this.$toasted.show(
              "Замечание обновлено",
              {
                theme: "green",
                position: "bottom-right",
                duration: 3000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
          this.$parent.fetchItem()
        }
      })

    }
  }
}
</script>

<style scoped>

</style>
