export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_ROLE = "setUserRole";
export const SET_FULL_ROLE = "setFullUserRole";
export const SET_ERROR = "setError";
export const FETCH_MP_START = "setMPLoading";
export const FETCH_MP_END = "setMP";
export const FETCH_MP_ERROR = "setMPError";
export const FETCH_NEWS_START = "setNewsLoading";
export const FETCH_NEWS_END = "setNews";
export const FETCH_NEWS_ERROR = "setNewsError";
export const SET_PROJECT = "setNewsItem";
export const FETCH_NEWS_ITEM_END = "setNewsItem";
export const FETCH_METHODS_START = "setMethodsLoading";
export const FETCH_METHODS_END = "setMethods";
export const FETCH_METHODS_ERROR = "setMethodsError";
export const FETCH_METHODS_ARCHIVE_END = "setMethodsArchive";
export const FETCH_TARIFFS_START = "setTariffsLoading";
export const FETCH_TARIFFS_END = "setTariffs";
export const FETCH_TARIFFS_ERROR = "setTariffsError";
export const FETCH_TARIFFS_ARCHIVE_END = "setTariffsArchive";

//recovery task
export const SET_RECOVERY_EMAIL = "setRecoveryEmail";
export const SET_RECOVERY_CAPTCHA = "setRecoveryCaptcha";
//profile task
export const SET_AVATAR = "setAvatar";
export const SET_NAME = "setName";
//org task
export const SET_ORG = "setOrg";
export const SET_COMPOSITION = "setComposition"
