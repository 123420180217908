<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-add-document"
             role="dialog"
             aria-labelledby="modal-title"
             aria-describedby="modalDescription"
        >
          <header
              class="modal-header main-clear"
          >
            <slot name="header">
              <div class="modal-title">СМЕНА ОРГАНИЗАЦИИ
                <button
                    type="button"
                    @click="close"
                    aria-label="Close modal"
                    class="modal-exit stl-FR reset-button"
                >
                </button>
              </div>
            </slot>
          </header>
          <section
              class="modal-body"
              id="modalDescription"
          >
            <slot name="body">
              <div class="modal_row main-clear modal_row_text">
                Организация
                <select name="organizations"
                        @change="selectOrg"
                        v-model="orgValue"
                >
                  <option v-for="(org, index) in changeOrganization"
                          :key="index"
                  >
                    {{org.COMPANY_NAME}} (ОГРН {{org.COMPANY_OGRN}})
                  </option>
                </select>
              </div>
            </slot>
          </section>
          <footer class="modal-footer modal_row main-clear text-center">
            <slot name="footer">
              <button
                  type="button"
                  class="btn-green link_style-blue"
                  @click="closeAndChange"
                  aria-label="Close modal"
              >
                ИЗМЕНИТЬ
              </button>
              <button
                  type="button"
                  class="btn-green link_style-white"
                  @click="close"
                  aria-label="Close modal"
              >
                ОТМЕНА
              </button>
            </slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "ChangeOrg",
    props: ['changeOrganization'],
    data() {
      return {
        orgValue: "",
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      closeAndChange() {
        this.$emit('closeAndChange');
      },
      selectOrg() {
        this.$emit('selectOrg', this.orgValue);
      },
    },
  }
</script>

<style scoped>

</style>
