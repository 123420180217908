<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <AccreditationItem/>
    </section>
    <Footer/>
  </div>
</template>

<script>
import MainMenu from "@/components/cabinet/MainMenu";
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";
import AccreditationItem from "@/components/cabinet/operatorManager/AccreditationItem";

export default {
  name: "AccreditationItemOM",
  components: {MainMenu, Header, Footer, AccreditationItem},
}
</script>

<style scoped>

</style>