<template>
  <div class="content-tab-wrapper">
    <div class="row row_link_title">
      <div class="col-md-12">
        <a @click="toggleDocs" class="link_title">
          <i class="i_arrow" :class="{i_arrow_hidden: !showDocs}"></i>
          Документы
        </a>
      </div>
    </div>
    <div class="content-tab-indent" v-if="showDocs">
      <div class="row">
        <div class="col-md-12 col-name">
          <div class="stl-pb_10">
            <span class="i-doc-desc"><i class="i-doc i-doc_y stl-vermid"></i> — документ принят</span>
            <span class="i-doc-desc"><i class="i-doc i-doc_n stl-vermid"></i> — документ отклонен </span>
            <span class="i-doc-desc"><i class="i-doc i-doc_np stl-vermid"></i> — документ не
                            проверен</span>
            <span class="i-doc-desc"><i class="i-doc i-doc_fp stl-vermid"></i> — документ формально принят</span>
          </div>

          <!-- START Основания внешнеэкономической операции -->
          <div class="content-tab-wrapper" :key="item.ID" v-for="item in docTypes()">
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="toggleVid(item)" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: item.hide}"></i>
                  {{ item.UF_NAME }}
                </a>
              </div>
            </div>
            <div class="content-tab-indent" v-if="!item.hide">
              <div class="row" :key="child.ID" v-for="child in item.CHILDS">
                <div v-if="requestItem.DOCUMENTES[child.ID]" class="col-md-12 col-name">
                  <strong>{{ child.UF_NAME }}</strong>
                </div>
                <div v-if="requestItem.DOCUMENTES[child.ID]">
                  <div class="col-md-12 col-name" :key="doc.ID"
                       v-for="doc in requestItem.DOCUMENTES[child.ID]">
                    <div class="stl-pb_10">
                      <br/>Название: {{ doc.UF_NAME }}
                    </div>
                    <div class="stl-pb_10">
                                            <span class="versia-box_doc">
                                                <a :href="docUrl(doc)"
                                                   :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ doc.UF_FILE_BASE_NAME }}</a></span>
                      <span v-if="doc.UF_IS_SIGN_ECP"
                            v-tooltip="{ content: new Object(() => ecpInfo(doc)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                                            <span class="e-signature">эцп</span>
                                            <span
                                                class="e-signature_data">{{ doc.UF_SIGN_ECP_DATE | dateFormat }}</span>
                                            </span>
                    </div>
                    <div class="">
                      <div class="col-name" v-if="doc.UF_COMMENT">
                      Комментарий: {{ doc.UF_COMMENT }}<br/><br/>
                    </div><div class="col-name" v-if="doc.UF_DATE_ADD_NEW_VERSION">
                      Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                    </div>
                      <div class="link_style-white"
                           v-if="requestItem.UF_IS_EDIT"
                           @click="showUploadModal(item, child.ID, doc.ID, doc.UF_NAME)">обновить
                        документ
                      </div>
                      <div class="link_style-blue stl-w-170"
                           v-if="currentUser.ACTIVE_ROLE_CODE==='expert_org_manager'"
                           @click="showStatusModal(doc)">изменить статус
                      </div>
                    </div>
                    <div class="content-tab-wrapper" v-if="doc.VERSION_LIST.length > 0">
                      <div class="row row_link_title">
                        <div class="col-md-12">
                          <a @click="toggleVid(doc)"
                             class="link_title link_suptitle">
                            <i class="i_arrow" :class="{i_arrow_hidden: !doc.hide}"></i>
                            Предыдущие версии
                          </a>
                        </div>
                      </div>
                      <div v-if="doc.hide">
                        <div class="content-tab-indent versia-tab-indent" :key="dh.ID"
                             v-for="dh in doc.VERSION_LIST">
                          <div class="row">
                            <div class="col-md-12 col-name">
                              Название: {{ dh.UF_NAME }}
                            </div>
                            <div class="col-md-12 col-name">
                                                        <span class="versia-box_doc">
                                                          <a :href="docUrl(doc)" :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ dh.UF_FILE_BASE_NAME }}</a>
                                                          <span class="link-doc_required">{{ dh.UF_COMMENT }}</span>
                                                        </span>
                              <span v-if="doc.UF_IS_SIGN_ECP"
                                    v-tooltip="{ content: new Object(() => ecpInfo(dh)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                                                            <span v-if="dh.UF_IS_SIGN_ECP"
                                                                  class="e-signature">эцп</span>
                                                            <span v-if="dh.UF_IS_SIGN_ECP"
                                                                  class="e-signature_data">{{
                                                                dh.UF_SIGN_ECP_DATE | dateFormat
                                                              }}</span>
                                                            </span>
                            </div>
                            <div class="col-name" v-if="dh.UF_COMMENT">
                              Комментарий: {{ dh.UF_COMMENT }}<br/><br/>
                            </div>
                            <div class="col-name" v-if="dh.UF_DATE_ADD_NEW_VERSION">
                              Срок предоставления новой версии: {{ dh.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- content-tab-indent versia-tab-indent -->
                      <!-- content-tab-wrapper -->
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
                <!-- col-name -->
              </div>
              <div v-if="requestItem.DOCUMENTES[item.ID]">
                <div class="col-md-12 col-name" :key="doc.ID"
                     v-for="doc in requestItem.DOCUMENTES[item.ID]">
                  <div class="stl-pb_10">
                    <br/>Название: {{ doc.UF_NAME }}
                  </div>
                  <div class="stl-pb_10">
                                            <span class="versia-box_doc"
                                            >
                                                <a :href="docUrl(doc)"
                                                   :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ doc.UF_FILE_BASE_NAME }}</a></span>
                    <span
                        v-tooltip="{ content: new Object(() => ecpInfo(doc)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                    <span v-if="doc.UF_IS_SIGN_ECP" class="e-signature">эцп</span>
                    <span v-if="doc.UF_IS_SIGN_ECP"
                          class="e-signature_data">{{ doc.UF_SIGN_ECP_DATE | dateFormat }}</span></span>
                  </div>
                  <div class="col-name" v-if="doc.UF_COMMENT">
                  Комментарий: {{ doc.UF_COMMENT }}<br/><br/>
                </div>
                  <div class="col-name" v-if="doc.UF_DATE_ADD_NEW_VERSION">
                    Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                  </div>
                  <div class="">
                    <div class="link_style-white"
                         v-if="requestItem.UF_IS_EDIT"
                         @click="showUploadModal(item, item.ID, doc.ID, doc.UF_NAME)">обновить
                      документ
                    </div>
                    <div class="link_style-blue stl-w-170"
                         v-if="currentUser.ACTIVE_ROLE_CODE==='expert_org_manager'"
                         @click="showStatusModal(doc)">изменить статус
                    </div>
                  </div>
                  <div class="content-tab-wrapper" v-if="doc.VERSION_LIST.length > 0">
                    <div class="row row_link_title">
                      <div class="col-md-12">
                        <a @click="toggleVid(doc)"
                           class="link_title link_suptitle">
                          <i class="i_arrow" :class="{i_arrow_hidden: !doc.hide}"></i>
                          Предыдущие версии
                        </a>
                      </div>
                    </div>
                    <div v-if="doc.hide">
                      <div class="content-tab-indent versia-tab-indent" :key="dh.ID"
                           v-for="dh in doc.VERSION_LIST">
                        <div class="row">
                          <div class="col-md-12 col-name">
                            Название: {{ doc.UF_NAME }}
                          </div>
                          <div class="col-md-12 col-name">
                                                        <span class="versia-box_doc">
                                                          <a href="#" :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ dh.UF_NAME }}</a>
                                                          <span class="link-doc_required">{{ dh.UF_COMMENT }}</span>
                                                        </span>
                            <span v-if="dh.UF_IS_SIGN_ECP"
                                  class="e-signature">эцп</span>
                            <span v-if="dh.UF_IS_SIGN_ECP"
                                  class="e-signature_data">{{ dh.UF_SIGN_ECP_DATE | dateFormat }}</span>
                          </div>
                          <div class="col-name" v-if="doc.UF_COMMENT">
                          Комментарий: {{ dh.UF_COMMENT }}<br/><br/>
                        </div>
                          <div class="col-name" v-if="dh.UF_DATE_ADD_NEW_VERSION">
                            Срок предоставления новой версии: {{ dh.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- content-tab-indent versia-tab-indent -->
                    <!-- content-tab-wrapper -->
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
              <!-- row -->
            </div>
            <!-- content-tab-indent -->
            <div class="stl-mt_10" v-if="!item.hide && requestItem.UF_IS_EDIT">
              <div class="link_style-blue" v-if="item.CHILDS" @click="showUploadModal(item, 0, 0)">
                добавить документ
              </div>
              <div class="link_style-blue" v-if="!item.CHILDS" @click="showUploadModal(item, item.ID, 0)">
                добавить документ
              </div>
            </div>

            <!--      Модальное окно для добавления файла     -->
            <div class="modal fade in show" tabindex="-1" v-if="uploadModalIsShowed(item)" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog " style="width: 490px" role="document">
                <div class="modal-content">
                  <div class="modal-add-document"
                       style="z-index: 10000;width: 490px">
                    <div class="modal-header main-clear">
                      <span class="modal-title">Добавление документа</span>
                      <a @click="closeUploadModal(item)" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body" v-show="!uploadCard.showECP">
                      <div class="modal_row main-clear"
                           v-if="uploadCard.ID === 0 && uploadTypes(item)">
                        <span class="modal-field_name">Вид документа</span>
                        <span>
                                         <select style="width: 465px"
                                                 @change="setAccept(item, uploadCard.UF_VID_DOC_REQUEST )"
                                                 v-model="uploadCard.UF_VID_DOC_REQUEST"
                                                 class="main_input main_select">
                                            <option v-for="item in uploadTypes(item)" :key="item.ID"
                                                    :value="item.ID">{{ item.UF_NAME }}</option>
                                        </select>
                                    </span>
                      </div>
                      <div class="modal_row main-clear" :disabled="uploadCard.blockName">
                        <span class="modal-field_name">Название</span>
                        <div v-if="uploadCard.ID>0">
                          {{ uploadCard.OLD_NAME }}
                        </div>
                        <span>
                                         <input style="width: 465px" v-model="uploadCard.UF_NAME" class="main_input "/>
                                    </span>
                      </div>
                      <div class="modal_row main-clear">
                        <div class="modal_upload-form" v-if="uploadCard.accept">
                          <label>
                            <input :accept="uploadCard.accept" name="modal-file"
                                   @change="onChangeFile"
                                   type="file"
                                   class="modal_i_file">
                            <div class="modal_link_add">выбрать файл</div>
                            <input name="modal-i-file_text" type="text"
                                   class="modal_i_file_text"
                                   :value="uploadCard.UP_NAME" disabled>
                          </label>
                        </div>
                      </div>
                      <div class="modal_row main-clear">
                        <button class="link_style-blue modal_btn_wid" style="width: 30%"
                                @click="uploadDocument(item)"
                                v-if="uploadCard.UF_FILE && uploadCard.UF_NAME && uploadCard.UF_VID_DOC_REQUEST > 0 && !uploadTypesItem(item, uploadCard.UF_VID_DOC_REQUEST).UF_IS_ECP_REQ">
                          сохранить
                        </button>
                        <button class="link_style-blue modal_btn_wid" style="width: 30%"
                                @click="startECP"
                                v-if="uploadCard.UF_FILE && uploadCard.UF_NAME && uploadCard.UF_VID_DOC_REQUEST > 0">
                          подписать ЭЦП
                        </button>
                        <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                             @click="closeUploadModal(item)">
                          отмена
                        </div>
                      </div>
                    </div>
                    <div class="modal-body" v-show="uploadCard.showECP">
                      <div v-if="!ecpPluginEnabled" style="color: red;">
                        Плагин не активен
                      </div>
                      <div v-else style="color: green;">
                        Плагин загружен.
                      </div>
                      <br/>
                      <div class="modal_row main-clear">
                        <span class="modal-field_name">Укажите сертификат подписи</span>
                        <span>
                                         <select style="width: 465px" v-model="ecpCert" class="main_input main_select">
                                           <option :key="cert.text" v-for="cert in ecpCerts" :value="cert"
                                           >{{ cert.text }}
                                        </option>
                                        </select>
                                    </span>
                      </div>

                      <div class="modal_row main-clear">
                        <button class="link_style-blue modal_btn_wid" style="width: 46%"
                                :disabled="ecpCert === null || isSignStarted"
                                @click="Common_SignCadesBES_File(item)"
                        >
                          подписать и сохранить
                        </button>
                        <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                             @click="closeUploadModal(item)">
                          отмена
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- content-tab-wrapper -->

        </div>
        <!-- col-md-12 col-name -->
      </div>
      <!-- row -->
      <div class="modal fade in show" tabindex="-1" v-if="statusCard.ID > 0" role="dialog"
           aria-labelledby="myModalLabel">
        <div class="modal-dialog " style="width: 490px" role="document">
          <div class="modal-content">
            <div class="modal-add-document"
                 style="z-index: 10000;width: 490px">
              <div class="modal-header main-clear">
                <span class="modal-title">Изменить статус</span>
                <a @click="statusCard.ID=0" class="modal-exit stl-FR"></a>
              </div>
              <div class="modal-body">
                <div class="modal_row main-clear">
                  <span class="modal-field_name">Статус</span>
                  <span>
                                         <select style="width: 465px"
                                                 v-model="statusCard.UF_STATUS_DOC_ID"
                                                 class="main_input main_select">
                                           <option></option>
                                            <option v-for="item in directory.status_documents_request" :key="item.ID"
                                                    v-show="item.UF_CODE==='document_formally_accepted' || item.UF_CODE==='document_rejected' "
                                                    :value="item.ID">{{ item.UF_NAME }}</option>
                                        </select>
                                    </span>
                </div>
                <div class="modal_row main-clear" v-if="statusCode(statusCard.UF_STATUS_DOC_ID) === 'document_rejected'">
                  <span class="modal-field_name">Комментарий</span>
                  <span>
                                         <textarea style="width: 465px;height: 100px" v-model="statusCard.UF_COMMENT" class="main_input "/>
                                    </span>
                </div>
                <div class="modal_row main-clear" v-if="statusCode(statusCard.UF_STATUS_DOC_ID) === 'document_rejected'">
                    <span class="modal-field_name" style="max-width: none">Срок предоставления новой версии документа</span>
                    <datepicker
                        v-model="statusCard.UF_DATE_ADD_NEW_VERSION"
                        :language="ru"
                        :format="dateFormat"
                        input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
                    ></datepicker>
                </div>
                <div class="modal_row main-clear">
                  <button class="link_style-blue modal_btn_wid" style="width: 30%"
                          @click="changeDocStatus"
                          v-if="statusCard.UF_STATUS_DOC_ID > 0">
                    сохранить
                  </button>
                  <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                       @click="statusCard.ID=0">
                    отмена
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-tab-indent -->
  </div>
  <!-- content-tab-wrapper -->
</template>

<script>
/*global cadesplugin*/
import {mapGetters} from "vuex";
import Vue from "vue";
import {RequestService} from "@/common/api.service";
import "@/scripts/cadesplugin_api";
import moment from "moment";
import {API_URL} from "@/common/config";
import {Funcs} from '@/common/funcs';
import {ru} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  name: "CardDocs",
  props: {
    page: {
      type: Number
    }
  },
  data() {
    return {
      ecpCert: "",
      ecpCerts: [],
      ecpValue: "",
      ecpPluginEnabled: false,
      showDocs: true,
      isSignStarted: false,
      uploadCard: {
        UF_VID_DOC_REQUEST: 0,
        UF_FORM_DOC_REQ_ID: 1,
        ID: 0,
        UF_NAME: "",
        UF_FILE: "",
        UP_NAME: "",
        blockName: false,
        showECP: false,
        file: ""
      },
      ru: ru,
      statusCard:{
        ID: 0,
        UF_STATUS_DOC_ID: 0,
        UF_DATE_ADD_NEW_VERSION: ""
      }
    };
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "vid_documents_request",
      "status_documents_request"
    ]);
    this.directory.vid_documents_request.forEach(function (item) {
      Vue.set(item, 'showUploadModal', false)
    })
  },
  components: { Datepicker},
  computed: {
    ...mapGetters([
      "requestItem",
      "isRequestsLoading",
      "directory",
      "currentUser"
    ]),
  },
  methods: {
    statusCode(id) {
      if (id > 0) {
        return Funcs.getDirectoryValue(this.directory.status_documents_request, id).UF_CODE
      } else {
        return ""
      }
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    startECP() {
      this.uploadCard.showECP = true;
      this.isSignStarted = false;
      this.Common_CheckForPlugIn();
    },
    Common_CheckForPlugIn() {
      cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_DEBUG);
      let canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        return this.CheckForPlugIn_Async();
      } else {
        //return CheckForPlugIn_NPAPI();
      }
    },
    CheckForPlugIn_Async() {
      this.ecpPluginEnabled = true;
      let _this = this;
      cadesplugin.async_spawn(function* () {
        //let oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
        yield _this.FillCertList_Async();
      });
    },
    FillCertList_Async() {
      let _this = this;
      cadesplugin.async_spawn(function* () {
        _this.ecpCerts = []
        let oStore;
        try {
          oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          if (!oStore) {
            alert("Ошибка чтения сертификатов ЭЦП");
            return;
          }

          yield oStore.Open();
        } catch (ex) {
          alert("Ошибка чтения сертификатов ЭЦП");
        }

        let CertificatesObj = yield oStore.Certificates;

        let certCnt = yield CertificatesObj.Count;
        if (certCnt === 0) {
          //throw "Сертификаты не найдены";
        }
        try {
          yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          let certs = yield oStore.Certificates;
          let certCnt = yield certs.Count;
          for (let i = 1; i <= certCnt; i++) {
            let cert = yield certs.Item(i);

            let oOpt = {
              text: "",
              value: ""
            };
            oOpt.text = yield cert.SubjectName;
            oOpt.value = yield cert.Thumbprint;
            _this.ecpCerts.push(oOpt);
          }
          yield oStore.Close();
        } catch (ex) {
          console.log(ex);
        }
        console.log(_this.ecpCerts)
      });
    },
    Common_SignCadesBES_File(item) {
      let canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        return this.SignCadesBES_Async_File(item);
      } else {
        //return SignCadesBES_NPAPI_File(id);
      }
    },
    SignCadesBES_Async_File(item) {
      let _this = this;
      this.isSignStarted = true
      cadesplugin.async_spawn(function* () {
        try {
          //FillCertInfo_Async(certificate);
          let oSigner;
          let errormes = "";
          try {
            oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          } catch (err) {
            errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
            throw errormes;
          }
          let oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );

          let CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
          yield oSigningTimeAttr.propset_Name(
              CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
          );
          let oTimeNow = new Date();
          yield oSigningTimeAttr.propset_Value(oTimeNow);
          let attr = yield oSigner.AuthenticatedAttributes2;
          yield attr.Add(oSigningTimeAttr);

          let oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );
          let CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
          yield oDocumentNameAttr.propset_Name(
              CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
          );
          yield oDocumentNameAttr.propset_Value("Document Name");
          yield attr.Add(oDocumentNameAttr);

          let oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          try {
            oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          } catch (e) {
            alert("Ошибка при открытии хранилища");
            console.log(e);
            return;
          }

          let CertificatesObj = yield oStore.Certificates;
          let CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
          let oCerts = yield CertificatesObj.Find(
              CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
              _this.ecpCert.value
          );

          if (oCerts.Count == 0) {
            errormes = "Certs not found";
            throw errormes;
          }

          let oCert = yield oCerts.Item(1);
          let sName = yield oCert.SubjectName;

          if (oSigner) {
            yield oSigner.propset_Certificate(oCert);
          } else {
            errormes = "Failed to create CAdESCOM.CPSigner";
            throw errormes;
          }

          let oSignedData = yield cadesplugin.CreateObjectAsync(
              "CAdESCOM.CadesSignedData"
          );
          let CADES_BES = 1;
          let dataToSign = _this.uploadCard.UF_FILE;
          if (dataToSign) {
            yield oSignedData.propset_ContentEncoding(1); //CADESCOM_BASE64_TO_BINARY
            yield oSignedData.propset_Content(dataToSign);
            yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN

            _this.ecpValue = yield oSignedData.SignCades(oSigner, CADES_BES);
            _this.showECP = false;
            _this.uploadDocument(item)
            let sn = sName.split(",");
            sn.forEach(function (v) {
              let r = v.replace("CN=", "");
              if (r.length !== v.length) {
                _this.ecpEnsurer = r.trim();
              }
            });

          }
          //alert(Signature)
        } catch (err) {
          console.log("sign err", err);
        }
      });
    },
    uploadModalIsShowed(item) {
      return item.showUploadModal
    },
    showStatusModal(doc) {
      this.statusCard.ID = doc.ID
      this.statusCard.UF_VID_DOC_REQUEST = doc.UF_VID_DOC_REQUEST
      this.statusCard.UF_STATUS_DOC_ID = doc.UF_STATUS_DOC_ID
      this.statusCard.UF_COMMENT = doc.UF_COMMENT
      this.statusCard.UF_DATE_ADD_NEW_VERSION = doc.UF_DATE_ADD_NEW_VERSION
    },
    showUploadModal(item, child, doc, oldName) {
      this.uploadCard.UF_VID_DOC_REQUEST = child
      if (child > 0) {
        this.uploadCard.accept = "." + item.UF_FORMAT_TYPE.replace(/;/g, ",.")
      }
      this.uploadCard.UP_NAME = "Файл не выбран"
      this.uploadCard.UF_FILE = ""
      this.uploadCard.UF_NAME = oldName
      this.uploadCard.OLD_NAME = oldName
      this.uploadCard.ID = doc
      this.uploadCard.showECP = false
      this.ecpValue = ""
      Vue.set(item, 'showUploadModal', true)
    },
    closeUploadModal(item) {
      Vue.set(item, 'showUploadModal', false)
    },
    uploadDocument(item) {
      let formData = new FormData();
      formData.append("UF_VID_DOC_REQUEST", this.uploadCard.UF_VID_DOC_REQUEST)
      formData.append("UF_FORM_DOC_REQ_ID", this.uploadCard.UF_FORM_DOC_REQ_ID)
      formData.append("UF_FILE", this.uploadCard.file)
      formData.append("UF_NAME", this.uploadCard.UF_NAME)
      formData.append("UF_FILE_SIGN_ECP", this.ecpValue)
      formData.append("UF_FILE_SIGN_AUTHOR", this.ecpCert.text)
      if (this.uploadCard.ID > 0) {
        RequestService.editDocument(this.requestItem.ID, this.uploadCard.ID, formData).then(({data}) => {
          if (!data.error) {
            this.closeUploadModal(item)
            RequestService.item(this.requestItem.ID).then(({data}) => {
              this.requestItem.DOCUMENTES = data.DOCUMENTES;
            })
          }
        }, this)
      } else {
        RequestService.addDocument(this.requestItem.ID, formData).then(({data}) => {
          if (!data.error) {
            this.closeUploadModal(item)
            RequestService.item(this.requestItem.ID).then(({data}) => {
              this.requestItem.DOCUMENTES = data.DOCUMENTES;
            })
          }
        }, this)
      }
    },
    changeDocStatus() {
      RequestService.changeStatusDocument(this.requestItem.ID, this.statusCard.ID, this.statusCard).then(({data}) => {
        if (!data.error) {
          this.statusCard.ID = 0
          RequestService.item(this.requestItem.ID).then(({data}) => {
            this.requestItem = data;
          })
        }
      }, this)
    },
    uploadTypes(item) {
      let ret = []
      if (!item.CHILDS) {
        return
      }
      for (let i = 0; i < item.CHILDS.length; i++) {
        if (item.CHILDS[i].UF_IS_MULTY) {
          ret.push(item.CHILDS[i])
        } else if (!this.requestItem.DOCUMENTES[item.CHILDS[i].ID]) {
          ret.push(item.CHILDS[i])
        }
      }
      return ret
    },
    uploadTypesItem(item, id) {
      if (!item.CHILDS) {
        return item
      }
      for (let i = 0; i < item.CHILDS.length; i++) {
        if (item.CHILDS[i].ID === id) {
          return item.CHILDS[i]
        }
      }
    },
    setAccept(item, id) {
      let ret = ""
      for (let i = 0; i < item.CHILDS.length; i++) {
        if (item.CHILDS[i].ID === id) {
          ret = item.CHILDS[i].UF_FORMAT_TYPE
        }
      }
      this.uploadCard.accept = "." + ret.replace(/;/g, ",.")
    },
    docTypes() {
      let ret = []
      for (let i = 0; i < this.directory.vid_documents_request.length; i++) {
        if (this.page === 1 && this.directory.vid_documents_request[i].UF_CODE !== 'technical_documentation') {
          ret.push(this.directory.vid_documents_request[i])
        }
        if (this.page === 2 && this.directory.vid_documents_request[i].UF_CODE === 'technical_documentation') {
          ret.push(this.directory.vid_documents_request[i])
        }
      }
      return ret
    },
    onChangeFile(e) {
      let files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + " kB",
            base64: reader.result,
            file: file
          };
          //this.uFile = file.name;
          let header = ";base64,";
          let fileData = reader.result;
          Vue.set(this.uploadCard, 'UP_NAME', fileInfo.name);
          Vue.set(this.uploadCard, 'UF_FILE', fileData.substr(
              fileData.indexOf(header) + header.length
          ));
          this.uploadCard.file = files[i]
        }; // reader.onload
      } // for
    },
    toggleDocs() {
      this.showDocs = !this.showDocs
    },
    toggleVid(item) {
      Vue.set(item, 'hide', !item.hide)
    },
    docUrl(object) {
      return API_URL.replace(new RegExp("[\\/]+$"), "") + object.UF_FILE;
    },
    async ecpInfo(doc) {
      console.log(doc)
      return await RequestService.getECPInfoFile(doc.ID).then(({data}) => {
        return `<div class="modal-content">
<div class="text-center"><span class="modal-title">Документ подписан <br> электронной
подписью</span></div>
<div class="modal-body">
<div class="modal_row modal_flex">
<span class="modal-field_name">Сертификат</span>
<span>${data.result.SERTIFICATE_NUMBER}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Организация</span>
<span>${data.result.COMPANY}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Владелец</span>
<span>${data.result.OWNER_FIO}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Должность</span>
<span>${data.result.POSITION}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Действителен</span>
<span>${data.result.DATE_USE_SERTIFICATE}</span>
</div>
</div>
</div>`
      }).catch(() => 'ERROR')
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
}
</script>

<style scoped>

</style>