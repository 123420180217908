<template>
  <!--  <div class="content-tab-area">-->
  <div class="content-tab__panel tab_active">
    <div class="container-fluid">
      <div class="content-tab-wrapper">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-name col-name_bold">7. Дополнительная информация</div>
        </div>
        <div class="content-child-box">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-name">7.1. Результаты анализа внешнеэкономической сделки по п.5.2
              заключения
            </div>
            <div class="col-md-7 col-lg-8 col-data">
              <textarea :disabled="!conclusionItem.UF_IS_EDIT" class="data-text-box" style="width: 100%;" v-model="conclusionItem.UF_RESULT_ANALYSIS"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-name">7.2. Перечень представленных документов</div>
            <div class="col-md-12 col-lg-12 col-name">
              <div class="table-responsive production-area">
                <table class="production-table_child">
                  <thead>
                  <tr>
                    <th class="cell-w_100 mob-cell-w_100">№ п/п</th>
                    <th class="mob-cell-w_250">Название документа</th>
                    <th class="mob-cell-w_250">Номер и дата</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in conclusionItem.DOCUMENTS_CONCLUSIONS" :key="item.ID">
                    <td>
                      <div class="production-cell">
                        <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="inp-production-cell" :value="index+1">
                      </div>
                    </td>
                    <td>
                      <div class="production-cell">
                        <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="inp-production-cell" style="width: 100%" v-model="item.UF_NAME">
                      </div>
                    </td>
                    <td>
                      <div class="production-cell">
                        <input :disabled="!conclusionItem.UF_IS_EDIT" type="text" class="inp-production-cell" style="width: 100%" v-model="item.UF_NUMBER_DATE_DOCUMENT">
                      </div>
                    </td>
                    <td class="text-center"><a v-if="conclusionItem.UF_IS_EDIT" @click="conclusionItem.DOCUMENTS_CONCLUSIONS.splice(index, 1)"><i class="control-delete_link"></i></a></td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <a v-if="conclusionItem.UF_IS_EDIT" @click="conclusionItem.DOCUMENTS_CONCLUSIONS.push({})" class="control-panel__link control-add_link">Добавить строку</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- row -->
          <div class="row">
            <div class="col-md-5 col-lg-4 col-name">7.3. Действие данного заключения не распространяется на продукцию
              военного назначения
            </div>
            <div class="col-md-7 col-lg-8 col-data"><input :disabled="!conclusionItem.UF_IS_EDIT" v-model="conclusionItem.UF_IS_MILITARY_PURPOSE" type="checkbox" class="main_check" >
            </div>
          </div>
        </div>
        <!-- content-child-box -->
      </div>

    </div>
    <!-- container-fluid -->
  </div>
  <!--  </div>-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OptionInfo",
  computed: {
    ...mapGetters([
      "conclusionItem",
      "isConclusionsLoading",
      "directory"
    ]),
  },
}
</script>

<style scoped>

</style>