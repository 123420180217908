<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <div>DogovorsServices OM</div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import MainMenu from "@/components/cabinet/MainMenu";
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";

export default {
  name: "DogovorsServicesOM",
  components: {MainMenu, Header, Footer},
}
</script>

<style scoped>

</style>