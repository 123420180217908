import {TariffsService} from "@/common/api.service";
import {
  FETCH_TARIFFS, FETCH_TARIFFS_ARCHIVE
} from "./actions.type";

import {
  FETCH_TARIFFS_START, FETCH_TARIFFS_END, FETCH_TARIFFS_ERROR, FETCH_TARIFFS_ARCHIVE_END
} from "./mutations.type";

const state = {
  tariffsArchiveLink: {},
  tariffs: {
    counts: 0,
    items: []
  },
  isTariffsLoading: true,
  isTariffsLoadingError: false
};

const getters = {
  tariffs(state) {
    return state.tariffs;
  },
  isTariffsLoading(state) {
    return state.isTariffsLoading;
  },
  isTariffsLoadingError(state) {
    return state.isTariffsLoadingError;
  }
};

const actions = {
  [FETCH_TARIFFS]({ commit }, params) {
    commit(FETCH_TARIFFS_START);
    return TariffsService.list(params.type, params)
      .then(({ data }) => {
        commit(FETCH_TARIFFS_END, data);
      })
      .catch(error => {
        commit(FETCH_TARIFFS_ERROR);
        throw new Error(error);
      });
  },

  [FETCH_TARIFFS_ARCHIVE]({ commit }) {
    return TariffsService.archive()
        .then(({ data }) => {
          commit(FETCH_TARIFFS_ARCHIVE_END, data);
        })
        .catch(error => {
          throw new Error(error);
        });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_TARIFFS_START](state) {
    state.isTariffsLoading = true;
  },
  [FETCH_TARIFFS_END](state, tariffs) {
    state.tariffs = tariffs;
    state.isTariffsLoading = false;
  },
  [FETCH_TARIFFS_ERROR](state) {
    state.isTariffsLoading = false;
    state.isTariffsLoadingError = true;
  },
  [FETCH_TARIFFS_ARCHIVE_END](state, link) {
    state.tariffsArchiveLink = link;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
