<template>
  <div>
    <div class="auth-area__form">
      <h3 class="form-title">Восстановление пароля</h3>
      <div class="recovery-code-area">
        <span>Контрольная строка для смены пароля выслана Вам на указанный E-mail</span>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ControlString",
        data(){
            return{

            }
        }
    }
</script>
