<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-add-document"
             role="dialog"
             aria-labelledby="modal-title"
             aria-describedby="modalDescription"
        >
          <header
              class="modal-header main-clear"
          >
            <slot name="header">
              <div class="modal-title">{{consentName}}
                <button
                    type="button"
                    @click="close"
                    aria-label="Close modal"
                    class="modal-exit stl-FR reset-button"
                >
                </button>
              </div>
            </slot>
          </header>
          <section
              class="modal-body"
              id="modalDescription"
          >
            <slot name="body">
              <div class="modal_row main-clear modal_row_text" v-html="consentText"></div>
            </slot>
          </section>
          <footer class="modal-footer modal_row main-clear text-center">
            <slot name="footer">
              <button
                  type="button"
                  class="btn-green link_style-blue"
                  @click="closeAndCheck"
                  aria-label="Close modal"
              >
                СОГЛАСЕН
              </button>
            </slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    export default {
        name: 'consent',
        props: ['consentName', 'consentText'],
        methods: {
            close() {
                this.$emit('close');
            },
            closeAndCheck() {
                this.$emit('closeAndCheck');
            }
        },
    };
</script>

