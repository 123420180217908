<template>
  <div class="content-tab-area">
    <!-- START content-tab__panel  -->
    <div class="content-tab__panel tab_active">
      <div class="container-fluid">
        <div class="content-tab-wrapper">
          <div class="content-tab-indent">
            <div class="control-panel">
              <div class="control-panel__item">
                <a href="#" class="control-panel__link control-xls_link" @click="showDownloadReportModal = true"></a>
                &nbsp;
                <a @click="getLinkReportXML" title="Загрузить отчет" class="control-xml_link"></a>
              </div>
              <div class="control-panel__item">
                <div class="control-parent_item">
                  <a href="#" class="control-panel__link control-funnel_link">
                    <i class="control-link_arrow"></i>
                  </a>
                  <div class="control-child_box">
                    <ul>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                    </ul>
                  </div>
                </div>
                <div class="control-parent_item">
                  <a href="#" class="control-panel__link control-diagram_link">
                    <i class="control-link_arrow"></i>
                  </a>
                  <div class="control-child_box">
                    <ul>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="control-panel__item control-panel__filter">
                <div class="panel-filter__item">
                  <span class="panel-filter_name panel-filter__item">Фильтр по периоду</span>
                  <select name="" v-model="filter.type" class="filter-inp_style">
                    <option value=""></option>
                    <option value="month">Месяц</option>
                    <option value="kvartal">Квартал</option>
                    <option value="year">Год</option>
                  </select>
                  <span class="mob-none">:</span>
                </div>
                <div class="panel-filter__item" v-if="filter.type==='month'">
                  <span class="panel-filter_name">месяц</span>
                  <select v-model="filter.month" class="filter-inp_style  input_small-size">
                    <option value="1">Январь</option>
                    <option value="2">Февраль</option>
                    <option value="3">Март</option>
                    <option value="4">Апрель</option>
                    <option value="5">Май</option>
                    <option value="6">Июнь</option>
                    <option value="7">Июль</option>
                    <option value="8">Август</option>
                    <option value="9">Сентябрь</option>
                    <option value="10">Октябрь</option>
                    <option value="11">Ноябрь</option>
                    <option value="12">Декабрь</option>
                  </select>
                </div>
                <div class="panel-filter__item" v-if="filter.type==='kvartal'">
                  <span class="panel-filter_name">квартал</span>
                  <select v-model="filter.kvartal" class="filter-inp_style  input_small-size">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div class="panel-filter__item">
                  <span class="panel-filter_name">год</span>
                  <input type="text" v-model="filter.year" class="filter-inp_style small_calendar input_small-size">
                </div>
                <div class="panel-filter__item">
                  <input type="button" @click="fetchReports" class="filter-btn_style-blue" value="применить">
                </div>
              </div>
              <div class="control-panel__item">
                <div class="panel-filter__item">
                  <input type="button" class="filter-btn_style-blue" @click="showReportModal=true"
                         value="сформировать отчет">
                </div>
              </div>
            </div>

            <!-- control-panel -->
            <div class="table-responsive production-area">
              <table class="production-table_child">
                <thead>
                <tr>
                  <th rowspan="3" class="mob-cell-w_200">Дата<i
                      class="i-panel_orient orient_active"></i></th>
                  <th colspan="4" class="mob-cell-w_300 text-center after_dashed">Количество выданных
                    заключений
                  </th>
                </tr>
                <tr>
                  <th rowspan="2" class="mob-cell-w_100 text-center">Всего</th>
                  <th colspan="3" class="mob-cell-w_400 text-center after_dashed">В том числе</th>
                  <th rowspan="2">Фактическая дата отправки отчета</th>
                  <th rowspan="2" class="mob-cell-w_100 text-center">Проверено</th>
                </tr>
                <tr>
                  <th class="mob-cell-w_100 text-center">Без ограничений</th>
                  <th class="mob-cell-w_100 text-center">Лицензия ФСТЭК</th>
                  <th class="mob-cell-w_100 text-center">Разрешение КЭК</th>
                </tr>
                </thead>
                <tbody>
                <tr class="" v-for="item in reports.items" v-bind:key="item.ID">
                  <td>
                    <router-link :to="{name: 'LK/reestr-fstec/edit', params: { id: item.ID }}">{{ item.UF_DATE_OTCHET | dateFormat }}</router-link></td>
                  <td class="text-center">
                    {{ item.UF_COUNT_ZAK_NOLIMIT + item.UF_COUNT_LIC_FSTEK + item.UF_COUNT_RES_KEK }}
                  </td>
                  <td class="text-center">{{ item.UF_COUNT_ZAK_NOLIMIT }}</td>
                  <td class="text-center">{{ item.UF_COUNT_LIC_FSTEK }}</td>
                  <td class="text-center">{{ item.UF_COUNT_RES_KEK }}</td>
                  <td class="text-center">
                    <datepicker
                        v-model="item.UF_DATE_SEND_FACT"
                        :language="ru"
                        :format="dateFormat"
                        @closed="updateFactDate(item)"
                        input-class="filter-inp_style small_calendar"
                    ></datepicker>
                  </td>
                  <td class="text-center"><i v-if="item.UF_IS_VERIFIED" class="checked-provereno"></i>
                  </td>
                </tr>
                <tr class="">
                  <td><b class="td_count_bold">Итого за период</b></td>
                  <td class="text-center"><b class="td_count_bold">
                    {{
                      fieldSum('UF_COUNT_ZAK_NOLIMIT') + fieldSum('UF_COUNT_LIC_FSTEK')
                      + fieldSum('UF_COUNT_RES_KEK')
                    }}
                  </b></td>
                  <td class="text-center"><b class="td_count_bold">{{ fieldSum('UF_COUNT_ZAK_NOLIMIT') }}</b>
                  </td>
                  <td class="text-center"><b
                      class="td_count_bold">{{ fieldSum('UF_COUNT_LIC_FSTEK') }}</b></td>
                  <td class="text-center"><b
                      class="td_count_bold">{{ fieldSum('UF_COUNT_RES_KEK') }}</b></td>
                  <td class="text-center">

                  </td>
                  <td class="text-center"></td>
                </tr>
                </tbody>
              </table>
              <div class="main-pagination table-pagination">
                <Pagination
                    :pages="pages"
                    :currentPage.sync="currentPage"
                />
                <span class="pagination_all">Всего: {{ reports.counts }}</span>
                <span>Выводить по: </span>
                <select class="pagination__select" v-model="itemsPerPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div class="modal fade in show" tabindex="-1" v-if="showReportModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog " style="width: 490px" role="document">
                <div class="modal-content">
                  <div class="modal-add-document" style="min-width: 310px">
                    <div class="modal-header main-clear">
                      <span class="modal-title">Сформировать отчет</span>
                      <a href="#" @click="closeReportModal" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <span>{{ rulesErr }}</span>
                      <div class="modal_row main-clear">
                        <span class="modal-field_name">Дата отчета    </span>
                        <span>
                                        <datepicker
                                            v-model="rulesDate"
                                            :language="ru"
                                            :format="dateFormat"
                                            input-class="modal_input stl-FR"
                                        ></datepicker>
                                        </span>
                      </div>
                      <div class="modal_row main-clear" style="width: 290px" v-if="textQuestion">
                        {{ textQuestion }}
                      </div>
                      <div class="modal_row main-clear">
                        <button v-if="!textQuestion" class="link_style-blue modal_btn_wid"
                                @click="checkRules">создать
                        </button>
                        <button v-if="!textQuestion" class="link_style-white stl-FR modal_btn_wid"
                                @click="closeReportModal">отмена
                        </button>
                        <button v-if="textQuestion" class="link_style-blue modal_btn_wid"
                                @click="createReport">да
                        </button>
                        <button v-if="textQuestion" class="link_style-white stl-FR modal_btn_wid"
                                @click="closeReportModal">нет
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade in show" tabindex="-1" v-if="showDownloadReportModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog " style="width: 490px" role="document">
                <div class="modal-content">
                  <div class="modal-add-document" style="min-width: 310px">
                    <div class="modal-header main-clear">
                      <span class="modal-title">Загрузить отчет</span>
                      <a href="#" @click="showDownloadReportModal = false" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <span>{{ rulesErr }}</span>
                      <div class="modal_row main-clear">
                        <span class="modal-field_name">Дата отчета    </span>
                        <span>
                                        <datepicker
                                            v-model="reportDate"
                                            :language="ru"
                                            :format="dateFormat"
                                            input-class="modal_input stl-FR"
                                        ></datepicker>
                                        </span>
                      </div>
                      <div class="modal_row main-clear">
                        <button class="link_style-blue modal_btn_wid"
                                @click="downloadReport">загрузить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- table-responsive -->
          </div>
          <!-- content-tab-indent -->
        </div>
        <!-- content-tab-wrapper -->
      </div>
      <!-- container-fluid -->
    </div>
    <!-- END content-tab__panel  -->
  </div>
  <!-- content-tab__panel -->
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from "@/components/site/Pagination";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import {ReportService} from "@/common/api.service";
import {API_URL} from "@/common/config";

export default {
  name: "ReportList",
  components: {Pagination, Datepicker},
  data() {
    return {
      itemsPerPage: 20,
      currentPage: 1,
      ru: ru,
      showDownloadReportModal: false,
      reportDate: null,
      showReportModal: false,
      rulesDate: null,
      rulesErr: null,
      textQuestion: "",
      filter: {
        type: null,
        month: null,
        kvartal: null,
        year: null
      }
    };
  },
  mounted() {
    this.fetchReports()
  },
  computed: {
    ...mapGetters([
      "reports",
      "isReportsLoading"
    ]),
    listConfig() {
      const q = {
        page: this.currentPage,
        limit: this.itemsPerPage,
        filter: {},
        filterPeriod: {}
      };
      if (this.filter.type === 'month') {
        q.filterPeriod.type = this.filter.type;
        q.filterPeriod.month = this.filter.month;
      }
      if (this.filter.type === 'kvartal') {
        q.filterPeriod.type = this.filter.type;
        q.filterPeriod.kvartal = this.filter.kvartal;
      }
      if (this.filter.type === 'year') {
        q.filterPeriod.type = this.filter.type;
      }
      q.filterPeriod.year = this.filter.year;
      return q;
    },
    pages() {
      if (this.isReportsLoading || this.reports.counts <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.reports.counts / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    }
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.page = newValue;
      this.fetchReports();
    },
    itemsPerPage(newValue) {
      this.listConfig.limit = newValue;
      this.fetchReports();
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  methods: {
    fetchReports() {
      this.$store.dispatch('fetchReports', this.listConfig).then(() => {
        document.title = unescape(this.reports.META_TITLE);
      }, this)
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    fieldSum(field) {
      let sum = 0;
      this.reports.items.forEach(function (v) {
        sum += v[field]
      });

      return sum
    },
    checkRules() {
      ReportService.checkRulesNewReport({
        UF_DATE_OTCHET: this.rulesDate
      }).then(
          ({data}) => {
            if (data.errorAr.length > 0) {
              this.rulesErr = data.errorAr[0].TEXT;
              return
            }
            if (data.isCreated) {
              if (data.isQuestion) {
                this.textQuestion = data.extQuestion;
              } else {
                this.createReport()
              }
            }
          }, this)
    },
    createReport() {
      ReportService.createReport({
        UF_DATE_OTCHET: this.rulesDate
      }).then(
          ({data}) => {
            if (data.ID > 0) {
              this.fetchReports();
              this.closeReportModal()
            }
            if (data.error) {
              this.rulesErr = data.errorAr[0].TEXT;
            }
          }, this)
    },
    downloadReport() {
      ReportService.downloadReport(moment(this.reportDate).format("DD.MM.YYYY")).then(
          ({data}) => {
            window.open(API_URL.replace(new RegExp("[\\/]+$"), "") + data.link);
          }, this)
    },
    getLinkReportXML() {
      ReportService.getLinkReportXML(moment(this.reportDate).format("DD.MM.YYYY")).then(
          ({data}) => {
            window.open(API_URL.replace(new RegExp("[\\/]+$"), "") + data.link);
          }, this)
    },
    closeReportModal() {
      this.textQuestion = "";
      this.showReportModal = false;
    },
    updateFactDate(item) {
      ReportService.editFactSendTime({
        UF_DATE_SEND_FACT: item.UF_DATE_SEND_FACT,
        ID: item.ID
      }).then(
          ({data}) => {
            console.log(data)
          }, this)
    }
  }
}
</script>

<style scoped>
.modal_row .vdp-datepicker {
  display: inline-block;
}
</style>