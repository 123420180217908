<template>
  <div>
    <div class="content-tab-wrapper">
      <div class="row row_link_title">
        <div class="col-md-12">
          <a @click="showProducts = !showProducts" class="link_title">
            <i class="i_arrow" :class="{i_arrow_hidden: !showProducts}"></i>
            Сведения о товаре / продукции
          </a>
        </div>
      </div>
      <div class="content-tab-indent" v-if="showProducts">
        <div class="row">
          <div class="col-md-12">
            <div class="control-panel control-panel-top" v-scroll="handleScroll" v-if="requestItem.UF_IS_EDIT">
              <div class="control-panel__item">
                <a @click="addObj()" class="control-panel__link control-add_link">Добавить</a>
              </div>
              <div class="control-panel__item">
                <a @click="showCopyObj" class="control-panel__link control-copy_link">добавить с
                  копированием</a>
              </div>
              <div class="control-panel__item" v-if="requestItem.UF_IS_EDIT">
                <a @click="deleteRequest" title="Удалить" class="control-delete_link"></a>
              </div>
              <div class="control-panel__search">
                <input type="text" class="small_search" v-model="filterName" placeholder="Наименование">
                <input type="text" class="small_search" v-model="filterVed"
                       placeholder="Код ТН ВЭД ЕАЭС">
              </div>
            </div>
            <!-- control-panel -->
            <div class="table-responsive production-area production-table-top-head" v-scroll="handleScroll">
              <table class="production-table">
                <thead>
                <tr>
                  <th v-if="requestItem.UF_IS_EDIT" style="width: 30px"><input type="checkbox" v-model="checkAllBox"
                                                                               @click="checkAll"></th>
                  <th class="cell-w_10 mob-cell-w_100">№ объекта</th>
                  <th class="cell-w_20 mob-cell-w_200">Наименование<span class="required_color">*</span></th>
                  <th class="cell-w_15 mob-cell-w_200">Код ТН ВЭД ЕАЭС<span class="required_color">*</span></th>
                  <th class="mob-cell-w_300">Описание<span class="required_color">*</span></th>
                  <th style="width: 10px;"></th>
                </tr>
                </thead>
              </table>
            </div>
            <!-- production-table -->
            <div class="table-responsive production-area">
              <table class="production-table">
                <thead>
                <tr>
                  <th v-if="requestItem.UF_IS_EDIT" style="width: 30px"><input type="checkbox" v-model="checkAllBox"
                                                                               @click="checkAll"></th>
                  <th class="cell-w_10 mob-cell-w_100">№ объекта</th>
                  <th class="cell-w_20 mob-cell-w_200">Наименование<span class="required_color">*</span></th>
                  <th class="cell-w_15 mob-cell-w_200">Код ТН ВЭД ЕАЭС<span class="required_color">*</span></th>
                  <th class="mob-cell-w_300">Описание<span class="required_color">*</span></th>
                  <th style="width: 10px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    :key="object.ID" v-for="(object, index) in objs()">
                  <td v-if="requestItem.UF_IS_EDIT">
                    <a :name="'pr'+index"></a>
                    <input type="checkbox" v-model="object.checked"/>
                  </td>
                  <td>
                    <div class="production-cell">{{ index + 1 }}</div>
                  </td>
                  <td>
                    <textarea-autosize :disabled="!requestItem.UF_IS_EDIT" class="production-cell" style="width: 100%"
                                       ref="namefield"
                                       v-model="object.UF_NAME"/>
                  </td>
                  <td>
                    <input :disabled="!requestItem.UF_IS_EDIT" class="production-cell" ref="tnvedfield"
                           style="width: 100%"
                           v-model="object.UF_TN_VED_CODE"/>
                  </td>
                  <td>
                    <div class="production-cell production-cell_desc">
                      <div class="production-cell_desc-text">
                        <textarea-autosize :disabled="!requestItem.UF_IS_EDIT"
                                           class="production-cell_desc-text"
                                           style="width: 100%;border: 0"
                                           v-model="object.UF_DESCRIPTION"/>
                        <input
                            type="file"
                            ref="img_file"
                            :alt="index"
                            accept=".jpeg,.jpg,.gif,.png"
                            @change="onChangePicFile"
                            style="display: none"
                        />
                        <img style="max-width: 500px" v-if="object.UF_PICTURE_BASE64"
                             @click="$refs['img_file'][index].click()" :src="object.UF_PICTURE_BASE64" alt=""/>
                        <img v-else-if="object.UF_PICTURE" @click="$refs['img_file'][index].click()"
                             :src="picUrl(object)" alt=""/>
                      </div>
                      <div class="production-cell__link" v-if="requestItem.UF_IS_EDIT">
                        <a title="Добавить изображение" v-if="!object.UF_PICTURE"
                           @click="$refs['img_file'][index].click()"
                           class="production-img_link"></a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="production-cell_scroll"></div>
                  </td>
                </tr>
                </tbody>
              </table>
              <span class="required_color">Все поля, отмеченные * обязательны для заполнения</span>
            </div>
            <!-- table-responsive -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- content-tab-indent -->
    </div>

    <div class="modal fade in show" tabindex="-1" v-if="showCopyModal" role="dialog"
         aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-middle" role="document">
        <div class="modal-content">
          <div class="modal-add-document modal-full" v-if="showCopyModal">
            <div class="modal-header main-clear">
              <span class="modal-title">укажите товар для копирования</span>
              <a @click="showCopyModal=false" class="modal-exit stl-FR"></a>
            </div>
            <div class="modal-body">
              <div class="modal_row main-clear">
                <div class="table-responsive">
                  <table class="filter-table">
                    <tbody>
                    <tr>
                      <td>
                        <select v-model="selectedItem"
                                class="main_input main_select filter-input">
                          <option :key="index" v-for="(o, index) in objs()"
                                  :value="index">
                            №{{ o.UF_NUMBER_OBJECT }} {{ o.UF_NAME }}, {{ o.UF_TN_VED_CODE }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal_row main-clear">
                <input class="link_style-blue modal-full_wid"
                       type="button" value="Добавить" @click="copyObj"/>
                <div class="link_style-white modal-full_wid"
                     @click="showCopyModal = false">отмена
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CardDocs :page="2"
              v-if="currentUser.ACTIVE_ROLE_CODE !== 'expert_org_manager' && !this.$route.params.copy && this.requestItem.ID > 0"></CardDocs>
    <CardExpertDocs :page="2"
                    v-if="currentUser.ACTIVE_ROLE_CODE === 'expert_org_manager' && !this.$route.params.copy && this.requestItem.ID > 0"></CardExpertDocs>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import {API_URL} from "@/common/config";
import Vue from "vue";
import CardDocs from "@/components/cabinet/request/CardDocs";
import CardExpertDocs from "@/components/cabinet/request/CardExpertDocs";
import Inputmask from "inputmask";

export default {
  name: "CardProduct",
  data() {
    return {
      ru: ru,
      filterName: "",
      filterVed: "",
      selectedItem: -1,
      toasterErrors: [],
      checkedItems: [],
      checkAllBox: false,
      showCopyModal: false,
      showProducts: true
    };
  },
  components: {CardDocs, CardExpertDocs},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country"
    ]);
  },
  computed: {
    ...mapGetters([
      "requestItem",
      "isRequestsLoading",
      "directory",
      "currentUser"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    objs() {
      let objs = this.requestItem.OBJECT;
      if (this.filterName.length > 0) {
        objs = objs.filter(function (el) {
          return el.UF_NAME.toString().toLowerCase().indexOf(this.filterName.toLowerCase()) > -1
        }, this);
      }
      if (this.filterVed.length > 0) {
        objs = objs.filter(function (el) {
          return el.UF_TN_VED_CODE.toLowerCase().indexOf(this.filterVed.toLowerCase()) > -1
        }, this);
      }
      return objs;
    },
    addObj() {
      this.requestItem.OBJECT.push({
        UF_REQUEST_ID: this.requestItem.ID,
        UF_NUMBER_OBJECT: (this.requestItem.OBJECT.length + 1),
        UF_NAME: "",
        UF_TN_VED_CODE: "",
        UF_DESCRIPTION: "",
        UF_PICTURE: "",
        isEdit: false
      })

      this.editProduct(this.requestItem.OBJECT.length - 1)
    },
    copyObj() {
      this.requestItem.OBJECT.push({
        UF_REQUEST_ID: this.requestItem.ID,
        UF_NUMBER_OBJECT: (this.requestItem.OBJECT.length + 1),
        UF_NAME: this.requestItem.OBJECT[this.selectedItem].UF_NAME,
        UF_TN_VED_CODE: this.requestItem.OBJECT[this.selectedItem].UF_TN_VED_CODE,
        UF_DESCRIPTION: this.requestItem.OBJECT[this.selectedItem].UF_DESCRIPTION,
        UF_PICTURE: this.requestItem.OBJECT[this.selectedItem].UF_PICTURE,
        isEdit: false
      })
      this.showCopyModal = false
      this.editProduct(this.requestItem.OBJECT.length - 1)
    },
    showCopyObj() {
      this.selectedItem = 0
      this.showCopyModal = true
    },
    deleteObj(index) {
      this.requestItem.OBJECT.splice(index, 1);
      this.requestItem.OBJECT.forEach(function (o, i) {
        o.UF_NUMBER_OBJECT = i + 1;
      })
    },
    picUrl(object) {
      return API_URL + object.UF_PICTURE;
    },
    onChangePicFile(e) {
      let files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + " kB",
            base64: reader.result,
            file: file
          };
          //this.uFile = file.name;

          Vue.set(this.requestItem.OBJECT[e.target.alt], 'UF_PICTURE_BASE64', fileInfo.base64);
        }; // reader.onload
      } // for
    },
    editProduct(index) {
      if (!this.requestItem.OBJECT[index].isEdit) {
        this.requestItem.OBJECT[index].isEdit = true
        let _this = this
        setTimeout(function () {
          let tnvedmask = new Inputmask({
            mask: "9999 99 999 9",
            greedy: false,
            definitions: {
              "*": {
                validator: "[0-9-]",
                casing: "lower"
              }
            }
          });
          tnvedmask.mask(_this.$refs.tnvedfield);
          location.hash = "#pr" + (_this.requestItem.OBJECT.length - 1);
        }, 100)
      } else {
        this.requestItem.OBJECT[index].isEdit = false
      }
    },
    checkAll() {
      this.requestItem.OBJECT.forEach(function (i, index) {
        this.requestItem.OBJECT[index].checked = !this.checkAllBox
      }, this)
    },
    deleteRequest() {
      if (confirm("Вы действительно хотите удалить выбранные записи?")) {
        this.requestItem.OBJECT.forEach(function (i, k) {
          if (i.checked) {
            this.requestItem.OBJECT.splice(k, 1)
          }
        }, this)
        this.requestItem.OBJECT.forEach(function (i, k) {
          if (i.checked) {
            this.requestItem.OBJECT.splice(k, 1)
          }
        }, this)
      }
    },
    handleScroll: function (evt, el) {
      if (window.scrollY > 320) {
        if (el.classList.contains('control-panel-top')) {
          el.classList.add("control-panel_fix")
        }
        if (el.classList.contains('production-table-top-head')) {
          el.classList.add("production-table-top-head_fix")
        }
      } else {
        if (el.classList.contains('control-panel-top')) {
          el.classList.remove("control-panel_fix")
        }
        if (el.classList.contains('production-table-top-head')) {
          el.classList.remove("production-table-top-head_fix")
        }
      }
    }
  },
  directives: {
    scroll: {
      // определение директивы
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },
}
</script>

<style scoped>

</style>