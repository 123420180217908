<template>
    <div class="auth-area__logo">
        <h1 class="area-logo__title">ис-нейва</h1>
        <h3 class="area-logo__suptitle">информационная система взаимодействия участников ВЭД</h3>
    </div>
</template>

<script>
    export default {
        name: "Logo"
    }
</script>

<style scoped>

</style>