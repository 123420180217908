<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <div v-if="managerIsLoading"
           class="content-indent-wrapper"
      >
        <div v-if="!manager"
             class="content-indent"
        >
          <div class="breadcrumbs">
            <router-link :to="{name: 'LK/request-kp'}">Запросы КП</router-link>
            >
            <span v-if="!isACopy && !!kpItem.ID">
              Запрос КП Рег. № {{ kpItem.UF_REG_NUMBER }} от {{ kpItem.UF_DATE_CREATE | dateFormat }}
            </span>
            <span v-else-if="isACopy">
              Копирование запроса КП Рег. № {{ kpItem.UF_REG_NUMBER }} от {{ kpItem.UF_DATE_CREATE | dateFormat }}
            </span>
            <span v-else>Создание запроса</span>
          </div>
          <div class="page-top-title">
            <div v-if="!!kpItem"
                 class="page-top-title__item"
            >
              <h2 class="content-title">Запрос КП</h2>
              <h2 v-if="!!kpItem.ID && !isACopy"
                  class="content-title"
              >
                Рег. номер {{ kpItem.UF_REG_NUMBER }} от
                {{ kpItem.UF_DATE_CREATE }}
                {{ getStatus(directory.status_zapros_kp) }}
              </h2>
            </div>
            <div class="page-top-title__item text-right">
            </div>
          </div>
          <div v-if="!isKPLoading || isLoaded"
               class="content-tab-area"
          >
            <ul class="content-tab-menu">
              <li class="tab-menu__item tab_active">
                Запрос КП
              </li>
            </ul>
            <div class="content-tab__panel tab_active">
              <div class="container-fluid">
                <ReqKP/>
              </div>
            </div>
            <div class="content-tab__panel tab_active">
              <div class="container-fluid">
                <div class="content-tab-wrapper">
                  <div class="btn-box">
                    <button v-if="kpItem.UF_IS_EDIT || isACopy"
                            class="link_style-blue"
                            @click="editKP"
                            type="button"
                    >
                      Сохранить
                    </button>
                    <button v-if="kpItem.UF_IS_EDIT && $route.params.id"
                            class="link_style-blue"
                            @click="sendKP"
                            type="button"
                    >
                      Отправить запрос
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReqKPManager v-else/>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import ReqKP from "@/components/cabinet/kp/ReqKP";
import Footer from "@/views/site/Footer";
import Header from "@/views/site/Header";
import MainMenu from "@/components/cabinet/MainMenu";
import moment from "moment";
import {mapGetters} from "vuex";
import {KPService} from "@/common/api.service";
import ReqKPManager from "@/components/cabinet/kp/ReqKPManager";

export default {
  name: "ResponseKP",
  components: {ReqKP, ReqKPManager, MainMenu, Header, Footer},

  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },

  data() {
    return {
      tosterErrors: [],
      isACopy: false,
      isLoaded: false,
      manager: undefined,
      managerIsLoading: false,
      veoName: '',
      countries: [],
    }
  },

  computed: {
    ...mapGetters([
      "kpItem",
      "kpItemManager",
      "kpItemManagerOrg",
      "directory",
      "isKPLoading",
      "currentUser",
    ]),
  },

  async created() {
    this.isACopy = this.$route.path.includes('copy');
    this.manager = this.$store.getters.currentUser.ACTIVE_ROLE === 10;
    await this.fetchItem();
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country",
      "status_zapros_kp",
    ]);
  },

  async mounted() {
    this.managerIsLoading = true;
  },

  methods: {
    async fetchItem() {
      if (this.manager) {
        this.$store.dispatch('fetchKPItemManager', this.$route.params.id).then(async () => {
          document.title = unescape(this.kpItemManager.META_TITLE);
        }, this)
      } else {
        this.$store.dispatch('fetchKPItem', this.$route.params.id).then(() => {
          document.title = unescape(this.kpItem.META_TITLE);
          this.isLoaded = true;
        }, this)
      }
    },

    getStatusName: function (id) {
      let t = this.directory.status_zapros_kp.filter(c => c.ID === id);
      if (t.length > 0) {
        return t[0].UF_NAME;
      }
    },

    sendKP() {
      KPService.sendKP(this.kpItem.ID, this.kpItem).then(({data}) => {
        this.$route.params.id = data.ID
        this.fetchItem()
        if (data.errorAr.length > 0) {
          data.errorAr.forEach(function (err) {
            this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.tosterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
        } else {
          this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
              'Запрос КП отправлен, Рег. номер ' + data.UF_REG_NUMBER,
              {
                theme: "green",
                position: "bottom-right",
                duration: 5000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
        // location.hash = "#htop";
      }, this)
    },

    editKP() {
      if (this.isACopy || !this.kpItem.ID) {
        KPService.createKP(this.kpItem).then(({data}) => {
          this.$route.params.id = data.ID;
          if (data.errorAr.length > 0) {
            data.errorAr.forEach(function (err) {
              this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                  err.TEXT,
                  {
                    theme: "bubble",
                    position: "bottom-right",
                    duration: null,
                    action: [
                      {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                          toastObject.goAway(0);
                        }
                      },
                      {
                        text: "Закрыть все",
                        onClick: () => {
                          this.tosterErrors.forEach(function (err) {
                            err.goAway(0);
                          });
                        }
                      }
                    ]
                  }
              );
            }, this);
          } else {
            this.isACopy = false;
            this.fetchItem();
            this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                'Запрос КП создан, Рег. номер ' + data.UF_REG_NUMBER,
                {
                  theme: "green",
                  position: "bottom-right",
                  duration: 5000,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                }
            );
          }
          // location.hash = "#htop";
        }, this)
      } else {
        this.tosterErrors.forEach(function (err) {
          err.goAway(0);
        });
        KPService.editKP(this.kpItem.ID, this.kpItem).then(({data}) => {
          this.fetchItem()
          if (data.errorAr.length > 0) {
            data.errorAr.forEach(function (err) {
              this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                  err.TEXT,
                  {
                    theme: "bubble",
                    position: "bottom-right",
                    duration: null,
                    action: [
                      {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                          toastObject.goAway(0);
                        }
                      },
                      {
                        text: "Закрыть все",
                        onClick: () => {
                          this.tosterErrors.forEach(function (err) {
                            err.goAway(0);
                          });
                        }
                      }
                    ]
                  }
              );
            }, this);
          } else {
            this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                'Проект сохранен',
                {
                  theme: "green",
                  position: "bottom-right",
                  duration: 3000,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                }
            );
          }
        }, this)
      }
    },

    getStatus(data) {
      const status = data.filter(el => {
        if (el.ID === this.kpItem.UF_STATUS_ID) {
          return el;
        }
      })
      if (status[0] && !!status[0].UF_NAME.length) {
        return status[0].UF_NAME;
      }
    }
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    }
  },

  beforeRouteLeave(to, from, next) {
    this.tosterErrors.forEach(function (err) {
      err.goAway(0);
    });
    next();
  }
}
</script>
