<template>
  <div class="content-tab-area">
    <!-- START content-tab__panel  -->
    <div class="content-tab__panel tab_active">
      <div class="container-fluid">
        <div class="content-tab-wrapper">
          <div class="content-tab-indent">
            <div class="control-panel">
              <div class="control-panel__item">
                <router-link :to="{name: 'LK/request/create'}"
                             class="control-panel__link control-add_link">оформить новую
                </router-link>
              </div>
              <div class="control-panel__item">
                <a @click="showCopyModal=true" class="control-panel__link control-copy_link">оформить с
                  копированием</a>
              </div>
              <div class="control-panel__item">
                <div class="control-parent_item">
                  <a @click="downloadReport" title="Загрузить отчет" class="control-xls_link"></a>
                </div>
                <a @click="deleteRequest" title="Удалить заявку" class="control-delete_link"></a>
              </div>
              <div class="control-panel__item">
                <div class="control-parent_item">
                  <a title="Настройка фильтра" class="control-panel__link control-funnel_link"
                     @click="showFilterModal=true"> </a>
                </div>
                <div class="control-parent_item">
                  <a title="Группировка" @click="showGroupModal=true"
                     class="control-panel__link control-diagram_link"></a>
                </div>
              </div>
              <div class="control-panel__search">
                <select v-model="filter.status" @change="fetchRequests" class="small_search"
                        style="background: none">
                  <option value="">Все статусы</option>
                  <option v-for="item in directory.status_request" :key="item.ID" :value="item.ID">
                    --{{ item.UF_NAME }}
                  </option>
                </select>
                <select v-model="filter.country" @change="fetchRequests" class="small_search"
                        style="background: none">
                  <option value="">Все страны</option>
                  <option v-for="item in directory.country" :key="item.ID" :value="item.ID">
                    --{{ item.UF_NAME }}
                  </option>
                </select>
              </div>
            </div>
            <div style="font-size: 14px;margin-top: 10px">
              <div v-html="filterString()"/>
              <div v-html="groupString()"/>
            </div>
            <!-- control-panel -->
            <div class="table-responsive production-area">
              <table class="production-table_child" v-if="!isRequestsLoading">
                <thead>
                <tr>
                  <th><input type="checkbox" v-model="checkAllBox" @click="checkAll"></th>
                  <th class="mob-cell-w_120" v-bind:key="item.NAME"
                      v-for="item in requests.settings.reestrFields">
                    <div class="table-th-wrap">
                      <span class="table-th-wrap__name">{{ item.NAME }}</span>
                      <i class="i-panel_orient" :class="{
                                                orient_active: sortColumn===item.FIELD,
                                                asc:  sortOrder==='ASC',
                                                desc: sortOrder==='DESC',
                                            }" v-if="item.SORT" @click="setOrder(item.FIELD)"></i>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="" v-for="item in requests.items" v-bind:key="item.ID">
                  <td><input type="checkbox" @click="checkItem(item.ID)"
                             :checked="isChecked(item.ID)"/></td>
                  <td :class="compositeItem(item, field.FIELD).CLASS" v-bind:key="field.NAME" v-for="field in requests.settings.reestrFields">
                    
                    <router-link v-if="field.IS_LINK===1"
                                 :to="{name: 'LK/request/edit', params: { id: item.ID }}">
                      {{ item[field.FIELD] }}
                    </router-link>
                    <span v-else-if="field.TYPE==='normal'">{{ item[field.FIELD] }}</span>
                    <span v-else-if="field.TYPE==='composite_normal'">{{ compositeValue(item, field.FIELD) }}</span>
                    <span v-else-if="field.TYPE==='composite_array'">
                                            <div v-for="object in compositeArrayValue(item, field.FIELD)"
                                                 v-bind:key="object">{{ object }}</div>
                                        </span>
                  </td>
                </tr>
                <tr class="" v-for="(gitem, gindex) in glist" v-bind:key="gitem.ID">
                  <td v-if="!gitem.isItem && !gitem.hide"
                      :colspan="requests.settings.reestrFields.length+1">
                    <span :style="{marginLeft: gitem.deep*10+'px'}"></span>
                    <a style="color:black;font-weight: bold;" @click="toggleGroup(gindex)">
                                            <span class="row-parent_switch" :class="{
                                                'row-parent_switch_close': !gitem.hideNext,
                                            }"></span>{{ gitem.name }}</a></td>
                  <td v-if="gitem.isItem && !gitem.hide">
                    <span :style="{marginLeft: gitem.deep*10+'px'}"></span>
                    <input type="checkbox" @click="checkItem(gitem.it.ID)"
                           :checked="isChecked(gitem.it.ID)"/></td>
                  <template v-if="gitem.isItem && !gitem.hide">
                    <td v-bind:key="field.NAME" v-for="field in requests.settings.reestrFields">
                      <router-link v-if="field.FIELD==='UF_NUMBER'"
                                   :to="{name: 'LK/request/edit', params: { id: gitem.it.ID }}">
                        {{ gitem.it.UF_NUMBER }}
                      </router-link>
                      <span v-else-if="field.TYPE==='normal'">{{ gitem.it[field.FIELD] }}</span>
                      <span
                          v-else-if="field.TYPE==='composite_normal'">{{ compositeValue(gitem.it, field.FIELD) }}</span>
                      <span v-else-if="field.TYPE==='composite_array'">
                                                <div v-for="object in compositeArrayValue(gitem.it, field.FIELD)"
                                                     v-bind:key="object">{{ object }}</div>
                                            </span>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>

              <div class="main-pagination table-pagination">
                <Pagination
                    :pages="pages"
                    :currentPage.sync="currentPage"
                />
                <span class="pagination_all">Всего: {{ requests.counts }}</span>
                <span>Выводить по: </span>
                <select class="pagination__select" v-model="itemsPerPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>

            <div class="modal fade in show" tabindex="-1" v-if="showFilterModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-add-document modal-full">
                    <div class="modal-header main-clear">
                      <span class="modal-title">настройка фильтра</span>
                      <a @click="showFilterModal=false" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <div class="modal_row main-clear">
                        <div class="table-responsive">
                          <table class="filter-table">
                            <tbody>
                            <tr :key="filter.name" v-for="(filter, fi) in filters">
                              <td>
                                <select @change="changeFilterIndex(filter)"
                                        v-model="filter.index"
                                        class="main_input main_select filter-input">
                                  <option :key="item.NAME" :value="index"
                                          v-for="(item, index) in requests.settings.filterFields">
                                    {{ item.NAME }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select v-model="filter.operation"
                                        class="main_input main_select filter-input">
                                  <option :key="item.NAME" :value="index"
                                          v-for="(item, index) in requests.settings.filterFields[filter.index].OPERATION">
                                    {{ item.NAME }}
                                  </option>
                                </select>
                              </td>
                              <td style="width: 250px">
                                <input v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='string'"
                                       type="text" class="main_input filter-input"
                                       v-model="filter.value">
                                <input style="margin-top: 5px"
                                       v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='string' && requests.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                       type="text" class="main_input filter-input"
                                       v-model="filter.value2">
                                <Multiselect
                                    label="UF_NAME"
                                    :loading="!filter.listRendered"
                                    :limit="1"
                                    :limitText="filterItemText"
                                    track-by="ID"
                                    v-model="filter.multivalue"
                                    :options="filter.objects"
                                    :multiple="true" placeholder=""
                                    v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='multiselect'"/>
                                <Multiselect
                                    label="UF_NAME"
                                    :loading="!filter.listRendered"
                                    :limit="1"
                                    :limitText="filterItemText"
                                    track-by="ID"
                                    v-model="filter.multivalue2"
                                    :options="filter.objects"
                                    :multiple="true" placeholder=""
                                    style="margin-top: 5px"
                                    v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='multiselect' && requests.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"/>
                                <datepicker
                                    v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='datetime'"
                                    v-model="filter.value"
                                    :language="ru"
                                    :format="dateFormat" style="width: 100%"
                                    input-class="main_input filter-input"
                                ></datepicker>
                                <datepicker
                                    v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='datetime'
                                                             && requests.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                    v-model="filter.value2"
                                    :language="ru"
                                    :format="dateFormat"
                                    style="margin-top: 5px;width: 100%"
                                    input-class="main_input filter-input"
                                ></datepicker>
                                <select v-if="requests.settings.filterFields[filter.index].FIELD_TYPE==='select'"
                                        type="text" class="main_input filter-input"
                                        v-model="filter.value">
                                  <option :key="item.ID" :value="item.ID"
                                          v-for="item in filter.objects">
                                    {{ item.UF_NAME }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select v-model="filter.logic"
                                        class="main_input main_select filter-input">
                                  <option :key="item.NAME" :value="index"
                                          v-for="(item, index) in requests.settings.filterFields[filter.index].LOGIC">
                                    {{ item.NAME }}
                                  </option>
                                </select>
                              </td>
                              <td class="text-center"><a @click="filters.splice(fi, 1)"><i
                                  class="control-delete_link"></i></a></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal_row">
                        <a class="control-panel__link control-add_link"
                           @click="filters.push({index:0, operation:0, logic: 0, value: '', value2: '', objects:[], listRendered: false, multivalue:[], multivalue2:[]})">добавить
                          фильтр</a>
                      </div>
                      <div class="modal_row main-clear">
                        <div class="link_style-blue modal-full_wid" @click="fetchRequests">
                          применить
                        </div>
                        <div class="link_style-blue link_style-reset modal-full_wid"
                             @click="clearFilters()">
                          сбросить фильтры
                        </div>
                        <div class="link_style-white modal-full_wid"
                             @click="showFilterModal=false">отмена
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade in show" tabindex="-1" v-if="showCopyModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog modal-middle" role="document">
                <div class="modal-content">
                  <div class="modal-add-document modal-full" v-if="showCopyModal">
                    <div class="modal-header main-clear">
                      <span class="modal-title">укажите заявку для копирования</span>
                      <a @click="showCopyModal=false" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <div class="modal_row main-clear">
                        <div class="table-responsive">
                          <table class="filter-table">
                            <tbody>
                            <tr>
                              <td>
                                <select v-model="copyID"
                                        class="main_input main_select filter-input">
                                  <option :key="o.ID" v-for="o in copyData"
                                          :value="o.ID">заявка
                                    Рег. № {{ o.UF_NUMBER }} от {{ o.UF_DATE_CREATE }}
                                  </option>
                                </select>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal_row main-clear">
                        <router-link class="link_style-blue modal-full_wid" v-if="copyID"
                                     :to="{name: 'LK/request/copy', params: { id: copyID, copy:1 }}">
                          Создать
                        </router-link>
                        <div class="link_style-white modal-full_wid"
                             @click="showCopyModal = false">отмена
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade in show" tabindex="-1" v-if="showGroupModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog modal-middle" role="document">
                <div class="modal-content">
                  <div class="modal-add-document">
                    <div class="modal-header main-clear">
                      <span class="modal-title">настройка группировки</span>
                      <a @click="showGroupModal=false" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <div class="modal_row main-clear">
                        <div class="table-responsive">
                          <table class="filter-table">
                            <tbody>
                            <tr :key="group.name" v-for="(group, gi) in groups">
                              <td>
                                <select v-model="group.index"
                                        class="main_input main_select filter-input">
                                  <option :key="item.NAME" :value="item.POS"
                                          v-for="item in groupFields(gi, requests.settings.groupFields)">
                                    {{ item.NAME }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                уровень {{ gi + 1 }}
                              </td>
                              <td class="text-center"><a @click="groups.splice(gi, 1)"><i
                                  class="control-delete_link"></i></a></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal_row">
                        <a class="control-panel__link control-add_link"
                           v-if="groupFields(-1, requests.settings.groupFields).length > 0"
                           @click="groups.push({})">добавить уровень группировки</a>
                      </div>
                      <div class="modal_row main-clear">
                        <div class="link_style-blue modal-full_wid" @click="fetchRequests">
                          применить
                        </div>
                        <div class="link_style-blue link_style-reset modal-full_wid"
                             @click="clearGroups">
                          сбросить группировку
                        </div>
                        <div class="link_style-white modal-full_wid"
                             @click="showGroupModal = false">
                          отмена
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-add-document" v-if="showRequestModal" style="min-width: 310px">
              <div class="modal-header main-clear">
                <span class="modal-title">Сформировать отчет</span>
                <a href="#" @click="closeRequestModal" class="modal-exit stl-FR"></a>
              </div>
              <div class="modal-body">
                <span>{{ rulesErr }}</span>
                <div class="modal_row main-clear">
                  <span class="modal-field_name">Дата отчета    </span>
                  <span>
                                        <datepicker
                                            v-model="rulesDate"
                                            :language="ru"
                                            :format="dateFormat"
                                            input-class="modal_input stl-FR"
                                        ></datepicker>
                                        </span>
                </div>
                <div class="modal_row main-clear" style="width: 290px" v-if="textQuestion">
                  {{ textQuestion }}
                </div>
                <div class="modal_row main-clear">
                  <button v-if="!textQuestion" class="link_style-blue modal_btn_wid"
                          @click="checkRules">создать
                  </button>
                  <button v-if="!textQuestion" class="link_style-white stl-FR modal_btn_wid"
                          @click="closeRequestModal">отмена
                  </button>
                  <button v-if="textQuestion" class="link_style-blue modal_btn_wid"
                          @click="createRequest">да
                  </button>
                  <button v-if="textQuestion" class="link_style-white stl-FR modal_btn_wid"
                          @click="closeRequestModal">нет
                  </button>
                </div>
              </div>
            </div>

            <div class="modal-add-document" v-if="showDownloadRequestModal" style="min-width: 310px">
              <div class="modal-header main-clear">
                <span class="modal-title">Загрузить отчет</span>
                <a href="#" @click="showDownloadRequestModal = false" class="modal-exit stl-FR"></a>
              </div>
              <div class="modal-body">
                <span>{{ rulesErr }}</span>
                <div class="modal_row main-clear">
                  <span class="modal-field_name">Дата отчета    </span>
                  <span>
                                        <datepicker
                                            v-model="requestDate"
                                            :language="ru"
                                            :format="dateFormat"
                                            input-class="modal_input stl-FR"
                                        ></datepicker>
                                        </span>
                </div>
                <div class="modal_row main-clear">
                  <button class="link_style-blue modal_btn_wid"
                          @click="downloadRequest">загрузить
                  </button>
                </div>
              </div>
            </div>
            <!-- table-responsive -->
          </div>
          <!-- content-tab-indent -->
        </div>
        <!-- content-tab-wrapper -->
      </div>
      <!-- container-fluid -->
    </div>
    <!-- END content-tab__panel  -->
  </div>
  <!-- content-tab__panel -->
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from "@/components/site/Pagination";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import ApiService, {RequestService} from "@/common/api.service";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import {API_URL} from "@/common/config";

export default {
  name: "RequestList",
  components: {Pagination, Datepicker, Multiselect},
  data() {
    return {
      itemsPerPage: 20,
      currentPage: 1,
      sortColumn: "UF_DATE_CREATE",
      sortOrder: "DESC",
      ru: ru,
      showDownloadRequestModal: false,
      showFilterModal: false,
      showGroupModal: false,
      showCopyModal: false,
      copyID: 0,
      requestDate: null,
      showRequestModal: false,
      rulesDate: null,
      rulesErr: null,
      textQuestion: "",
      checkedItems: [],
      checkAllBox: false,
      filter: {
        status: "",
        country: ""
      },
      groups: [],
      filters: [],
      applyFilters: [],
      applyGroups: [],
      glist: [],
      copyData: []
    };
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "status_request",
      "country"
    ]);
    await this.fetchRequests()
  },
  computed: {
    ...mapGetters([
      "requests",
      "isRequestsLoading",
      "directory"
    ]),
    listConfig() {
      const q = {
        page: this.currentPage,
        limit: this.itemsPerPage,
        filter: "",
        group: [],
        filterStatus: null,
        filterCountry: null,
        filterPeriod: {}
      };
      if (this.sortColumn !== "") {
        let sort = {
          column: "",
          order: ""
        };
        sort.column = this.sortColumn;
        sort.order = this.sortOrder;
        q.sort = sort;
      }
      if (this.filter.status > 0) {
        q.filterStatus = this.filter.status
      }
      if (this.filter.country > 0) {
        q.filterCountry = this.filter.country
      }
      let filterarr = []
      this.filters.forEach(function (filter) {
        filterarr.push({
          FIELD: this.requests.settings.filterFields[filter.index].FIELD,
          OPERATION: this.requests.settings.filterFields[filter.index].OPERATION[filter.operation].CODE,
          VALUE: filter.value, VALUE2: filter.value2,
          LOGIC: this.requests.settings.filterFields[filter.index].LOGIC[filter.logic].CODE,
          RUNTIME: this.requests.settings.filterFields[filter.index].RUNTIME,
          CODE_RUNTIME: this.requests.settings.filterFields[filter.index].CODE_RUNTIME
        })
      }, this);
      q.filter = ""
      if (filterarr.length) {
        q.filter = Buffer.from(JSON.stringify(filterarr)).toString("base64");
      }
      if (this.filters.length > 0) {
        Vue.set(this, 'applyFilters', JSON.parse(JSON.stringify(this.filters)))
      }

      this.groups.forEach(function (group) {
        q.group.push(this.requests.settings.groupFields[group.index].FIELD)
      }, this);

      if (this.groups.length > 0) {
        Vue.set(this, 'applyGroups', JSON.parse(JSON.stringify(this.groups)))
      }
      return q;
    },
    pages() {
      if (this.isRequestsLoading || this.requests.counts <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.requests.counts / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    }
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.page = newValue;
      this.fetchRequests();
    },
    itemsPerPage(newValue) {
      this.listConfig.limit = newValue;
      this.fetchRequests();
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  methods: {
    setOrder(column) {
      if (this.sortColumn !== column) {
        this.sortOrder = "ASC";
      } else {
        if (this.sortOrder === "DESC") {
          this.sortOrder = "";
          this.sortColumn = "";
        } else {
          this.sortOrder = "DESC";
        }
      }
      this.sortColumn = column;
      this.fetchRequests();
    },
    fetchRequests() {
      this.$store.dispatch('fetchRequests', this.listConfig).then(() => {
        document.title = unescape(this.requests.META_TITLE);
        this.showFilterModal = false
        this.showGroupModal = false
        if (this.requests.group.length > 0) {
          this.glist = []
          this.appendq(this.requests.group, 0)
        }
        RequestService.getListCopyRequest().then(({data}) => {
          this.copyData = data
        }, this)
      }, this)
    },
    downloadReport() {
      let conf = this.listConfig
      RequestService.downloadReport(conf.type, conf).then(({data}) => {
        window.open(API_URL.replace(new RegExp("[\\/]+$"), "") + data.link);
      }, this)
    },
    appendq(items, deep) {
      items.forEach(function (item) {
        this.glist.push({name: item.GROUP_NAME, deep: deep, isItem: false, hide: false, hideNext: false})
        if (item.GROUP_CHILDS.length) {
          this.appendq(item.GROUP_CHILDS, deep + 1)
        } else {
          item.GROUP_CHILDS.items.forEach(function (it) {
            this.glist.push({name: item.GROUP_NAME, deep: deep, isItem: true, it: it, hide: false})
          }, this)
        }
      }, this)
    },
    toggleGroup(ind) {
      let isStarted = false
      let deep = 0
      let hide = false
      this.glist.forEach(function (item, index) {
        if (isStarted && item.deep <= deep && !item.isItem) {
          isStarted = false
        }
        if (isStarted && item.deep >= deep) {
          item.hide = hide
          item.hideNext = hide
        }
        if (ind === index) {
          isStarted = true
          deep = item.deep
          item.hideNext = !item.hideNext
          hide = item.hideNext
        }
      }, this)
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    compositeValue(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v) {
        f = gv(f, v)
      })
      return f;
    },
    compositeArrayValue(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v, i) {
        if (i < fields.length - 1) {
          f = gv(f, v)
        }
      })
      let ret = []
      f.forEach(function (v) {
        ret.push(v[fields[fields.length - 1]])
      })
      return ret;
    },
    compositeItem(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v, i) {
        if (i < fields.length - 1) {
          f = gv(f, v)
        }
      })
      return f;
    },
    isChecked(id) {
      return this.checkedItems[id]
    },
    checkAll() {
      this.requests.items.forEach(function (i) {
        this.checkedItems[i.ID] = !this.checkAllBox
      }, this)
      this.glist.forEach(function (i) {
        if (i.isItem) {
          this.checkedItems[i.it.ID] = !this.checkAllBox
        }
      }, this)
    },
    checkItem(id) {
      if (this.checkedItems[id]) {
        delete this.checkedItems[id]
        this.checkAllBox = false
      } else {
        this.checkedItems[id] = true
      }
    },
    changeFilterIndex(filter) {
      filter.value = ""
      filter.value2 = ""
      filter.operation = 0
      filter.logic = 0
      filter.multivalue = []
      filter.multivalue2 = []
      if (this.requests.settings.filterFields[filter.index].LINK) {
        filter.listRendered = false
        ApiService.get2(this.requests.settings.filterFields[filter.index].LINK).then(({data}) => {
          filter.objects = data
          Vue.set(filter, 'listRendered', true)
        }, this)
      }
    },
    filterItemText(count) {
      return `и еще ${count}`
    },
    groupFields(index, fields) {
      let ret = []
      fields.forEach(function (f, fin) {
        let hasAdd = false
        this.groups.forEach(function (gr, gin) {
          if (index !== gin) {
            if (gr.index === fin) {
              hasAdd = true
            }
          }
        }, this)
        if (!hasAdd) {
          ret.push({NAME: f.NAME, POS: fin})
        }
      }, this)
      return ret
    },
    filterString() {
      let str = ""
      if (this.applyFilters.length > 0) {
        str += `Фильтр: `
        this.applyFilters.forEach(function (filter, ind) {
          let f = this.requests.settings.filterFields[filter.index]
          if (filter.value) {
            str += ` ${f.NAME} ${f.OPERATION[filter.operation].NAME} "${filter.value}"`
            if (filter.value2) {
              str += ` и "${filter.value2}"`
            }
            if (this.applyFilters[ind + 1] && (this.applyFilters[ind + 1].value || this.applyFilters[ind + 1].multivalue.length > 0)) {
              str += ` <strong>${f.LOGIC[filter.logic].NAME}</strong> `
            }
          }
          if (filter.multivalue.length > 0) {
            str += ` ${f.NAME} ${f.OPERATION[filter.operation].NAME} `
            filter.multivalue.forEach(function (val, i) {
              if (i > 0) {
                str += ', '
              }
              str += `"${val.UF_NAME}"`
            })

            if (this.applyFilters[ind + 1] && (this.applyFilters[ind + 1].value || this.applyFilters[ind + 1].multivalue.length > 0)) {
              str += ` <strong>${f.LOGIC[filter.logic].NAME}</strong> `
            }
          }
        }, this)
      }
      return str
    },
    groupString() {
      let str = ""
      if (this.applyGroups.length > 0) {
        str += `Группировка: `
        this.applyGroups.forEach(function (group, i) {
          if (i > 0) {
            str += ", "
          }
          str += this.requests.settings.groupFields[group.index].NAME
        }, this);
      }
      return str
    },
    deleteRequest() {
      if (confirm("Вы действительно хотите удалить выбранные записи?")) {
        let l = []

        this.checkedItems.forEach(function (i, k) {
          l.push({ID: k})
        })
        RequestService.deleteRequest(l).then(({data}) => {
          if (data.error) {
            this.$toasted.show(
                data.errorAr[0],
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: 3000,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                }
            );
          }
        })
        this.fetchRequests()
      }
    },
    clearFilters() {
      this.filters = []
      this.showFilterModal = false
      Vue.set(this, 'applyFilters', [])
      this.fetchRequests()
    },
    clearGroups() {
      this.groups = []
      this.showGroupModal = false
      Vue.set(this, 'applyGroups', [])
      this.fetchRequests()
    }
  }
}
</script>

<style scoped>
.modal_row .vdp-datepicker {
  display: inline-block;
}

.multiselect {
  max-width: 300px;
}

.multiselect__tag {
  background: #3d7aaa !important;
}
</style>