<template>
    <ul class="ul-pagination">
      <li v-if="currentPage > 1">
        <a @click.prevent="changePage(currentPage - 1)">&laquo;</a>
      </li>
      <li
              v-for="page in pages"
              :data-test="`page-link-${page}`"
              :key="page"
              :class="paginationClass(page)"
              @click.prevent="changePage(page)"
      >
        <a v-if="currentPage > page - 5 && currentPage < page + 5"
           href v-text="page" />
      </li>

      <li v-if="pages.length > 1 && currentPage < pages.length">
        <a @click.prevent="changePage(currentPage + 1)">&raquo;</a>
      </li>
    </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pages: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    changePage(goToPage) {
      if (goToPage === this.currentPage) return;
      this.$emit("update:currentPage", goToPage);
    },
    paginationClass(page) {
      return {
        "pagination__item": true,
        "pagination_active": this.currentPage === page
      };
    }
  }
};
</script>
