<template>
    <div class="auth-header__menu main-clear">
        <a href="#" class="btn-auth-mob" @click="toggleMobile" :class="{'btn-auth-mob_active': mmSHow}">
            <span></span>
        </a>
        <ul class="auth-menu" :class="{'auth-menu_active': mmSHow}">
            <li class="auth-menu__item">
                <router-link class="auth-menu__item" :to="{name: 'News'}">новости</router-link></li>
            <li class="auth-menu__item">
                <router-link class="auth-menu__item" :to="{name: 'Methods'}">Методические материалы</router-link></li>
            <li class="auth-menu__item">
                <router-link class="auth-menu__item" :to="{name: 'Tariffs'}">тарифы</router-link></li>
        </ul>
        <UserMenu/>
    </div>

</template>

<script>
    import UserMenu from "@/views/partial/UserMenu";
    export default {
        name: "Menu",
        components: {UserMenu},
        data() {
            return {
                mmSHow: false
            }
        },
        methods: {
            toggleMobile() {
                this.mmSHow = !this.mmSHow;
            }
        }
    }
</script>

<style scoped>

</style>