<template>
  <div class="content-tab-wrapper">
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">ИНН</div>
      <div class="col-md-9 col-lg-8 col-data"><input type="text" class="main_input"  v-model="orgItem.UF_INN"></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">ОГРН</div>
      <div class="col-md-9 col-lg-8 col-data"><input type="text" class="main_input"  v-model="orgItem.UF_OGRN"></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-4 col-name">Краткое наименование</div>
      <div class="col-md-9 col-lg-8 col-data"><input type="text" class="main_input"  v-model="orgItem.UF_NAME"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ru} from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  name: "OrgMainInfo",
  props: {
    orgItem: {
      type: Object
    }
  },
  async mounted() {

  },
  data() {
    return {
      ru: ru
    }
  },
  computed: {
    ...mapGetters([
      "directory"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    directoryVal(directory, id) {
      for (let i = 0; i < directory.length; i++) {
        if (directory[i].ID === id) {
          return directory[i]
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>

</style>