<template>
  <div class="table-responsive production-area">
    <table class="production-table_child" v-if="!isLoading">
      <thead>
      <tr v-for="n in items.settings.header_rows+1" :key="n">
        <th style="vertical-align: middle" v-if="showCheckField && n===1" :rowspan="items.settings.header_rows"><input
            type="checkbox" v-model="items.checkAllBox" @click="checkAll"></th>
        <th style="vertical-align: middle" v-bind:key="item.NAME"
            :colspan="item.colspan" :rowspan="item.rowspan"
            :class="'mob-cell-w_120 '+item.FIELD_ALIGN_CLASS+' '+item.CLASS"
            v-for="item in items.settings.reestrFields" v-show="item.row === n-1">
          <div class="table-th-wrap">
            <span class="table-th-wrap__name">{{ item.NAME }}</span>
            <i class="i-panel_orient" :class="{
                                                orient_active: items.sortColumn===item.FIELD,
                                                asc:  items.sortOrder==='ASC',
                                                desc: items.sortOrder==='DESC',
                                            }" v-if="item.SORT" @click="setOrder(item.FIELD)"></i>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="" v-for="item in items.items" v-bind:key="item.ID">
        <td v-if="showCheckField"><input type="checkbox" @click="checkItem(item.ID)"
                                         :checked="isChecked(item.ID)"/></td>
        <td v-bind:key="field.NAME" v-for="field in items.settings.reestrFields"
            :class="field.FIELD_ALIGN_CLASS"
            v-show="field.TYPE"
        >

          <router-link v-if="field.IS_LINK===1"
                       :to="{name: cardLink, params: { id: item.ID }}">
            {{ item[field.FIELD] }}
          </router-link>
          <span v-else-if="field.TYPE==='normal'">{{ item[field.FIELD] }}</span>
          <a @click="callMethod(field.method, compositeItem(item, field.FIELD).method_params)" v-else-if="field.TYPE==='call_method'">{{ compositeValue(item, field.FIELD)}}</a>
          <span
              :class="compositeItem(item, field.FIELD).CLASS"
              v-else-if="field.TYPE==='composite_normal' && (!field.isEdit || !compositeIsEdit(item, field.FIELD))">
            <input type="checkbox" class="main_check" v-if="field.ControlView === 'boolean'"
                   disabled
                   v-model="compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)]"
                   @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)])"
            />
            <span v-else-if="!compositeItem(item, field.FIELD).HTML_VALUE">{{ compositeValue(item, field.FIELD) }}</span>
            <span v-else-if="compositeItem(item, field.FIELD).HTML_VALUE"
                  v-html="compositeItem(item, field.FIELD).HTML_VALUE"></span>
          </span>
          <span v-else-if="field.TYPE==='composite_normal' && field.isEdit">
            <select v-if="field.ControlView === 'select'"
                    @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD).RECORD_ID)"
                    v-model="compositeItem(item, field.FIELD).RECORD_ID" class="main_input main_select input_full-size">
                                <option :key="i.ID" :value="i.ID"
                                        v-for="i in compositeItem(item, field.FIELD).LINK">{{ i.NAME }}</option>
                              </select>
            <input type="checkbox" class="main_check" v-else-if="field.ControlView === 'boolean'"
                   v-model="compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)]"
                   @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)])"
            />
            <datepicker
                v-else-if="field.ControlView === 'date'"
                v-model="compositeItem(item, field.FIELD).DATE_VALUE"
                @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)])"
                :language="ru"
                :format="dateFormat" style="width: 100%"
                input-class="main_input input_full-size small_calendar"
            ></datepicker>
                    <input class="main-input input_full-size" v-else type="text"
                           @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)])"
                           v-model="compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)]"/>
                  </span>
          <span v-else-if="field.TYPE==='composite_array'">
                                            <div v-for="object in compositeArrayValue(item, field.FIELD)"
                                                 v-bind:key="object">{{ object }}</div>
                                        </span>
        </td>
      </tr>
      <tr class="" v-for="(gitem, gindex) in glist" v-bind:key="gitem.ID">
        <td v-if="!gitem.isItem && !gitem.hide"
            :colspan="items.settings.reestrFields.length+1">
          <span :style="{marginLeft: gitem.deep*10+'px'}"></span>
          <a style="color:black;font-weight: bold;" @click="toggleGroup(gindex)">
                                            <span class="row-parent_switch" :class="{
                                                'row-parent_switch_close': !gitem.hideNext,
                                            }"></span>{{ gitem.name }}</a></td>
        <td v-if="gitem.isItem && !gitem.hide">
          <span :style="{marginLeft: gitem.deep*10+'px'}"></span>
          <input type="checkbox" @click="checkItem(gitem.it.ID)"
                 :checked="isChecked(gitem.it.ID)"/></td>
        <template v-if="gitem.isItem && !gitem.hide">
          <td v-bind:key="field.NAME" v-for="field in items.settings.reestrFields">
            <router-link v-if="field.IS_LINK===1"
                         :to="{name: cardLink, params: { id: item.ID }}">
              {{ item[field.FIELD] }}
            </router-link>
            <a @click="callMethod(field.method, compositeItem(item, field.FIELD).method_params)" v-else-if="field.TYPE==='call_method'">{{ compositeValue(item, field.FIELD)}}</a>
            <span v-else-if="field.TYPE==='normal'">{{ item[field.FIELD] }}</span>
            <span
                :class="compositeItem(item, field.FIELD).CLASS"
                v-else-if="field.TYPE==='composite_normal' && (!field.isEdit || !compositeIsEdit(item, field.FIELD))">
            <span v-if="!compositeItem(item, field.FIELD).HTML_VALUE">{{ compositeValue(item, field.FIELD) }}</span>
            <span v-if="compositeItem(item, field.FIELD).HTML_VALUE"
                  v-html="compositeItem(item, field.FIELD).HTML_VALUE"></span>

          </span>

            <span v-else-if="field.TYPE==='composite_normal' && field.isEdit">
            <select v-if="field.ControlView === 'select'"
                    @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD).RECORD_ID)"
                    v-model="compositeItem(item, field.FIELD).RECORD_ID" class="main_input main_select input_full-size">
                                <option :key="i.ID" :value="i.ID"
                                        v-for="i in compositeItem(item, field.FIELD).LINK">{{ i.NAME }}</option>
                              </select>
            <input type="checkbox" class="main_check" v-else-if="field.ControlView === 'boolean'"
                   v-model="compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)]"
                   @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)])"
            />
            <datepicker
                v-else-if="field.ControlView === 'date'"
                v-model="compositeItem(item, field.FIELD).DATE_VALUE"
                :language="ru"
                :format="dateFormat" style="width: 100%"
                input-class="main_input input_full-size small_calendar"
            ></datepicker>
                    <input class="main-input input_full-size" v-else type="text"
                           @change="updateValue(item, field.FIELD, compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)])"
                           v-model="compositeItem(item, field.FIELD)[compositeLastKey(field.FIELD)]"/>
                  </span>
            <span v-else-if="field.TYPE==='composite_array'">
                                            <div v-for="object in compositeArrayValue(item, field.FIELD)"
                                                 v-bind:key="object">{{ object }}</div>
                                        </span>
          </td>
        </template>
      </tr>
      </tbody>
    </table>

    <div class="main-pagination table-pagination" v-if="!isLoading">
      <Pagination
          :pages="pages"
          :currentPage.sync="currentPage"
      />
      <span class="pagination_all">Всего: {{ items.counts }}</span>
      <span>Выводить по: </span>
      <select class="pagination__select" v-model="itemsPerPage">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import Pagination from "@/components/site/Pagination";
import {ru} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "TableList",
  components: {Pagination, Datepicker},
  data() {
    return {
      ru: ru,
      glist: [],
      itemsPerPage: 20,
      currentPage: 1
    }
  },
  mounted() {
    this.itemsPerPage = this.items.itemsPerPage
    this.currentPage = this.items.currentPage
    if (this.items.groups.length > 0) {
      this.glist = []
      this.appendq(this.items.groups, 0)
    }
  },
  props: {
    isLoading: Boolean,
    items: Object,
    showCheckField: Boolean,
    ApiService: Object,
    cardLink: String
  },
  computed: {
    pages() {
      if (this.isLoading || this.items.counts <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.items.counts / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    }
  },
  watch: {
    currentPage(newValue) {
      this.$parent.listConfig.page = newValue;
      this.$parent.fetchList();
    },
    itemsPerPage(newValue) {
      this.$parent.listConfig.limit = newValue;
      this.$parent.fetchList();
    }
  },
  methods: {
    appendq(items, deep) {
      items.forEach(function (item) {
        this.glist.push({name: item.GROUP_NAME, deep: deep, isItem: false, hide: false, hideNext: false})
        if (item.GROUP_CHILDS.length) {
          this.appendq(item.GROUP_CHILDS, deep + 1)
        } else {
          item.GROUP_CHILDS.items.forEach(function (it) {
            this.glist.push({name: item.GROUP_NAME, deep: deep, isItem: true, it: it, hide: false})
          }, this)
        }
      }, this)
    },
    setOrder(column) {
      if (this.items.sortColumn !== column) {
        this.items.sortOrder = "ASC";
      } else {
        if (this.items.sortOrder === "DESC") {
          this.items.sortOrder = "";
          this.items.sortColumn = "";
        } else {
          this.items.sortOrder = "DESC";
        }
      }
      this.items.sortColumn = column;
      this.$parent.fetchList();
    },
    isChecked(id) {
      return this.items.checkedItems[id]
    },
    checkAll() {
      this.items.items.forEach(function (i) {
        this.items.checkedItems[i.ID] = !this.items.checkAllBox
      }, this)
      this.glist.forEach(function (i) {
        if (i.isItem) {
          this.items.checkedItems[i.it.ID] = !this.items.checkAllBox
        }
      }, this)
    },
    checkItem(id) {
      if (this.items.checkedItems[id]) {
        delete this.items.checkedItems[id]
        this.items.checkAllBox = false
      } else {
        this.items.checkedItems[id] = true
      }
    },
    compositeValue(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v) {
        f = gv(f, v)
      })
      return f;
    },
    compositeIsEdit(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v, i) {
        if (i < fields.length - 1) {
          f = gv(f, v)
        }
      })
      return f.isEdit === 1;
    },
    compositeItem(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v, i) {
        if (i < fields.length - 1) {
          f = gv(f, v)
        }
      })
      return f;
    },
    compositeLastKey(field) {
      let fields = field.split('.')
      return fields[fields.length - 1];
    },
    updateValue(item, key, value) {
      this.ApiService.updateField(item.ID, {
        FIELD_NAME: key,
        FIELD_VALUE: value,
      }).then(({data}) => {
        if (!data.error && data.item) {
          for (let i = 0; i <= this.items.items.length; i++) {
            if (data.item.ID === this.items.items[i].ID) {
              Vue.set(this.items.items, i, data.item)
              break
            }
          }
        }
      }, this)
    },
    compositeArrayValue(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v, i) {
        if (i < fields.length - 1) {
          f = gv(f, v)
        }
      })
      let ret = []
      f.forEach(function (v) {
        ret.push(v[fields[fields.length - 1]])
      })
      return ret;
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    callMethod(method, params) {
      this.$parent[method].apply(this.$parent, params)
    },
    toggleGroup(ind) {
      let isStarted = false
      let deep = 0
      let hide = false
      this.glist.forEach(function (item, index) {
        if (isStarted && item.deep <= deep && !item.isItem) {
          isStarted = false
        }
        if (isStarted && item.deep >= deep) {
          item.hide = hide
          item.hideNext = hide
        }
        if (ind === index) {
          isStarted = true
          deep = item.deep
          item.hideNext = !item.hideNext
          hide = item.hideNext
        }
      }, this)
    },
  }
}
</script>

<style scoped>

</style>