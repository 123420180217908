<template>
    <div>
        <div class="auth-footer">
            <FooterText/>
        </div>
        <Support/>
    </div>
</template>

<script>
    import FooterText from "@/views/partial/FooterText";
    import Support from "@/views/partial/Support";

    export default {
        name: "Footer",
        components: {Support, FooterText}
    }
</script>

<style scoped>

</style>