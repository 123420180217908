<template>
  <div class="content-tab__panel tab_active">
    <div class="container-fluid">
      <div class="content-tab-wrapper">
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name col-name_bold">1. Составитель заключения</div>
          <div class="col-md-9 col-lg-10 col-data">
            {{ directoryVal(directory.expert_org, conclusionItem.REQUEST.UF_COMPANY_EXPERT_ID).UF_NAME }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name col-name_bold">2. Сведения о внешнеэкономической операции</div>
          <div class="col-md-9 col-lg-10 col-data">
            {{ directoryVal(directory.veo, conclusionItem.REQUEST.UF_VEO_ID).UF_NAME }}
          </div>
        </div>
        <div class="content-child-box">
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">2.1. Документ, на основании которого осуществляется
              внешнеэкономическая операция
            </div>
            <div class="col-md-9 col-lg-10 col-data">Договор №
              {{ conclusionItem.REQUEST.DOGOVOR.NUMBER }} от
              {{ conclusionItem.REQUEST.DOGOVOR.DATE }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">2.2. Страна назначения</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ countries()}}
              {{ directoryVal(directory.country, conclusionItem.REQUEST.UF_COUNTRY_ID).UF_NAME }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">2.3. Российский участник внешнеэкономической операции</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ conclusionItem.VED_COMPANY_INFO.NAME }}, {{ conclusionItem.VED_COMPANY_INFO.ADDRES_JUR }}, тел.
              {{ conclusionItem.VED_COMPANY_INFO.PHONES.join(', ') }}, ИНН {{ conclusionItem.VED_COMPANY_INFO.INN }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name col-name_bold">2.4. Иностранные участники внешнеэкономической
              операции
            </div>
          </div>
          <div class="content-child-indent-box">
            <div class="row">
              <div class="col-md-3 col-lg-2 col-name">2.4.1. Покупатель</div>
              <div class="col-md-9 col-lg-10 col-data">{{ conclusionItem.REQUEST.UF_BUYER }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-lg-2 col-name">2.4.2. Потребители (конечные пользователи)</div>
              <div class="col-md-9 col-lg-10 col-data">{{ conclusionItem.REQUEST.UF_CONSUMER }}
              </div>
            </div>
          </div>
        </div>
        <!-- content-tab-indent -->
      </div>
      <!-- content-tab-wrapper -->
      <CardDocs :page="1"></CardDocs>

    </div>
    <!-- container-fluid -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CardDocs from "@/components/cabinet/conclusion/CardDocs";
import {Funcs} from "@/common/funcs";

export default {
  name: "MainInfo",
  components: {CardDocs},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country"
    ]);
  },
  computed: {
    ...mapGetters([
      "conclusionItem",
      "isConclusionsLoading",
      "directory"
    ]),
  },
  methods: {
    directoryVal(directory, id) {
      for (let i = 0; i < directory.length; i++) {
        if (directory[i].ID === id) {
          return directory[i]
        }
      }
      return {}
    },
    countries() {
      let s = []
      for (let i = 0; i < this.conclusionItem.REQUEST.UF_COUNTRY_ID.length; i++) {
        s.push(Funcs.getDirectoryValue(this.directory.country, this.conclusionItem.REQUEST.UF_COUNTRY_ID[i]).UF_NAME)
      }
      return s.join(", ")
    },
  }
}
</script>

<style scoped>

</style>