<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <div class="content-indent-wrapper">
        <div class="content-indent">
          <div class="page-top-title">
            <div class="page-top-title__item">
              <h2 class="content-title">ПРОФИЛЬ ОРГАНИЗАЦИИ</h2>
            </div>
            <div class="page-top-title__item text-right">
            </div>
          </div>
          <div class="content-tab-area">
            <ul class="content-tab-menu">
              <li class="tab-menu__item" :class="{tab_active: $route.name === 'LK/profile-org'}">
                <router-link class="main-menu__text_link" :to="{name: 'LK/profile-org'}">
                  Общая информация
                </router-link>
              </li>
<!--              <li class="tab-menu__item" :class="{tab_active: step === 2}" @click="setStep(2)">ЭЦП</li>-->
              <li class="tab-menu__item" :class="{tab_active: $route.name === 'LK/profile-org-accreditation'}">
                <router-link class="main-menu__text_link" :to="{name: 'LK/profile-org-accreditation'}">
                  Аккредитация
                </router-link>
              </li>
              <!--<li class="tab-menu__item" :class="{tab_active: step === 4}" @click="setStep(4)">Лицевой счет</li>-->
              <!--<li class="tab-menu__item" :class="{tab_active: step === 5}" @click="setStep(5)">Дополнительная информация</li>-->
            </ul>
            <OrgProfile v-if="$route.name === 'LK/profile-org'"/>
            <OrgAccreditation v-if="$route.name === 'LK/profile-org-accreditation'"/>
            <!--            todo тут должен быть компоненты для шагов 2,4,5-->
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/site/Footer";
import Header from "@/views/site/Header";
import OrgProfile from "@/components/cabinet/org/OrgProfile";
import MainMenu from "@/components/cabinet/MainMenu";
import OrgAccreditation from "@/components/cabinet/org/OrgAccreditation";

export default {
  name: "OrganizationProfile",
  components: {OrgAccreditation, MainMenu, OrgProfile, Header, Footer},
}
</script>

<style scoped>
</style>
