<template>
    <div class="main-wraper">
        <Header/>
        <section class="content-area">
            <MainMenu/>
            <div class="content-indent-wrapper">
                <div class="content-indent">
                    <div class="page-top-title">
                        <div class="page-top-title__item">
                            <h2 class="content-title">Реестр отчетов во ФСТЭК</h2>
                        </div>
                        <div class="page-top-title__item text-right">
                        </div>
                    </div>
                    <!-- page-top-title -->
                    <ReportList/>
                </div>
                <!-- content-indent -->
            </div>
            <!-- content-indent-wrapper -->
        </section>
        <!-- content-area -->
        <Footer />
    </div>
</template>

<script>
    import Footer from "@/views/site/Footer";
    import Header from "@/views/site/Header";
    import ReportList from "@/components/cabinet/report/ReportList";
    import MainMenu from "../../components/cabinet/MainMenu";
    export default {
        name: "ReestrFSTEK",
        components: {MainMenu, ReportList, Header, Footer},
    }
</script>

<style scoped>

</style>
