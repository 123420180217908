<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <a name="htop"></a>
      <MainMenu/>
      <div class="content-indent-wrapper">
        <div class="content-indent">
          <div class="breadcrumbs">
            <router-link :to="{name: 'LK/reestr-fstec'}">Реестр отчетов во ФСТЭК</router-link>
            >
            <span>{{reportItem.UF_NAME}}</span>
          </div>

          <!-- page-top-title -->
          <div class="content-tab-area" v-if="!isReportsLoading || isLoaded">

            <ReportTable></ReportTable>
            <!--ссылка на верх-->
          </div>
          <!-- content-tab-area -->
        </div>
        <!-- content-indent -->
      </div>
      <!-- content-indent-wrapper -->
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";
import {mapGetters} from "vuex";
import moment from "moment";
import MainMenu from "../../components/cabinet/MainMenu";
import ReportTable from "@/components/cabinet/report/ReportTable";

export default {
  name: "ReportCard",
  components: {ReportTable, MainMenu, Footer, Header},
  data() {
    return {
      step: 1,
      toasterErrors: [],
      isLoaded: false,
    }
  },
  async mounted() {
    await this.fetchItem()
  },
  computed: {
    ...mapGetters([
      "reportItem",
      "directory",
      "isReportsLoading"
    ]),
  },
  methods: {
    async fetchItem() {
      this.$store.dispatch('fetchReportItem', this.$route.params.id).then(() => {
        document.title = unescape(this.reportItem.META_TITLE);
        this.isLoaded = true
      }, this)
    },
    setStep(step) {
      this.step = step
    },
    getStatusName: function (id) {
      let t = this.directory.status_request.filter(c => c.ID === id);
      if (t.length > 0) {
        return t[0].UF_NAME;
      }
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    }
    ,
  },
  beforeRouteLeave(to, from, next) {
    this.toasterErrors.forEach(function (err) {
      err.goAway(0);
    });
    next();
  }
}
</script>

<style scoped>

</style>
