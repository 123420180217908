<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <Accreditation/>
    </section>
    <Footer/>
  </div>
</template>

<script>
import MainMenu from "@/components/cabinet/MainMenu";
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";
import Accreditation from "@/components/cabinet/operatorManager/Accreditation";

export default {
  name: "AccreditationOM",
  components: {MainMenu, Header, Footer, Accreditation},
}
</script>

<style scoped>

</style>