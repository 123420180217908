<template>
  <div class="auth-wrapper">
    <div class="auth-header__menu main-clear">
      <router-link :to="{name:'Login'}" class="prev-page" title="Назад"></router-link>
    </div>
    <div class="auth-content">
      <div class="auth-area">
        <Logo/>
        <ResetPassword/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Logo from '@/views/auth/Logo.vue';
  import Footer from "@/views/auth/Footer";
  import ResetPassword from "@/components/auth/ResetPassword";

  export default {
    name: 'Auth',
    metaInfo() {
      return {
        title: '"ИС-НЕЙВА", восстановление пароля',
      }
    },
    components: {
      ResetPassword,
      Logo,
      Footer,
    }
  }
</script>
