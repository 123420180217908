<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <div class="content-indent-wrapper">
        <div class="content-indent">
          <div class="page-top-title">
            <div class="page-top-title__item">
              <h2 class="content-title">ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ</h2>
            </div>
            <div class="page-top-title__item text-right">
            </div>
          </div>
          <!-- page-top-title -->
          <div class="content-tab-area">
            <div>
              <ul class="content-tab-menu">
                <li class="tab-menu__item" :class="{tab_active: step === 1}" @click="setStep(1)">Общие сведения</li>
                <li v-if="showOrgs" class="tab-menu__item" :class="{tab_active: step === 2}" @click="setStep(2)">
                  Организации
                </li>
              </ul>
            </div>
            <PersonalCabinet v-if="step === 1"/>
            <!--            todo тут должен быть компонент для организаций в профиле пользователя-->
          </div>
        </div>
        <!-- content-indent -->
      </div>
      <!-- content-indent-wrapper -->
    </section>
    <!-- content-area -->
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/site/Footer";
import Header from "@/views/site/Header";
import PersonalCabinet from "@/components/cabinet/personal/PersonalCabinet";
import MainMenu from "@/components/cabinet/MainMenu";

export default {
  name: "UserProfile",
  data() {
    return {
      step: 1,
      showOrgs: false,
    }
  },
  components: {MainMenu, PersonalCabinet, Header, Footer},
  mounted() {
    if (this.$store.getters.currentUser.ACTIVE_ROLE === 9 && this.$store.getters.currentUser.ACTIVE_ROLE_CODE === "broker") {
      this.showOrgs = true;
    }
    //ВРОДЕ КАК ЭТА ФУНКЦИЯ ТОЛЬКО ДЛЯ БРОКЕРОВ ДОЛЖНА БЫТЬ, НО МОЖНО РАЗБЛОКИРОВАТЬ
    // if(this.$store.getters.currentUser.ACTIVE_ROLE === 8 && this.$store.getters.currentUser.ACTIVE_ROLE_CODE === "users"){
    //   this.showOrgs = true;
    // }
  },
  methods: {
    setStep(step) {
      this.step = step;
    },
  },
}
</script>

<style scoped>

</style>
