<template>
  <div class="main-wraper" v-if="data.NAME">
    <Header/>
    <section class="content-area">
      <div class="content-indent">
        <div class="page-top-title">
          <div class="page-top-title__item">
            <h2 class="content-title">{{ data.NAME }}</h2>
          </div>
          <div class="page-top-title__item text-right">
          </div>
        </div>
        <div v-html="data.PREVIEW_TEXT"></div>
      </div>
      <!-- content-indent -->
    </section>
    <!-- content-area -->
    <Footer/>
  </div>
</template>
<script>
import {NewsService} from "@/common/api.service";
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";

export default {
  name: "Page",
  components: {Footer, Header},
  data() {
    return {
      data: {
        NAME: "",
        PREVIEW_TEXT: ""
      }
    }
  },
  mounted() {
    NewsService.servicePage(this.$route.params.url).then(
        ({data}) => {
          this.data = data
          document.title = unescape(data.META_TITLE);
        })
  }
}
</script>

<style scoped>

</style>