<template>
    <header class="header main-clear">
        <router-link :to="{name: 'Home'}" class="header-logo">ис-Нейва</router-link>
        <UserMenu/>
    </header>
</template>

<script>
    import UserMenu from "@/views/partial/UserMenu";
    export default {
        name: "Header",
        components: {UserMenu}
    }
</script>

<style scoped>

</style>