<template>
  <div class="auth-wrapper">
    <Menu/>
    <div class="auth-content">
      <div class="auth-area">
        <Logo/>
        <Welcome/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Welcome from '@/components/Welcome.vue'
  import Menu from '@/views/auth/Menu.vue'
  import Logo from '@/views/auth/Logo.vue'
  import Footer from "@/views/auth/Footer";

  export default {
    name: 'Home',
    components: {
      Footer,
      Logo,
      Welcome,
      Menu
    },

    beforeCreate: function () {
      document.body.className = 'gradient';
      document.documentElement.className = 'gradient';
    },

    destroyed() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.documentElement;
      body.classList.remove('gradient');
      html.classList.remove('gradient');
    },
  }
</script>
