<template>
  <div class="main-wraper">
    <Header/>
    <section class="content-area">
      <MainMenu/>
      <div class="content-indent-wrapper">
        <div class="content-indent">
          <div class="breadcrumbs">
            <router-link :to="{name: 'LK/request-kp'}">КП по Запросу</router-link>
          </div>
          <div class="page-top-title">
            <div class="page-top-title__item">
              <h2 class="content-title">
                КП по Запросу
              </h2>
            </div>
            <div class="page-top-title__item text-right">
            </div>
          </div>
          <div class="content-tab-area">
            <ul class="content-tab-menu">
              <li class="tab-menu__item tab_active">
                КП по запросу
              </li>
            </ul>
            <div class="content-tab__panel tab_active">
              <div class="container-fluid">
                <div class="content-tab__panel tab_active">
                  <div class="container-fluid">
                    <div class="content-tab-wrapper">
                      <div class="content-tab-indent">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="control-panel">
                              <a href="#" class="control-panel__link-mobil">Показать элементы управления (только на
                                мобилке)</a>
                              <div class="control-panel__item">
                                <a href="#" class="control-panel__link control-xls_link"></a>
                              </div>
                              <div class="control-panel__item">
                                <div class="control-parent_item">
                                  <a href="#" class="control-panel__link control-funnel_link">
                                    <i class="control-link_arrow"></i>
                                  </a>
                                  <div class="control-child_box">
                                    <ul>
                                      <li>1</li>
                                      <li>2</li>
                                      <li>3</li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="control-parent_item">
                                  <a href="#" class="control-panel__link control-diagram_link control-diagram_active">
                                    <i class="control-link_arrow"></i>
                                  </a>
                                  <div class="control-child_box">
                                    <ul>
                                      <li>1</li>
                                      <li>2</li>
                                      <li>3</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="control-panel__search">
                                <input type="text" class="small_search" placeholder="Экспертная организация">
                                <input type="text" class="small_search" placeholder="Регистрационный №">
                              </div>
                            </div>
                            <!-- control-panel -->
                            <div class="table-responsive production-area">
                              <table class="production-table_child">
                                <thead>
                                <tr>
                                  <th class="mob-cell-w_150">Регистрационный №</th>
                                  <th class="mob-cell-w_200">Экспертная организация</th>
                                  <th class="mob-cell-w_300">Внешнеэкономическая операция</th>
                                  <th class="mob-cell-w_100">Предмет операция</th>
                                  <th class="mob-cell-w_100 text-center">Срок (рабочие дни)</th>
                                  <th class="mob-cell-w_100">Цена с НДС <br> (тыс. руб.)</th>
                                  <th class="mob-cell-w_100">Дата ответа <i
                                      class="i-panel_orient orient_active filter_img_active"></i></th>
                                  <th class="text-center">Действие</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="row-parent">
                                  <td colspan="4"><i class="row-parent_switch_open"></i> <a href="#">56661/19</a> Вывоз
                                    из РФ
                                    1,2-Бис(дихлорфосфино)этан, 97+%
                                  </td>
                                </tr>
                                <tr class="row-parent_child table-row_active">
                                  <td></td>
                                  <td>ФГУП ПО “Октябрь”</td>
                                  <td></td>
                                  <td></td>
                                  <td class="text-center">4</td>
                                  <td>12.5</td>
                                  <td><a href="#">17.09.2019</a></td>
                                  <td class="text-center"><a href="#" class="row-link_edit"></a></td>
                                </tr>
                                <tr class="row-parent_child">
                                  <td></td>
                                  <td>ФГУП ПО “Октябрь”</td>
                                  <td></td>
                                  <td></td>
                                  <td class="text-center">8</td>
                                  <td>11</td>
                                  <td><a href="#">17.09.2019</a></td>
                                  <td class="text-center"><a href="#" class="row-link_edit"></a></td>
                                </tr>
                                <tr class="row-parent_child">
                                  <td></td>
                                  <td>ФГУП ПО “Октябрь”</td>
                                  <td></td>
                                  <td></td>
                                  <td class="text-center">1</td>
                                  <td>10.5</td>
                                  <td><a href="#">17.09.2019</a></td>
                                  <td class="text-center"><a href="#" class="row-link_edit"></a></td>
                                </tr>
                                <tr class="row-parent_child">
                                  <td></td>
                                  <td>ФГУП ПО “Октябрь”</td>
                                  <td></td>
                                  <td></td>
                                  <td class="text-center">2</td>
                                  <td>13.5</td>
                                  <td><a href="#">17.09.2019</a></td>
                                  <td class="text-center"><a href="#" class="row-link_edit"></a></td>
                                </tr>
                                <tr class="row-parent">
                                  <td colspan="8"><i class="row-parent_switch"></i> <a href="#">56661/19</a> Вывоз из РФ
                                    1,2-Бис(дихлорфосфино)этан, 97+%
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <div class="main-pagination table-pagination">
                                <span class="pagination_all">Всего: 3</span>
                                <span>Выводить по: </span>
                                <select class="pagination__select">
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="Все">Все</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import MainMenu from "@/components/cabinet/MainMenu";
import Header from "@/views/site/Header";
import Footer from "@/views/site/Footer";

export default {
  name: "KPOnRequest",
  components: {MainMenu, Header, Footer},
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>