<template>
  <div class="content-tab-area">
    <!-- START content-tab__panel  -->
    <div class="content-tab__panel tab_active">
      <div class="container-fluid">
        <div class="content-tab-wrapper">
          <div class="content-tab-indent">
            <div class="control-panel">
              <div class="control-panel__item">
                <router-link :to="{ name: 'LK/dogovors/create' }" class="control-panel__link control-add_link">оформить новый</router-link>
              </div>
              <div class="control-panel__item">
                <a @click="showCopyModal = true" class="control-panel__link control-copy_link">оформить с копированием</a>
              </div>
              <div class="control-panel__item">
                <div class="control-parent_item">
                  <a title="Настройка фильтра" class="control-panel__link control-funnel_link"
                     @click="contracts.showFilterModal=true"> </a>
                </div>
                <div class="control-parent_item">
                  <a title="Группировка" @click="contracts.showGroupModal=true"
                     class="control-panel__link control-diagram_link"></a>
                </div>
              </div>
              <div class="control-panel__item" v-if="currentUser.ACTIVE_ROLE_CODE === 'expert_org_expert'"
                   style="padding-top: 0px">

              </div>
              <div v-else class="control-panel__search">
                <select v-model="filter.type_contract" @change="fetchList" class="small_search"
                        style="background: none">
                  <option value="">Вид договора</option>
                  <option v-for="item in directory.vid_dogovor" :key="item.ID" :value="item.ID">
                    --{{ item.UF_NAME }}
                  </option>
                </select>

              </div>
              <div class="control-panel__item" v-if="currentUser.ACTIVE_ROLE_CODE === 'expert_org_assignee'"><a
                  class="link_style-blue" style="padding: 4px 14px" @click="startExpertise">начать экспертизу</a>
              </div>
            </div>

            <InfoFilter
                :items="contracts"/>
            <!-- control-panel -->
            <TableList
                :items="contracts"
                :is-loading="isContractsLoading"
                :show-check-field="currentUser.ACTIVE_ROLE_CODE === 'expert_org_assignee'"
                :api-service="service"
                :card-link="'LK/dogovors/edit'"
            />

            <ListFilter
                :items="contracts"
            />

            <div class="modal fade in show" tabindex="-1" v-if="showCopyModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog modal-middle" role="document">
                <div class="modal-content">
                  <div class="modal-add-document modal-full" v-if="showCopyModal">
                    <div class="modal-header main-clear">
                      <span class="modal-title">укажите договор для копирования</span>
                      <a @click="showCopyModal=false" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <div class="modal_row main-clear">
                        <div class="table-responsive">
                          <table class="filter-table">
                            <tbody>
                            <tr>
                              <td>
                                <select v-model="copyID"
                                        class="main_input main_select filter-input">
                                  <option :key="o.ID" v-for="o in copyData"
                                          :value="o.ID">договор
                                    Рег. № {{ o.UF_NUMBER }} от {{ o.UF_DATE_CREATE }}
                                  </option>
                                </select>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal_row main-clear">
                        <router-link class="link_style-blue modal-full_wid" v-if="copyID"
                                     :to="{name: 'LK/dogovors/copy', params: { id: copyID, copy:1 }}">
                          Создать
                        </router-link>
                        <div class="link_style-white modal-full_wid"
                             @click="showCopyModal = false">отмена
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade in show" tabindex="-1" v-if="showDownloadModal" role="dialog"
                 aria-labelledby="myModalLabel">
              <div class="modal-dialog modal-middle" role="document">
                <div class="modal-content">
                  <div class="modal-add-document modal-full" v-if="showDownloadModal">
                    <div class="modal-header main-clear">
                      <span class="modal-title">Выбор закючения для печати</span>
                      <a @click="showDownloadModal=false" class="modal-exit stl-FR"></a>
                    </div>
                    <div class="modal-body">
                      <div class="modal_row main-clear">
                        <div class="table-responsive">
                          <table class="filter-table">
                            <tbody>
                            <tr>
                              <td>
                                <select v-model="downloadID"
                                        class="main_input main_select filter-input">
                                  <option :key="o.ID" v-for="o in downloadData"
                                          :value="o.ID">заключение
                                    Рег. № {{ o.UF_NUMBER }} от {{ o.UF_DATE_FINISHED }}
                                  </option>
                                </select>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal_row main-clear">
                        <div class="link_style-blue modal-full_wid" v-if="downloadID"
                             @click="downloadReport">
                          Загрузить
                        </div>
                        <div class="link_style-white modal-full_wid"
                             @click="showDownloadModal = false">отмена
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- table-responsive -->
          </div>
          <!-- content-tab-indent -->
        </div>
        <!-- content-tab-wrapper -->
      </div>
      <!-- container-fluid -->
    </div>
    <!-- END content-tab__panel  -->
  </div>
  <!-- content-tab__panel -->
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import Vue from "vue";
import TableList from "@/components/cabinet/partial/TableList";
import ListFilter from "@/components/cabinet/partial/ListFilter";
import {ContractService} from "@/common/api.service";
import InfoFilter from "@/components/cabinet/partial/InfoFilter";
//import Multiselect from "vue-multiselect";

export default {
  name: "ContractList",
  components: {InfoFilter, ListFilter, TableList},
  data() {
    return {
      service: ContractService,
      ru: ru,
      showDownloadModal: false,
      showCopyModal: false,
      copyID: 0,
      copyData: [],
      downloadID: 0,
      downloadData: [],
      filter: {
        type_contract: "",
        result: "",
        org: [],
        status: ""
      },
      applyFilters: [],
      applyGroups: [],
      orgList: [],
      toasterErrors: []
    };
  },
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "vid_dogovor",
    ]);

    await this.fetchList()
  },
  computed: {
    ...mapGetters([
      "contracts",
      "isContractsLoading",
      "directory",
      "currentUser"
    ]),
    listConfig() {
      const q = {
        page: this.contracts.currentPage,
        limit: this.contracts.itemsPerPage,
        filter: "",
        group: [],
        filterStatus: null,
        filterCountry: null,
        filterPeriod: {}
      };
      if (this.contracts.sortColumn !== "") {
        let sort = {
          column: "",
          order: ""
        };
        sort.column = this.contracts.sortColumn;
        sort.order = this.contracts.sortOrder;
        q.sort = sort;
      }
      if (this.filter.type_contract > 0) {
        q.filterVidDogovor = this.filter.type_contract
      }
      if (this.filter.result > 0) {
        q.filterResult = this.filter.result
      }
      if (this.filter.org.length > 0) {
        q.filterOrg = []
        for (let i = 0; i < this.filter.org.length; i++) {
          q.filterOrg.push(this.filter.org[i]['ID'])
        }
      }
      if (this.filter.status.length > 0) {
        q.filterStatus = []
        for (let i = 0; i < this.filter.status.length; i++) {
          q.filterStatus.push(this.filter.status[i]['ID'])
        }
      }
      let filterarr = []
      this.contracts.filters.forEach(function (filter) {
        filterarr.push({
          FIELD: this.contracts.settings.filterFields[filter.index].FIELD,
          OPERATION: this.contracts.settings.filterFields[filter.index].OPERATION[filter.operation].CODE,
          VALUE: filter.value, VALUE2: filter.value2,
          LOGIC: this.contracts.settings.filterFields[filter.index].LOGIC[filter.logic].CODE,
          RUNTIME: this.contracts.settings.filterFields[filter.index].RUNTIME,
          CODE_RUNTIME: this.contracts.settings.filterFields[filter.index].CODE_RUNTIME
        })
      }, this);
      q.filter = ""
      if (filterarr.length) {
        q.filter = Buffer.from(JSON.stringify(filterarr)).toString("base64");
      }
      if (this.contracts.filters.length > 0) {
        Vue.set(this.contracts, 'applyFilters', JSON.parse(JSON.stringify(this.contracts.filters)))
      }

      this.contracts.groups.forEach(function (group) {
        q.group.push(this.contracts.settings.groupFields[group.index].FIELD)
      }, this);

      if (this.contracts.groups.length > 0) {
        Vue.set(this.contracts, 'applyGroups', JSON.parse(JSON.stringify(this.contracts.groups)))
      }
      return q;
    },
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  methods: {
    fetchList() {
      this.$store.dispatch('fetchContracts', this.listConfig).then(() => {
        document.title = unescape(this.contracts.META_TITLE);
        this.contracts.showFilterModal = false
        this.contracts.showGroupModal = false
        ContractService.getListPrintPDF().then(({data}) => {
          this.downloadData = data
        }, this)
      }, this)
      ContractService.getListCopyDogovor().then(({data}) => {
        this.copyData = data
      }, this)
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    startExpertise() {
      let r = []
      this.contracts.checkedItems.forEach(function (i, k) {
        if (i) {
          r.push(k)
        }
      }, this)
      if (!r.length) {
        alert("Необходимо выбрать заключения для начала проведения экспертизы!")
        return
      }
      ContractService.startExpertise({
        UF_CONCLUSION_ID: r
      }).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
        } else {
          this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
              'Выбранные экспертизы переведены в работу',
              {
                theme: "green",
                position: "bottom-right",
                duration: 3000,
                action: [
                  {
                    text: "Закрыть",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  }
                ]
              }
          );
        }
      }, this)
    }
  }
}
</script>

<style scoped>
.modal_row .vdp-datepicker {
  display: inline-block;
}

.multiselect {
  max-width: 300px;
}

.multiselect__tag {
  background: #3d7aaa !important;
}
</style>