<template>
  <div class="content-tab__panel tab_active">
    <div class="container-fluid" v-if="!isMethodsLoading">
      <div class="content-tab-wrapper" v-for="(section,index) in methods.items" :key="section.ID">
        <div class="row row_link_title">
          <div class="col-md-12">
            <a class="link_title"
               @click="changeSectionHide(methods.items, index)">
              <i class="i_arrow" :class="{'i_arrow_hidden': section.HIDE}"></i>
              {{ section.SECTION_NAME }}
            </a>
          </div>
        </div>
        <div v-show="!section.HIDE" style="padding-left: 20px" class="content-tab-wrapper" v-for="(child,index) in section.CHILD" :key="child.ID">
          <div class="row row_link_title">
            <div class="col-md-12">
              <a class="link_title"
                 @click="changeSectionHide(section.CHILD, index)">
                <i class="i_arrow" :class="{'i_arrow_hidden': child.HIDE}"></i>
                {{ child.SECTION_NAME }}
              </a>
            </div>
          </div>
          <div class="content-tab-indent" v-show="!child.HIDE">
            <div class="row">
              <div class="col-md-12 col-name" v-for="item in child.DOCUMENTS" :key="item.ID">
                <a :href="item.FILE" class="methodic_link">{{ item.NAME }}</a></div>
            </div>
          </div>
        </div>
        <div class="content-tab-indent" v-show="!section.HIDE">
          <div class="row">
            <div class="col-md-12 col-name" v-for="item in section.DOCUMENTS" :key="item.ID">
              <a :href="item.FILE" class="methodic_link">{{ item.NAME }}</a></div>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <a @click="fetchMethodsArchive" class="link_style-blue">скачать одним архивом</a>
      </div>
    </div>
  </div>
</template>

<script>
import {FETCH_METHODS} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Vue from "vue";
import {API_URL} from "@/common/config";
import {MethodsService} from "@/common/api.service";

export default {
  name: "MethodsList",
  data() {
    return {
      itemsPerPage: 20,
      currentPage: 1
    };
  },
  mounted() {
    this.fetchMethods()
  },
  computed: {
    ...mapGetters([
      "methods",
      "isMethodsLoading"
    ]),
    listConfig() {
      const q = {
        page: this.currentPage,
        limit: this.itemsPerPage,
      };

      return q;
    },
    pages() {
      if (this.isLoading || this.methods.counts <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.methods.counts / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    }
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.page = newValue;
      this.fetchMethods();
    },
    itemsPerPage(newValue) {
      this.listConfig.limit = newValue;
      this.fetchMethods();
    }
  },
  methods: {
    changeSectionHide(section, index) {
      Vue.set(section[index], 'HIDE', section[index].HIDE ? 0 : 1)
    },
    async fetchMethods() {
      await this.$store.dispatch(FETCH_METHODS, this.listConfig).then(() => {
        document.title = unescape(this.methods.META_TITLE);
        for (let i = 0; i < this.methods.items.length; i++) {
          Vue.set(this.methods.items[i], 'HIDE', true)
          if (this.methods.items[i].CHILD) {
            for (let c = 0; c < this.methods.items[i].CHILD.length; c++) {
              Vue.set(this.methods.items[i].CHILD[c], 'HIDE', true)
            }
          }
        }
      }, this)
    },
    fetchMethodsArchive() {
      MethodsService.archive().then(
          ({data}) => {
            window.location.href =
                API_URL.replace(new RegExp("[\\/]+$"), "") + data.link;
          }
      )
    }
  }
}
</script>

<style scoped>

</style>