import { render, staticRenderFns } from "./ViewFaq.vue?vue&type=template&id=9c563840&scoped=true&"
import script from "./ViewFaq.vue?vue&type=script&lang=js&"
export * from "./ViewFaq.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c563840",
  null
  
)

export default component.exports