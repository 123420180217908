<template>
  <div class="content-tab-wrapper">
    <div class="row">
      <div class="col-md-3 col-lg-2 col-name">Регистрационный № договора (№договора)</div>
      <div class="col-md-9 col-lg-10 col-data"><input type="text" class="main_input"  v-model="contractItem.UF_NUMBER"></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-2 col-name">Вид договора</div>
      <div class="col-md-9 col-lg-10 col-data">
        <select v-model="contractItem.UF_VID_DOGOVOR_ID" class="main_input main_select">
          <option v-for="item in directory.vid_dogovor" :key="item.ID" :value="item.ID">
            {{ item.UF_NAME }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-2 col-name">Исполнитель</div>
      <div class="col-md-9 col-lg-10 col-data">
        <select v-model="contractItem.UF_COMPANY_EXPERT_ID" class="main_input main_select">
          <option v-for="item in directory.expert_org" :key="item.ID" :value="item.ID">
            {{ item.UF_NAME }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-2 col-name">Срок действия договора</div>
      <div class="col-md-9 col-lg-10 col-data"><span style="float: left;padding-top: 5px;padding-right: 4px">с</span>
        <datepicker
            v-model="contractItem.UF_DATE_START_MAIN_D"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>

        <span style="float: left;padding-top: 5px;padding-right: 4px"> по</span>
        <datepicker
            v-model="contractItem.UF_DATE_END_MAIN_D"
            :language="ru"
            style="float: left"
            :format="dateFormat"
            input-class="main_input small_calendar input_calendar-inline"
        ></datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-2 col-name">Автопролонгация</div>
      <div class="col-md-9 col-lg-10 col-data"><input v-model="contractItem.UF_IS_AUTO_PROLONG" type="checkbox" checked class="main_check"></div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-2 col-name">Срок автопролонгации</div>
      <div class="col-md-9 col-lg-10 col-data">
        <select name="" id="" class="main_input main_select">
          <option value="1 год">1 год</option>
          <option value="2 год">2 год</option>
          <option value="3 год">3 год</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ru} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "MainInfo",
  components: {Datepicker},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org", "vid_dogovor"
    ]);
  },
  data() {
    return {
      ru: ru
    }
  },
  computed: {
    ...mapGetters([
      "contractItem",
      "isConclusionsLoading",
      "directory"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    directoryVal(directory, id) {
      for (let i = 0; i < directory.length; i++) {
        if (directory[i].ID === id) {
          return directory[i]
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>

</style>